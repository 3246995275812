import _, { isEmpty, isNil, get } from 'lodash';
import { BindAll } from 'lodash-decorators';
import React from 'react';
import { DragDropContext, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button } from 'semantic-ui-react';
import PivotConfiguratorFilter from '../PivotConfiguratorFilter/PivotConfiguratorFilter';
import './_PivotConfigurator.scss';
import PivotConfigEntry from './PivotConfigEntry';
import { ConfigItem, PivotConfiguratorProps, PivotConfiguratorState } from './PivotConfigurator.types';
import { AVAILABLE } from 'src/utils/Domain/Constants';

export const checkedIcon = 'far fa-check-square';
export const uncheckedIcon = 'far fa-square';

function reorder(list: any[], startIndex: number, endIndex: number): any[] {
  const copy = Array.from(list);
  const [removed] = copy.splice(startIndex, 1);
  copy.splice(endIndex, 0, removed);

  return copy;
}

export function findNode(grp: ConfigItem[], split: string[]) {
  let tmpArray = grp;
  let tmpParent;
  for (const token of split) {
    tmpParent = tmpArray.find((g) => g.id === token);
    tmpArray = tmpParent && tmpParent.children ? tmpParent.children : [];
  }
  return {
    parent: tmpParent,
    parentArray: tmpArray,
  };
}

@BindAll()
export default class PivotConfigurator extends React.Component<PivotConfiguratorProps, PivotConfiguratorState> {
  private handleFilterChange = _.debounce(this.applyFilterChange, 500);

  public constructor(props: PivotConfiguratorProps) {
    super(props);
    this.state = {
      currentDraggableId: '',
      groups: [
        {
          id: AVAILABLE,
          name: 'Dimension',
          children: _.cloneDeep(props.available) || [],
        },
        {
          id: 'rows',
          name: 'Rows',
          children: _.cloneDeep(props.rows) || [],
        },
        {
          id: 'columns',
          name: 'Columns',
          children: _.cloneDeep(props.columns) || [],
        },
      ],
    };
  }

  public onDragStart(initial: DragStart) {
    this.setState({
      currentDraggableId: initial.draggableId,
    });
  }

  public isTopLevelDropDisabled() {
    const currentDraggableId = this.state.currentDraggableId;
    const split = currentDraggableId.split('/');
    return split.length !== 2;
  }

  public onDragEnd(result: DropResult) {
    const destination = result.destination;
    const source = result.source;
    if (!destination) {
      return;
    }
    const from = source.droppableId;
    const to = destination.droppableId;
    const itemId = result.draggableId;
    const itemIdSplit = itemId.split('/');
    const lastId = itemIdSplit[itemIdSplit.length - 1];

    if (from === to) {
      // Case 1: src == dst
      const split = from.split('/');
      const { parentArray } = findNode(this.state.groups, split);
      const newChildArray = reorder(parentArray, source.index, destination.index);
      const newGroups = _.cloneDeep(this.state.groups);
      const parentInfo = findNode(newGroups, split);
      if (parentInfo.parent) {
        parentInfo.parent.children = newChildArray;
      }
      this.setState({
        groups: newGroups,
      });
    } else if (itemIdSplit.length === 2) {
      // Case 2: copying entire group to another
      const splitSrc = from.split('/');
      const splitDst = to.split('/');
      const newGroups = _.cloneDeep(this.state.groups);
      const srcInfo = findNode(newGroups, splitSrc);
      const dstInfo = findNode(newGroups, splitDst);
      const srcItem = srcInfo.parentArray.find((g) => g.id === lastId);
      const dstItem = dstInfo.parentArray.find((g) => g.id === lastId);
      if (!dstItem && srcItem && dstInfo.parent && srcInfo.parent && srcInfo.parent.children) {
        if (!dstInfo.parent.children) {
          dstInfo.parent.children = [];
        }
        dstInfo.parent.children.splice(destination.index, 0, srcItem);
        srcInfo.parent.children = srcInfo.parent.children.filter((g) => g.id !== lastId);
      }
      this.setState({
        groups: newGroups,
      });
    }
  }

  public computeHiddenItems(group: ConfigItem, filter: string, forceShow = false) {
    const tree = [group.id]; // building up a path array in order to unhide parents of any leaves

    const shouldHide = (item: ConfigItem) => {
      return !_.includes(item.name!.toLowerCase(), filter);
    };

    const recursiveHidden = (gp: ConfigItem) => {
      if (!gp.children) {
        return;
      }
      gp.children.forEach((nestedChild, idx, configArray) => {
        tree.push(nestedChild.id);
        if (forceShow && !nestedChild.alwaysHidden) {
          // turning everything back on
          nestedChild.hidden = false;
        } else if (nestedChild.alwaysHidden) {
          // some metrics are never shown to the user
          nestedChild.hidden = true;
        } else {
          nestedChild.hidden = shouldHide(nestedChild);
          if (!shouldHide(nestedChild)) {
            const parentTree = tree.slice(0, -1);
            for (let i = parentTree.length - 1; i >= 0; i--) {
              const info = findNode([group], parentTree);
              if (info.parent) {
                info.parent.hidden = false;
              }
              parentTree.pop();
            }
          }
        }

        if (nestedChild.children && nestedChild.children.length > 0) {
          recursiveHidden(nestedChild);
        } else {
          tree.pop();
          if (idx === configArray.length - 1) {
            tree.pop();
          }
        }
        if (tree[tree.length - 1] === configArray[idx].id) {
          // after recursing, remove from the tree to keep going
          tree.pop();
        }
      });
    };

    if (group.children) {
      group.children.forEach((cg, idx, groupArray) => {
        tree.push(cg.id);
        if (!cg.children) {
          return;
        }
        if (forceShow) {
          cg.hidden = false;
        } else {
          cg.hidden = shouldHide(cg);
        }
        if (cg.children.length > 0) {
          recursiveHidden(cg);
        }
        if (tree[tree.length - 1] === groupArray[idx].id) {
          // after recursing, remove from the tree to keep going
          tree.pop();
        }
      });
    }

    return group;
  }

  public onToggleClick(id: string) {
    if (id.startsWith('available')) {
      return;
    }
    const split = id.split('/');
    const newGroups = _.cloneDeep(this.state.groups);
    const info = findNode(newGroups, split);
    if (info && info.parent) {
      info.parent.disabled = !info.parent.disabled;
    }

    this.setState({
      groups: newGroups,
    });
  }

  public onCollapseExpand(id: string) {
    const split = id.split('/');
    const newGroups = _.cloneDeep(this.state.groups);
    const info = findNode(newGroups, split);
    if (info && info.parent) {
      info.parent.collapsed = !info.parent.collapsed;
    }

    this.setState({
      groups: newGroups,
    });
  }

  public getCheckAllClass(group: ConfigItem, parentId: string): string {
    let checkAllFilled = true;

    // TODO check that this is correct for no children scenario
    if (!group.children) {
      return uncheckedIcon;
    }

    group.children.forEach((cg) => {
      let id = `${group.id}/${cg.id}`;
      let split = id.split('/');
      let info = findNode(this.state.groups, split);
      if (!info.parent) {
        id = `${parentId}/${group.id}/${cg.id}`;
        split = id.split('/');
        info = findNode(this.state.groups, split);
      }
      if (info.parent && info.parent.hidden) {
        return;
      }
      if (info.parent && info.parent.disabled) {
        checkAllFilled = false;
      }
      if (cg.children && cg.children.length > 0) {
        cg.children.forEach((nestedChild) => {
          const tmpParentId = `${group.id}/${cg.id}`;
          const id2 = `${tmpParentId}/${nestedChild.id}`;
          const split2 = id2.split('/');
          const info2 = findNode(this.state.groups, split2);
          if (info2.parent && info2.parent.disabled) {
            checkAllFilled = false;
          }
        });
      }
    });

    if (checkAllFilled) {
      return checkedIcon;
    }
    return uncheckedIcon;
  }

  public onSelectAll(group: ConfigItem, parentId: string) {
    const newGroups = _.cloneDeep(this.state.groups);
    const checkOrUncheck = this.getCheckAllClass(group, parentId) === checkedIcon;

    if (parentId === AVAILABLE) {
      return;
    }

    function recursiveDisable(gp: ConfigItem, groupId: string) {
      if (!gp.children) {
        return;
      }
      gp.children.forEach((nestedChild) => {
        if (nestedChild.hidden || nestedChild.alwaysHidden) {
          return;
        }
        const parentId2 = `${groupId}/${gp.id}`;
        const id = `${parentId}/${parentId2}/${nestedChild.id}`;
        const split = id.split('/');
        const info = findNode(newGroups, split);
        if (info.parent) {
          info.parent.disabled = checkOrUncheck;
        }

        if (nestedChild.children && nestedChild.children.length > 0) {
          recursiveDisable(nestedChild, parentId2);
        }
      });
    }
    if (group.children) {
      group.children.forEach((cg) => {
        let id = `${group.id}/${cg.id}`;
        let split = id.split('/');
        let info = findNode(newGroups, split);
        if (cg.hidden || cg.alwaysHidden) {
          return;
        }
        if (!info.parent) {
          id = `${parentId}/${group.id}/${cg.id}`;
          split = id.split('/');
          info = findNode(newGroups, split);
        }
        if (info.parent) {
          info.parent.disabled = checkOrUncheck;
        }

        if (cg.children && cg.children.length > 0) {
          recursiveDisable(cg, group.id);
        }
      });
    }

    this.setState({
      groups: newGroups,
    });
  }

  public onLevelClick(levelId: string, disable: boolean) {
    const newGroups = _.cloneDeep(this.state.groups);
    function traverse(configItem: ConfigItem) {
      if (configItem.level === levelId) {
        configItem.disabled = disable;
      }
      if (configItem.children) {
        configItem.children.forEach(traverse);
      }
    }
    newGroups.forEach(traverse);
    this.setState({
      groups: newGroups,
    });
  }

  public getVisibleLevels(configItem: ConfigItem) {
    const levelsByDepth: { [key: string]: string } = {};
    const levelCounter: { [key: string]: number } = {};
    function findLevels(ci: ConfigItem, depth: number) {
      if (ci.level && !levelsByDepth[depth]) {
        levelsByDepth[depth] = ci.level;
      }
      if (!ci.disabled) {
        if (levelCounter[ci.level!]) {
          levelCounter[ci.level!]++;
        } else {
          levelCounter[ci.level!] = 1;
        }
      }
      if (ci.children) {
        ci.children.forEach((child) => {
          findLevels(child, depth + 1);
        });
      }
    }
    findLevels(configItem, 0);

    return Object.keys(levelsByDepth)
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .map((index) => levelsByDepth[index])
      .filter((lvl) => !!levelCounter[lvl]);
  }

  public getLevelOptionsRender(configItem: ConfigItem, parentId: string) {
    if (configItem.id === 'metrics' || configItem.id === 'revisions') {
      return <React.Fragment />;
    }

    const disabled = parentId === AVAILABLE;
    const settings = this.props.settings;
    const levelsByDepth: { [key: string]: string } = {};
    const levelCounter: { [key: string]: number } = {};
    function findLevels(ci: ConfigItem, depth: number) {
      if (ci.level && !levelsByDepth[depth]) {
        levelsByDepth[depth] = ci.level;
      }
      if (!ci.disabled) {
        if (levelCounter[ci.level!]) {
          levelCounter[ci.level!]++;
        } else {
          levelCounter[ci.level!] = 1;
        }
      }
      if (ci.children) {
        ci.children.forEach((child) => {
          findLevels(child, depth + 1);
        });
      }
    }

    findLevels(configItem, 0);
    const levels = Object.keys(levelsByDepth).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    return (
      <Button.Group className="level-button-group" floated="right">
        {levels
          .map((index) => levelsByDepth[index])
          .map((level, i) => {
            const levelLabel = get(settings, [`level.${level}.display`, 'value'], level);
            return (
              <Button
                toggle={true}
                key={i}
                size="mini"
                content={`${levelLabel}`}
                active={!!levelCounter[level]}
                disabled={disabled}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  if (!disabled) {
                    this.onLevelClick(level, !!levelCounter[level]);
                  }
                }}
              />
            );
          })}
      </Button.Group>
    );
  }

  public componentDidMount() {
    // clear filters on remount so we don't inadvertently hide things
    this.applyFilterChange('');
  }

  public componentDidUnMount() {
    // clear filters on remount so we don't inadvertently hide things
    this.applyFilterChange('');
  }

  public render() {
    const groups = this.state.groups;
    const currentDraggableId = this.state.currentDraggableId;
    return (
      <React.Fragment>
        <PivotConfiguratorFilter onChange={this.handleFilterChange} />
        <div className="pivot-configurator">
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            {groups.map((group, index) => (
              <Droppable droppableId={group.id} key={index} isDropDisabled={this.isTopLevelDropDisabled()}>
                {(provided) => (
                  <div className="pivot-configurator-list">
                    <div className="pivot-configurator-title">{group.name}</div>
                    <div className="pivot-list-groups" ref={provided.innerRef} {...provided.droppableProps}>
                      {group.children
                        ? group.children.map((cg, i) => (
                            <PivotConfigEntry
                              key={i}
                              index={i}
                              configItem={cg}
                              checkAll={this.getCheckAllClass}
                              parentId={group.id}
                              currentDraggableId={currentDraggableId}
                              onToggleClick={this.onToggleClick}
                              onCollapseExpand={this.onCollapseExpand}
                              onCheckAll={this.onSelectAll}
                            >
                              {this.selectAllButton(cg, group.id, this.getCheckAllClass)}
                              {this.getLevelOptionsRender(cg, group.id)}
                            </PivotConfigEntry>
                          ))
                        : null}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      </React.Fragment>
    );
  }

  private selectAllButton(
    configItem: ConfigItem,
    parentId: string,
    checkAll: (group: ConfigItem, parentId: string) => void
  ): JSX.Element | null {
    let selectAll: JSX.Element | null = null;
    // TODO make this configurable
    // and take out all the ignore-toggle cruft
    if (configItem.name === 'metrics' || configItem.name === 'product') {
      selectAll = (
        <div
          className="pivot-configurator-checkbox-group ignore-toggle"
          onClick={() => {
            this.onSelectAll(configItem, parentId);
          }}
        >
          <span className={'ignore-toggle'}>Select All?</span>
          <div
            className={`pivot-configurator-checkbox ignore-toggle ${checkAll ? checkAll(configItem, parentId) : ''}`}
          />
        </div>
      );
    }

    return selectAll;
  }

  private applyFilterChange(value: string) {
    // debounced call to parse the filters
    const deValue = _.deburr(value).toLowerCase();
    const maybeHiddenGroups = _.cloneDeep(this.state.groups);

    // use this to clear out everything if they remove all the text
    const clearHidden = isNil(value) || isEmpty(value);

    // otherwise compute the groups
    maybeHiddenGroups.map((group) => {
      return this.computeHiddenItems(group, deValue, clearHidden);
    });

    this.setState({
      groups: maybeHiddenGroups,
    });
  }
}
