import React from 'react';
import { find, flatMap } from 'lodash/fp';
import { option } from 'fp-ts';
import { map } from 'fp-ts/lib/Option';
import { Route } from 'react-router-dom';

import { ViewComponent, BoundEnabledPerspective, BoundTab } from 'src/services/configuration/codecs/bindings.types';
import { computeTabZipRoute, computeViewZipRoute } from 'src/pages/NavigationShell/navigationUtils';

import {
  BoundTenantZipper,
  BoundTabZipper,
  BoundSectionZipper,
  BoundViewZipper,
} from 'src/pages/NavigationShell/NavigationZipper';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import { pipe } from 'fp-ts/lib/function';
import { getPerspectiveLookupKey } from 'src/services/configuration/service';
import Favorite from '../Mfp/MfpGrid/favorite';
import ServiceContainer from 'src/ServiceContainer';

function viewComponentToRouteComponent(
  id: string,
  path: string,
  viewComponent: ViewComponent,
  viewProps?: { [s: string]: unknown }
): JSX.Element {
  const Component = viewComponent;
  const modPath = path
    .split('/')
    .slice(2)
    .join('/');
  return (
    <Route
      key={id}
      path={`${modPath}/*`}
      element={
        <ErrorBoundary>
          <Component key={`${path}-${id}`} {...viewProps} />
        </ErrorBoundary>
      }
    />
  );
}

export function getActivePerspective(
  perspective: string | undefined,
  perspectives: BoundEnabledPerspective[]
): option.Option<BoundEnabledPerspective> {
  return option.fromNullable(find((x) => x.pathSlot === perspective, perspectives));
}

export function getActivePerspectiveId(
  perspective: string | undefined,
  perspectives: BoundEnabledPerspective[]
): option.Option<string> {
  return pipe(
    getActivePerspective(perspective, perspectives),
    map((p) => getPerspectiveLookupKey(p))
  );
}

function getViewContentArea(view: BoundViewZipper): JSX.Element {
  // Figure out a good way of allowing the component to access the bound view
  return viewComponentToRouteComponent(
    view.getView().id,
    computeViewZipRoute(view),
    view.getView().component,
    view.getView().componentProps
  );
}

function getSectionContentArea(section: BoundSectionZipper): JSX.Element[] {
  return section
    .getViews()
    .filter((view) => !view.getView().errorView)
    .map((view) => getViewContentArea(view));
}

function getTabContentArea(tab: BoundTabZipper): JSX.Element[] {
  const component = tab.getTab().component;
  if (component) {
    return [viewComponentToRouteComponent(tab.getTab().id, computeTabZipRoute(tab), component)];
  }
  return flatMap((section) => getSectionContentArea(section), tab.getSections());
}

export function getContentArea(tenantZip: BoundTenantZipper): JSX.Element[] {
  const components = flatMap((tabZip) => getTabContentArea(tabZip), tenantZip.getTabs());
  return components;
}

export function getFirstMFPFavoriteComponentPath(tabZip: BoundTab): string {
  let path = '__notfound__';
  let pathfound = tabZip.boundSections?.some(section => {
    return section.boundViews.some((view) => {
      if (view.component as unknown === Favorite) {
        path = `${section.pathSlot}/${view.pathSlot}`;
        return true;
      }
    })
  });
  if (!pathfound) {
    ServiceContainer.loggingService.warn("User attempted to navigate to favorite but No MFP Favorite component found.")
  }
  return path;
}

export function getActiveComponentOverflow(tenantZip: BoundTenantZipper, routerLocationPath: string): string {
  let overflow = 'hidden';
  tenantZip.getTabs().forEach((tabZip) =>
    tabZip.getSections().forEach((section) => {
      section.getViews().forEach((view) => {
        const path = computeViewZipRoute(view);
        if (routerLocationPath === path) {
          const defaultOverflow = view.getView().overflow;
          if (defaultOverflow) {
            overflow = defaultOverflow;
          }
        }
      });
    })
  );
  return overflow;
}
