import { z } from 'zod';
import { PlanId, zPlanId, zSpace } from './PlanMetadata';

export const zCOMMAND_BALANCE = z.literal('balance');
export const zCOMMAND_SEED = z.literal('seed');
export const zCOMMAND_IMPORT = z.literal('import');
export const zCOMMAND_ACTUALS = z.literal('actuals');
export const zCOMMAND_PLAN = z.literal('plan');
export const zCOMMAND_TAGS = z.union([
  zCOMMAND_BALANCE,
  zCOMMAND_SEED,
  zCOMMAND_IMPORT,
  zCOMMAND_PLAN,
  zCOMMAND_ACTUALS,
]);
export type CommandTags = z.infer<typeof zCOMMAND_TAGS>;

export interface HasPlanId {
  planId: PlanId;
  commands: any[];
}
export const zCommandAnchor = z.object({
  displayTime: z.string(),
  displaySource: z.string(),
  type: z.union([zCOMMAND_BALANCE, zCOMMAND_SEED, zCOMMAND_PLAN]),
  command: z.unknown(),
});
export interface CommandAnchor extends z.infer<typeof zCommandAnchor> {}

export const zCommandImport = z.object({
  displaySource: z.string(),
  type: zCOMMAND_IMPORT,
  command: z.unknown(),
});
export interface CommandImport extends z.infer<typeof zCommandImport> {}
export const zCommand = zCommandAnchor.or(zCommandImport);

export type Command = z.infer<typeof zCommand>;

export const zCommandDetail = z.object({
  planId: zPlanId,
  mustSeed: z.boolean(),
  mustBalance: z.boolean(),
  mayBalance: z.boolean(),
  commands: z.array(zCommand),
  anchor: zSpace,
});
// re-exporting these as intertfaces, as the types are unreadable in the vscode hints
type tCommandDetail = z.infer<typeof zCommandDetail>;
export interface CommandDetail extends tCommandDetail {}

export const zCommandAnchorDetail = z.object({
  planId: zPlanId,
  mustSeed: z.boolean(),
  mustBalance: z.boolean(),
  mayBalance: z.boolean(),
  commands: z.array(zCommandAnchor),
  anchor: zSpace,
});
export interface CommandAnchorDetail extends z.infer<typeof zCommandAnchorDetail> {}

export const zCommands = z.object({
  commands: z.array(zCommandDetail),
});
export interface Commands extends z.infer<typeof zCommands> {}
