import { z } from 'zod';

/** Print */

export const PrintSize = z.object({
  name: z.string().optional(),
  width: z.number(),
  height: z.number(),
});

/** FlowStatus */

const FlowStatusValues = z.object({
  text: z.string(),
  tooltip: z
    .string()
    .optional()
    .default(''),
});
export const FlowStatus = z.object({
  values: z.array(FlowStatusValues),
  defaultSelection: z.array(z.number()),
});

/** Placeholder */

export const Placeholder = z.object({
  text: z.string(),
});
export interface PlaceholderConfig extends z.infer<typeof Placeholder> {}
