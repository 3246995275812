import React from 'react';
import { connect } from 'react-redux';
import { ButtonProps, Dropdown, Segment } from 'semantic-ui-react';
import { useRef, useState } from 'react';
import { AppState, AppThunkDispatch } from 'src/store';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { ReseedPlanModalOwnProps } from 'src/components/Mfp/Reseed/ReseedPlanModal';
import { useCallback, useEffect } from 'react';
import Modal from '@trendmicro/react-modal';
import { Button } from 'semantic-ui-react';
import { keyToCommand } from 'src/state/scope/codecs/projections/PlanMetadataToDropdown';
import { isEmpty, isUndefined, noop } from 'lodash';
import { useHandleKeyPress } from 'src/utils/Component/hooks/hooks';
import AnchorRadioSelect from 'src/components/AnchorRadioSelect/AnchorRadioSelect';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import { serverScopeMemberFromId } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';
import { importVersion, overlayVersion } from 'src/state/scope/Scope.actions';
import { toast } from 'react-toastify';
import { Command } from 'src/state/scope/codecs/Commands';
import { anchorsFromCommandDetails, getImportCommands, hasCommands } from 'src/state/scope/codecs/Commands.utils';
import {
  useSelectedPlanId,
  useCurrentCommands,
  useSetPlanId,
  useCommandToDropdown,
  useSelectedCommand,
  useHandleChangeCommand,
} from 'src/utils/Component/hooks/PlanManagement.hooks';

export interface ImportFromVersionOwnProps {
  loading: boolean;
}

const mapStateToProps = (state: AppState) => {
  const { settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(state.mfpScope);

  if (!readyScope || isEmpty(readyScope.commands)) {
    return;
  }

  // map it twice, as hasCommands doesn't return the correct type
  // filter so that we don't get empty sets of commands
  const commands = readyScope.commands
    .map(getImportCommands)
    .filter(hasCommands)
    .map(getImportCommands);

  return {
    commands,
    dimensionLabel,
    overlayOptions: readyScope.overlayOptions,
    scope: readyScope,
    anchor: serverScopeMemberFromId(anchorsFromCommandDetails(commands), readyScope.mainConfig.members),
    isMultiScope: readyScope.isMultiScope,
    entries: settings.entriesByKey
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    handleImportVersion: (seedCommand: Command['command']) => {
      return dispatch(importVersion(seedCommand));
    },
    handleOverlayVersion: (overlayId: string, applyTo: PlanId) => {
      return dispatch(overlayVersion(overlayId, applyTo));
    },
  };
};

export type ImportFromVersionProps = ReseedPlanModalOwnProps &
  ImportFromVersionOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ImportFromVersion = (props: ImportFromVersionProps) => {
  const { commands, onCancel, onSubmit, scope, handleImportVersion, dimensionLabel, anchor, isMultiScope, entries } = props;

  const [mutationPending, setMutationPending] = useState(false);
  const [ddOpen, setDdOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const modalRef = useRef<HTMLDivElement | null>(document.querySelector('.titled-modal-entered'));
  const prevOptions = useRef<typeof commands>();
  const close = useCallback(() => (ddOpen ? setDdOpen(false) : noop()), [ddOpen]);

  const [selectedPlanId, setSelectedPlanId] = useSelectedPlanId(commands);
  const currentCommands = useCurrentCommands(commands, selectedPlanId);
  const handleChangePlanId = useSetPlanId(commands, setSelectedPlanId);
  const versionOptions = useCommandToDropdown(commands, selectedPlanId, undefined, entries);
  const [selectedCommand, setSelectedCommand] = useSelectedCommand(currentCommands);
  const handleChangeCommand = useHandleChangeCommand(setSelectedCommand, setDdOpen);

  useEffect(() => {
    if (isEmpty(commands) && isUndefined(anchor)) {
      setLoading(false); // no data, doesn't need to remain loading
      setDdOpen(false)
    } else if (isEmpty(commands)) {
      setLoading(true);
    }
  }, [commands, anchor])

  useEffect(() => {
    // all of this garbage exists because the semantic dd doesn't bind the blur event on the first open
    // so we've got to manually jiggle it from the titled modal above
    // because there isn't another good way to get this click
    // TODO: fix this or replace the DD
    if (modalRef.current) {
      modalRef.current.addEventListener('click', close);
    } else {
      modalRef.current = document.querySelector('.titled-modal-entered');
    }
    return () => (modalRef.current ? modalRef.current.removeEventListener('click', close) : noop());
  }, [close, ddOpen]);

  // if coming from undef options to new options, open the dd
  // this is why the dd needs to be controlled
  useEffect(() => {
    if (!prevOptions.current && versionOptions && !isEmpty(versionOptions) && !ddOpen && !isUndefined(anchor)) {
      setDdOpen(true);
    }
    prevOptions.current = commands;
  }, [ddOpen, versionOptions, commands]);

  const handleSubmit = useCallback(async () => {
    if (!selectedCommand || !currentCommands) {
      return;
    }
    setMutationPending(true);
    const maybeFoundCommand = keyToCommand(selectedCommand, currentCommands.commands);
    if (!maybeFoundCommand) {
      return;
    }
    await handleImportVersion(maybeFoundCommand.command)
      .catch(() => {
        toast.error('An error occured import from version');
        throw new Error('An error occured import from version');
      })
      .then(() => {
        if (!isMultiScope) {
          onSubmit(); // only close modal on success
        }
      })
      .finally(() => setMutationPending(false));
  }, [currentCommands, handleImportVersion, isMultiScope, onSubmit, selectedCommand]);

  const handleOnSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
      handleSubmit();
    },
    [handleSubmit]
  );
  const handleEnterPress = useHandleKeyPress(handleSubmit);

  return (
    <div className="import-version">
      <Modal.Body>
        <Segment>Select a version to import into your WP</Segment>
        <div>
          {scope && dimensionLabel && anchor ? (
            <AnchorRadioSelect
              labelDimenion={dimensionLabel}
              anchor={anchor}
              onUpdateAnchorSelections={handleChangePlanId}
            />
          ) : null}
        </div>
        <Dropdown
          data-qa="import-version-dropdown"
          loading={loading}
          fluid={true}
          search={true}
          selection={true}
          options={versionOptions}
          onChange={handleChangeCommand}
          onKeyPress={handleEnterPress}
          value={selectedCommand}
          closeOnBlur={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button content="Close" onClick={onCancel} />
        <Button
          content="Submit"
          className="import-version-modal-button"
          data-qa="version-import-btn-import"
          loading={loading || mutationPending}
          disabled={isUndefined(anchor)}
          onClick={handleOnSubmit}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportFromVersion);
