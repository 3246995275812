import NavigationShell from './NavigationShell';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { AppState, AppThunkDispatch } from 'src/store';
import { BoundEnabledPerspective, Perspective } from 'src/services/configuration/codecs/bindings.types';
import {
  setActivePerspective,
  setActivePage,
  setActiveTab,
  preSetActiveSubPage,
  setActiveSubPage,
  preSetActivePage,
  setViewLinks,
} from 'src/pages/NavigationShell/NavigationShell.slice';
import { connect } from 'react-redux';
import {
  setSessionPerspective,
  setSessionPage,
  setSessionTab,
  setSessionSubPage,
} from 'src/components/RestoreSession/RestoreSession.utils';
import { updateFlowStatus, updateFlowStatusConfig } from 'src/components/Subheader/Subheader.slice';
import { TenantConfig, FlowStatus } from 'src/services/configuration/codecs/bindings.types';
import { RouteLink } from 'src/types/RouteLink';
import { withRouter, WithRouterProps } from 'src/components/higherOrder/withRouter';
import { updateAppConfig } from 'src/services/configuration/AppConfig.slice';
import { getPerspectiveLookupKey } from 'src/services/configuration/service';
import { appReady } from 'src/state/ViewConfig/ViewConfig.slice';
import { getScopeId, isReady } from 'src/state/scope/Scope.types';
import { MfpGetScopeRequest, getScope } from 'src/state/scope/Scope.actions';
import { get } from 'lodash';
import { clearScope } from 'src/state/scope/Scope.slice';

export interface StateProps {
  validScope: boolean;
  activeTab: string;
  tenantConfig: TenantConfig;
  activePage: string;
  activeSubPage?: string;
  viewLinks: RouteLink[];
  enabledPerspective: BoundEnabledPerspective | null;
  confdefns: Record<string, any>;
  mfpScopeId: string | undefined;
  isFetchingMfpScope: boolean;
}

export interface DispatchProps extends ReturnType<typeof mapDispatchToProps> { }

export interface Props extends StateProps, DispatchProps, WithRouterProps { }

function mapStateToProps(state: AppState): StateProps {
  const isFetchingMfpScope = get(state.mfpScope, 'isFetching', false);

  return {
    validScope: state.scope.scope.valid || isReady(state.mfpScope),
    activeTab: state.perspective.activeTab,
    tenantConfig: state.appConfig.tenantConfig,
    activePage: state.perspective.activePage,
    activeSubPage: state.perspective.activeSubPage,
    viewLinks: state.perspective.viewLinks,
    enabledPerspective: state.perspective.selected,
    confdefns: state.appConfig.confDefns,
    mfpScopeId: getScopeId(state.mfpScope),
    isFetchingMfpScope,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    updateAppConfig(tenantConfig: any) {
      dispatch(updateAppConfig(tenantConfig));
    },
    setActivePerspective(perspective: BoundEnabledPerspective) {
      dispatch(setActivePerspective(perspective));
      const perspectiveLookupKey = getPerspectiveLookupKey(perspective);
      setSessionPerspective(perspectiveLookupKey as Perspective);
    },
    setActivePage(data: string) {
      dispatch(preSetActivePage());
      dispatch(setActivePage(data));
      if (data !== '/') setSessionPage(data);
    },
    setActiveSubPage(data: string | undefined) {
      dispatch(preSetActiveSubPage());
      dispatch(setActiveSubPage(data));
      setSessionSubPage(data);
    },
    setActiveTab(tab: string) {
      dispatch(setActiveTab(tab));
      setSessionTab(tab);
    },
    setViewLinks(links: RouteLink[]) {
      dispatch(setViewLinks(links));
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateFlowStatusConfig(config: FlowStatus) {
      dispatch(updateFlowStatusConfig(config));
    },
    getMfpScope: (request: MfpGetScopeRequest) => {
      return dispatch(getScope(request));
    },
    clearMfpScope: () => dispatch(clearScope()),
    onMainMount: () => {
      dispatch(appReady());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(makePrintSensitive(withRouter(NavigationShell())));
