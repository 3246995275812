import { ofType } from 'redux-observable';
import { filter, mergeMap, map, of } from 'rxjs';
import { isNil } from 'lodash';
import { AppEpic } from 'src/store';
import { receiveViewDefns, updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import {
  cleanCollectionViewUp,
  fetchCollectionViewData,
  receiveCollectionViewTenantConfig,
} from './CollectionView.slice';
import {
  inputIsNotNullOrUndefined,
  isScopeDefined,
  isSubheaderLoaded,
  topMemInWorklistSelected,
} from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  CollectionViewComponent,
  maybeGetComponentProps,
  isSameComponentType,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { ExtraPivotOptions, getAggBys, organizeListDataOptions } from '../StyleColorReview.slice';
import {
  receiveScopeConfig,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';

export const collectionViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      receiveCollectionViewTenantConfig.type,
      // Instead of listening to activePage or activeSubPage, listen to the subheader finishing
      // fetching the groupBy/sortBy defns. This is to prevent collection view breaking when
      // switching from a screen with a groupBy active that is not valid in collection view.
      // If we run the epic too early, it applies the invalid groupBy
      receiveViewDefns.type,
      receiveScopeConfig.type,
      updateSelectedItem.type,
      updateGroupBy.type,
      'LENS_ASST_PLAN_REFRESHING_PAGE'
    ),
    map(() => maybeGetComponentProps<CollectionViewComponent>(state$.value, ConfDefnComponentType.collectionView)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns, topMembers }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const aggBy = getAggBys(state$.value.subheader, dataApi);
      const options: ExtraPivotOptions = !isNil(topMembers) ? { topMembers, aggBy } : { aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      if (isSameComponentType<CollectionViewComponent>(state$.value, ConfDefnComponentType.collectionView)) {
        return of(cleanCollectionViewUp(), fetchCollectionViewData(finalModelDefn, finalOptions));
      }

      return of(fetchCollectionViewData(finalModelDefn, finalOptions));
    })
  );
};
