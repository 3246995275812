import { AxiosInstance } from 'axios';
import qs from 'query-string';
import { AppState } from 'src/store';
import { FavoritesEntry } from 'src/services/Favorites';
import { createFavorite, mutateFavorite } from 'src/state/favorites/favorites.slice';
import {
  forceRefreshGrid,
  redoScope,
  saveVersion,
  undoScope,
  unlockScopeLockState,
  setGridAsyncState,
} from 'src/state/scope/Scope.actions';
import './_visualize-summary-pivot.scss';
import { Favorite } from './favorite';
import { FavoriteDispatchProps, FavoriteOwnProps, FavoriteValueProps } from './visualize-summary-pivot-ag.types';
import { Dispatch, Action } from 'redux';
import { GridAsyncState, getScopeId, getScopeReadyData, GRID_DEFAULT } from 'src/state/scope/Scope.types';
import { extractNavPath } from 'src/pages/NavigationShell/navigationUtils';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { isSome } from 'fp-ts/lib/Option';

export function mapStateToProps(state: AppState, ownProps: FavoriteOwnProps): FavoriteValueProps {
  let tempPivotName = 'pivot';
  const maybeMatchHash = extractNavPath(window.location, 'hash');
  if (maybeMatchHash) {
    // should be view id
    // FIXME add mfp support to work list
    tempPivotName = isSome(maybeMatchHash) ? maybeMatchHash.value[3] : 'pivot';
  }
  const currentModule = getMfpModule(state)?.id || '';

  return {
    scopeId: getScopeId(state.mfpScope),
    mainConfig: getScopeReadyData(state.mfpScope)?.mainConfig,
    initialized: getScopeReadyData(state.mfpScope)?.initialized || false,
    settings: state.settings.entriesByKey,
    isLockToggled: !!getScopeReadyData(state.mfpScope)?.hasLocks,
    favorites: state.favorites.favorites,
    groupId: currentModule,
    // FIXME needs to read from somewhere else
    favoriteId: qs.parse(ownProps.routerLocation.search)['favoriteId'] as string,
    isFetchingScope: getScopeReadyData(state.mfpScope)?.isFetching || false,
    pivotName: tempPivotName,
    gridAsyncState: getScopeReadyData(state.mfpScope)?.gridAsyncState || GRID_DEFAULT,
    hasEditableRevision: getScopeReadyData(state.mfpScope)?.hasEditableRevision || false,
    // The scope is ready if it has ready data
    scopeReady: !!getScopeReadyData(state.mfpScope),
    reports: state.workingSets.reports,
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<Action>): FavoriteDispatchProps => {
  return {
    onUnlockAllClick: (cmp: Favorite, axios: AxiosInstance, scopeId: string) => {
      // @ts-ignore
      return dispatch(unlockScopeLockState(axios, scopeId)).then(() => {
        if (cmp.pivot) {
          cmp.pivot.refreshCells();
          cmp.updateContextMenu();
        }
      });
    },
    // @ts-ignore
    addToFavorites: (axios, fav: FavoritesEntry) =>
      // @ts-ignore
      dispatch(createFavorite(axios, fav)),
    // @ts-ignore
    updateToFavorites: (axios, fav: FavoritesEntry) =>
      // @ts-ignore
      dispatch(mutateFavorite(axios, fav)),
    // @ts-ignore
    saveWorkingPlan: (axios, scopeId) => dispatch(saveVersion(axios, scopeId)),
    // @ts-ignore
    onClickUndo: (scopeId) => dispatch(undoScope(scopeId)),
    // @ts-ignore
    onClickRedo: (scopeId) => dispatch(redoScope(scopeId)),
    // @ts-ignore
    onforceRefreshGrid: () => dispatch(forceRefreshGrid()),
    // @ts-ignore
    onUpdateGridAsyncState: (newState: GridAsyncState) => {
      // @ts-ignore
      dispatch(setGridAsyncState(newState));
    },
  };
};
