import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnState } from '@ag-grid-community/core';

// ag-grid doesn't provide filter/sort types
export interface FilterModelType {
  [key: string]: { filterType: string; values: string[] } | undefined;
}

interface ReduxSlice {
  groupId: string | null;
  collapsed: boolean;
  gridFilterModel: FilterModelType;
  gridSortModel: ColumnState[];
}

export const initialState: ReduxSlice = {
  groupId: null,
  collapsed: false,
  gridFilterModel: {},
  gridSortModel: [],
};

const sideNavSlice = createSlice({
  name: 'SideNav',
  initialState,
  reducers: {
    setFocusedGroup(state, action: PayloadAction<string>) {
      state.groupId = action.payload;
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.collapsed = action.payload;
    },
    setGridFilterModel(state, action: PayloadAction<FilterModelType>) {
      state.gridFilterModel = action.payload;
    },
    setGridSortModel(state, action: PayloadAction<ColumnState[]>) {
      state.gridSortModel = action.payload;
    },
    resetGridModel(state) {
      state.gridFilterModel = {};
      state.gridSortModel = [];
    },
  },
});

export const {
  setFocusedGroup,
  setCollapsed,
  setGridFilterModel,
  setGridSortModel,
  resetGridModel,
} = sideNavSlice.actions;

export default sideNavSlice.reducer;
