import { head, isEmpty, isNil, noop, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { TopMembers } from 'src/services/Scope.client';
import { SettingsByKey } from 'src/services/Settings';
import { Command, CommandAnchorDetail, CommandDetail, HasPlanId } from 'src/state/scope/codecs/Commands';
import {
  commandAnchorsFromPlanId,
  commandDetailFromSpace,
  commandsFromPlanId,
} from 'src/state/scope/codecs/Commands.utils';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import {
  commandToKey,
  commandToDisplayDropdown,
  keyToCommand,
} from 'src/state/scope/codecs/projections/PlanMetadataToDropdown';

export const useSelectedPlanId = <T extends HasPlanId>(commands: T[] | undefined) =>
  useState<PlanId | undefined>(head(commands) ? head(commands)!.planId : undefined);

export const useCurrentCommands = <T extends HasPlanId>(commands: T[] | undefined, planId: PlanId | undefined) =>
  useMemo(() => {
    return commandAnchorsFromPlanId<T>(commands, planId);
  }, [commands, planId]);

export const useSetPlanId = (
  commands: CommandDetail[] | undefined,
  setPlanId: React.Dispatch<React.SetStateAction<PlanId | undefined>>
) => {
  return useCallback(
    (newMembers: TopMembers) => {
      if (!commands) {
        return;
      }
      setPlanId(commandDetailFromSpace(commands, newMembers).planId);
    },
    [commands, setPlanId]
  );
};

export const useCommandToDropdown = (
  commands: CommandDetail[] | undefined,
  planId: PlanId | undefined,
  time: string | undefined,
  entries?: SettingsByKey
) => {
  return useMemo(() => {
    if (isEmpty(commands) || !planId) {
      return [];
    }
    return commandsFromPlanId(commands, planId)!
      .filter((c) => ('displayTime' in c ? c.displayTime === time : true))
      .map((com) => commandToDisplayDropdown(com, entries));
  }, [commands, planId, time]);
};

export const useVersionState = (commands: CommandDetail[] | undefined, entries: SettingsByKey) => {
  return useState(commands?.flatMap((c) => c.commands.map((com) => commandToDisplayDropdown(com, entries))));
};

export const useSelectedCommand = (command: CommandDetail | undefined) => {
  return useState<string | undefined>(isNil(command) ? undefined : commandToKey(head(command.commands)!));
};

export const useFocusButtonRef = () => {
  const buttonRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const submitButton = buttonRef.current;
    if (submitButton) {
      submitButton.focus();
    }
  }, []);
  return buttonRef;
};

export const useCommandToTimeDropdown = (commandDetail: CommandAnchorDetail | undefined) => {
  return useMemo(() => {
    if (!commandDetail) {
      return [];
    }
    const ret = commandDetail.commands.map((c) => {
      return {
        text: c.displayTime,
        value: c.displayTime,
      };
    });
    return uniqBy(ret, 'value');
  }, [commandDetail]);
};

export const useHandleChangeCommand = (
  setCommand: (value: React.SetStateAction<string | undefined>) => void,
  setModal?: (value: React.SetStateAction<boolean>) => void
) => {
  return useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const ddCommands = data.options?.map((o) => o.command) as Command[];
      if (!ddCommands || typeof data.value !== 'string') {
        throw new Error('Commands not found');
      }
      const maybeCommand = keyToCommand(data.value, ddCommands)!;
      setCommand(commandToKey(maybeCommand));
      if (setModal) {
        setModal(false);
      }
    },
    [setCommand, setModal]
  );
};
export const useSelectedTime = (commandDetail: CommandAnchorDetail | undefined) => {
  return useState<string | undefined>(!commandDetail ? undefined : head(commandDetail.commands)?.displayTime);
};

export const useFocusDropdown = () => {
  const ddRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // focus the input on open, so the user can start typing immediately
    if (ddRef.current) {
      const input = ddRef.current.querySelector('input');
      input ? input.focus() : noop();
    }
  }, []);
  return ddRef;
};

export const useHandleChangeTime = (
  selectedTime: string | undefined,
  setSelectedTime: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  return useCallback(
    (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const newTime = data.value;
      if (newTime && typeof newTime === 'string' && newTime !== selectedTime) {
        setSelectedTime(newTime);
      }
    },
    [selectedTime, setSelectedTime]
  );
};
