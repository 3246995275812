import React from 'react';
import { isEmpty, isNil, reduce } from 'lodash';
import type { GridCellProps } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { Grid } from 'react-virtualized/dist/es/Grid';
import { StandardCard, StandardCardProps } from 'src/components/StandardCardView/StandardCard/StandardCard';
import { BOTTOMHEIGHT, TOPHEIGHT } from 'src/components/StandardCardView/StandardCard/StandardCard.styles';
import { CardType } from 'src/components/StandardCardView/StandardCardView.types';
import { TopCard } from 'src/components/StandardCardView/TopCard/TopCard';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { resolvePath } from 'src/cdn';

const noImage = resolvePath(noImagePath);

const GRID_PADDING = 15;
const ROW_PADDING = 10;
type CardsGridProps = {
  cardType?: CardType;
  gridItems: StandardCardProps[];
  additionalWidth?: number;
  cardHeight: number;
  cardWidth: number;
  rowHeightOverride?: number;
};

type CardsGridSizingProps = {
  gridWidth: number;
  gridHeight: number;
  columnCount: number;
  rowCount: number;
  columnWidth: number;
  rowHeight: number;
};

type InternalCardsGridProps = {
  cardType?: CardType;
  items: StandardCardProps[][];
  gridSizing: CardsGridSizingProps;
};

type RenderedGridCardProps = {
  style: React.CSSProperties;
  isScrolling: boolean;
  isVisible: boolean;
  cardProps: StandardCardProps;
  cardType?: CardType;
  classNameOverride?: string;
};

export const RenderedGridCard = ({
  style,
  classNameOverride,
  isScrolling,
  isVisible,
  cardType,
  cardProps,
}: RenderedGridCardProps) => {
  // prevent blank cards from being rendered (i.e. empty columns at the bottom of the grid)
  if (isNil(cardProps)) {
    return (
      <div style={style} className={!isNil(classNameOverride) ? classNameOverride : ''}>
        <span />
      </div>
    );
  }

  // To prevent too many network requests from firing during excessive scrolling,
  // only render images that are currently visible if the user starts scrolling.
  // This makes it so that when the user short scrolls a small amount
  // the images don't flip back and forth between the skeleton and the actual image
  // Also falls back to noImage if not scrolling and no imgSrc value is present.

  let finalImgSrc;
  if (isScrolling && !isVisible) {
    finalImgSrc = '';
  } else {
    finalImgSrc = !isEmpty(cardProps.imgSrc) ? cardProps.imgSrc : noImage;
  }

  let cardElement;
  switch (cardType) {
    case 'TopCard': {
      cardElement = <TopCard {...cardProps} imgSrc={finalImgSrc} />;
      break;
    }
    case 'StandardCard':
    default:
      cardElement = <StandardCard {...cardProps} imgSrc={finalImgSrc} />;
  }
  return (
    <div style={{ ...style, maxWidth: 500 }} className={!isNil(classNameOverride) ? classNameOverride : ''}>
      {cardElement}
    </div>
  );
};

const InternalCardsGrid = ({ cardType, items, gridSizing }: InternalCardsGridProps) => {
  const { gridWidth, gridHeight, columnCount, rowCount, columnWidth, rowHeight } = gridSizing;

  return (
    <Grid
      cellRenderer={({ columnIndex, rowIndex, key, style, isScrolling, isVisible }: GridCellProps) => (
        <RenderedGridCard
          key={key}
          style={style}
          isScrolling={isScrolling}
          isVisible={isVisible}
          cardType={cardType}
          cardProps={items[rowIndex][columnIndex]}
        />
      )}
      columnCount={columnCount}
      columnWidth={columnWidth}
      height={gridHeight}
      rowCount={rowCount}
      rowHeight={rowHeight}
      width={gridWidth}
      overscanRowCount={2}
    />
  );
};

export function convertToMatrix<T>(itemsProps: T[], columnCount: number): T[][] {
  return reduce(
    itemsProps,
    (rows, cardProps, index) => {
      if (index % columnCount == 0) {
        rows.push([cardProps]);
      } else {
        rows[rows.length - 1].push(cardProps);
      }

      return rows;
    },
    [] as T[][]
  );
}

const CardsGrid = ({ gridItems, cardWidth, cardType, rowHeightOverride, additionalWidth = 0 }: CardsGridProps) => {
  const rowHeight = rowHeightOverride ? rowHeightOverride : TOPHEIGHT + ROW_PADDING + BOTTOMHEIGHT;
  return (
    <AutoSizer>
      {({ height: autoSizerHeight, width: autoSizerWidth }) => {
        const columnCount = Math.floor((autoSizerWidth - additionalWidth) / cardWidth);
        const rowCount = Math.ceil(gridItems.length / columnCount);

        if (columnCount === 0 || rowCount === 0) {
          return null;
        }

        // convert gridItems to matrix for grid lookup based on row/column indices
        const itemsMatrix = convertToMatrix<StandardCardProps>(gridItems, columnCount);
        return (
          <InternalCardsGrid
            items={itemsMatrix}
            cardType={cardType}
            gridSizing={{
              gridWidth: autoSizerWidth,
              gridHeight: autoSizerHeight - GRID_PADDING,
              columnCount,
              rowCount,
              columnWidth: cardWidth,
              rowHeight,
            }}
          />
        );
      }}
    </AutoSizer>
  );
};

export default React.memo(CardsGrid);
