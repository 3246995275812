import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Step, StepContent, StepLabel, Stepper, Tab, Tabs, Typography } from '@material-ui/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ValueGetterParams, FirstDataRenderedEvent } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import Modal from '@trendmicro/react-modal';
import { Button, Dropdown, DropdownProps, Segment } from 'semantic-ui-react';
import { get, isNil } from 'lodash';
import { connect } from 'react-redux';

import './_submission-wizard.scss';
import { DropdownOption, WizardSection } from './submission-wizard.types';
import { AppState, AppThunkDispatch } from 'src/store';
import PivotToggle from 'src/components/Mfp/PivotConfigurator/PivotToggle';
import {
  getSelectedOption,
  setWizardActualizeDefaults,
  TabPanel,
  TogglesSelectAll,
  TogglesStatus,
  useGoBack,
  useGoForward,
  WizardActions,
} from './submission-wizard.utils';
import ServiceContainer from 'src/ServiceContainer';
import { usePlanMetadata } from 'src/components/Mfp/PlanMetadataGrid/FetchPlanMetadataHook';
import { formatDate } from 'src/utils/Domain/time.utils';
import { clonePlansActualize } from 'src/state/workingSets/workingSets.actions';
import { toast } from 'react-toastify';
import TitledModal from 'src/components/Modal/TitledModal';
import { getMemberNameFromSpace } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';
import { useGetPublishVersionsOnMount } from 'src/utils/Component/hooks/hooks';
import { getPublishVersions } from 'src/state/scope/ScopeManagement.actions';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import MfpSubheader from 'src/components/Mfp/MfpSubheader/MfpSubheader';

interface SubmissionWizardOwnProps {}
type SubmissionWizardValueProps = ReturnType<typeof mapStateToProps>;
interface SubmissionWizardDispatchProps extends ReturnType<typeof mapDispatchToProps> {}

export type SubmissionWizardProps = SubmissionWizardValueProps &
  SubmissionWizardDispatchProps &
  SubmissionWizardOwnProps;

const mapStateToProps = (state: AppState) => {
  const { controlPoints } = state.viewConfigSlice;
  const { dimensionLabelProperty } = state.settings;
  const { publishVersions } = state.scopeManagement;
  const locationDisplay = dimensionLabelProperty?.location;
  const currentModule = getMfpModule(state);

  const readyScope = getScopeReadyData(state.mfpScope);
  if (readyScope) {
    return {
      currentPlanId: readyScope.mainConfig.initializedPlans[0].id,
      controlPoints,
      currentModule,
      publishVersions,
      locationDisplay,
    };
  }
  return {
    currentPlanId: null,
    controlPoints,
    currentModule,
    publishVersions,
    locationDisplay,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    clonePlans: (payload: { planIds: number[]; version: string }) => dispatch(clonePlansActualize(payload)),
    getPublishVersions: (currentPlanId: PlanId) => dispatch(getPublishVersions(currentPlanId)),
  };
};

const SubmissionWizardActualize = (props: SubmissionWizardProps) => {
  const { clonePlans, getPublishVersions, publishVersions, currentPlanId, locationDisplay } = props;

  const client = ServiceContainer.axios;
  const logger = ServiceContainer.loggingService;

  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [planMovement, setPlanMovement] = useState('');
  const [planMovementOptions, setPlanMovementOptions] = useState<DropdownOption[]>([]);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [timeOptions, setTimeOptions] = useState<DropdownOption[]>([]);
  const [allProducts, setAllProducts] = useState(false);
  const [allLocations, setAllLocations] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(new Set<string>());
  const [selectedLocations, setSelectedLocations] = useState(new Set<string>());
  const [summaryTab, setSummaryTab] = useState(0);
  const [{ plans }, setFilters] = usePlanMetadata(client, props.currentModule!);

  useGetPublishVersionsOnMount(getPublishVersions, publishVersions, currentPlanId);

  const { toValue, toText, products, locations } = useMemo(() => {
    const { option } = getSelectedOption(planMovement, planMovementOptions);
    const toValue = (option?.value as string) || '';
    const toText = (option?.text as string) || '';
    const controlPoint = props.controlPoints ? props.controlPoints[toValue] : null;
    const products = controlPoint?.product || [];
    const locations = controlPoint?.location || [];
    return { toValue, toText, products, locations };
  }, [props.controlPoints, planMovement, planMovementOptions]);

  // setup plan movement options
  useEffect(() => {
    const options = isNil(props.publishVersions)
      ? []
      : props.publishVersions.map((version) => ({
          text: `${version.toUpperCase()}`,
          value: version,
        }));

    setPlanMovementOptions(options);
  }, [props.publishVersions]);

  // plan version changed, refetch data (via hook)
  useEffect(() => {
    if (time && selectedProducts.size > 0 && selectedLocations.size > 0) {
      setFilters({
        version: [toValue],
        time: [time],
        product: Array.from(selectedProducts.values()),
        location: Array.from(selectedLocations.values()),
      });
    }
  }, [
    toValue,
    time,
    setFilters,
    selectedProducts.size,
    selectedProducts.values,
    selectedLocations.size,
    selectedLocations.values,
    selectedProducts,
    selectedLocations,
  ]);

  /* Accordion methods */
  const goForward = useGoForward(setExpanded, 4);
  const goBack = useGoBack(setExpanded);

  /* Dropdown methods */
  const handleMovementDropdownChange = useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const { text, option } = getSelectedOption(data.value as string, data.options);

      if (text === planMovement) {
        // no change
        return;
      } else if (isNil(props.controlPoints)) {
        // can't set defaults without controlPoints
        setPlanMovement(text);
        return;
      }

      const { selectedProducts, selectedLocations, time, timeOptions } = setWizardActualizeDefaults(
        option,
        props.controlPoints
      );
      setPlanMovement(text);
      setAllProducts(true);
      setAllLocations(true);
      setSelectedProducts(selectedProducts);
      setSelectedLocations(selectedLocations);
      setTime(time);
      setTimeOptions(timeOptions);
    },
    [props.controlPoints, planMovement]
  );

  const handleTimeDropdownChange = useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const { text } = getSelectedOption(data.value as string, data.options);

      if (text === time) {
        // no change
        return;
      }

      setTime(text);
    },
    [time]
  );

  /* Toggles methods */

  const handleProductToggle = useCallback(
    (productId: string) => {
      const nextSelection = new Set(selectedProducts);

      if (selectedProducts.has(productId)) {
        nextSelection.delete(productId);
      } else {
        nextSelection.add(productId);
      }

      // determines correct toggle state for select all checkbox
      // by retrieving selected planMovement option and parsing 'To' value
      // to lookup correct products in correct controlPoint

      const { option } = getSelectedOption(planMovement, planMovementOptions);
      const to = (option?.value as string) || '';
      const controlPoint = props.controlPoints ? props.controlPoints[to] : null;
      const selectAllProducts = isNil(controlPoint?.product)
        ? allProducts
        : nextSelection.size === controlPoint?.product.length;

      setAllProducts(selectAllProducts);
      setSelectedProducts(nextSelection);
    },
    [props.controlPoints, planMovement, planMovementOptions, selectedProducts, allProducts]
  );

  const handleLocationToggle = useCallback(
    (productId: string) => {
      const nextSelection = new Set(selectedLocations);

      if (selectedLocations.has(productId)) {
        nextSelection.delete(productId);
      } else {
        nextSelection.add(productId);
      }

      // determines correct toggle state for select all checkbox

      const { option } = getSelectedOption(planMovement, planMovementOptions);
      const to = (option?.value as string) || '';
      const controlPoint = props.controlPoints ? props.controlPoints[to] : null;
      const selectAllLocations = isNil(controlPoint?.location)
        ? allLocations
        : nextSelection.size === controlPoint?.location.length;

      setAllLocations(selectAllLocations);
      setSelectedLocations(nextSelection);
    },
    [props.controlPoints, planMovement, planMovementOptions, selectedLocations, allLocations]
  );

  const toggleAllProducts = useCallback(() => {
    const { option } = getSelectedOption(planMovement, planMovementOptions);
    const to = (option?.value as string) || '';
    const controlPoint = props.controlPoints ? props.controlPoints[to] : null;

    if (isNil(controlPoint?.product)) {
      return;
    }

    const nextSelection = new Set<string>();

    if (allProducts) {
      setAllProducts(false);
      setSelectedProducts(nextSelection);
      return;
    }

    controlPoint?.product.forEach((product) => {
      nextSelection.add(product.id);
    });

    setAllProducts(true);
    setSelectedProducts(nextSelection);
  }, [props.controlPoints, planMovement, planMovementOptions, allProducts]);

  const toggleAllLocations = useCallback(() => {
    const { option } = getSelectedOption(planMovement, planMovementOptions);
    const to = (option?.value as string) || '';
    const controlPoint = props.controlPoints ? props.controlPoints[to] : null;

    if (isNil(controlPoint?.location)) {
      return;
    }

    const nextSelection = new Set<string>();

    if (allLocations) {
      setAllLocations(false);
      setSelectedLocations(nextSelection);
      return;
    }

    controlPoint?.location.forEach((location) => {
      nextSelection.add(location.id);
    });

    setAllLocations(true);
    setSelectedLocations(nextSelection);
  }, [props.controlPoints, planMovement, planMovementOptions, allLocations]);

  /* Tab methods */

  const handleTabChange = useCallback((_event: React.ChangeEvent<{}>, value: any) => {
    setSummaryTab(value);
  }, []);

  /* Grid methods */

  const onDataRendered = useCallback((event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
    event.api.hideOverlay();
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);
  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const getMemberNameFromId = useCallback(
    (params: ValueGetterParams) => {
      if (!params.colDef.field) {
        return;
      }
      const id = get(params.data, params.colDef.field);
      if (props.controlPoints && toValue) {
        return getMemberNameFromSpace(id, props.controlPoints[toValue]);
      }
      return id;
    },
    [props.controlPoints, toValue]
  );

  const handleSubmitMassCopy = useCallback(async () => {
    setLoading(true);
    if (selectedLocations.size > 0 && selectedProducts.size > 0 && toValue && plans) {
      const planIds = plans.map((p) => p.id);
      const clonePlamResp = await clonePlans({
        planIds,
        version: toValue,
      });

      if (clonePlamResp.type === 'workingSets/clonePlanActualize/rejected') {
        // TODO: handle this correctly
        toast.error('An error occured copying this plan', {
          position: toast.POSITION.TOP_LEFT,
        });
        logger.error(`An error occured copying the plan ids ${planIds} to ${toValue}`);
      } else {
        toast.info('Plans Copied Successfully!');
      }

      // reset everything
      setLoading(false);
      setModalVisible(false);
      setSelectedProducts(new Set());
      setSelectedLocations(new Set());
      setPlanMovement('');
      setTime(undefined);
      setExpanded(0);
    }
  }, [client, clonePlans, logger, plans, selectedLocations.size, selectedProducts.size, toValue]);

  return (
    <div className="visualize-summary-pivot">
      <MfpSubheader title={'Mass Actualize Plans'} />

      <Stepper activeStep={expanded} className="submission-wizard-sections" orientation="vertical">
        <Step expanded={expanded === WizardSection.Plan}>
          <StepLabel classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>Select Plan to Actualize</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <div className="dropdown-group">
              <div className="dropdown-group-label">Select Plan Movement</div>
              <Dropdown
                fluid={true}
                data-qa="submission-wizard-plan-movement-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={planMovementOptions}
                value={planMovement}
                text={planMovement}
                onChange={handleMovementDropdownChange}
                scrolling={true}
              />
            </div>
            <WizardActions
              section={WizardSection.Plan}
              onContinue={goForward}
              onGoBack={goBack}
              disabled={planMovement === ''}
            />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.Product}>
          <StepLabel classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>Select Product(s)</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <div className="toggles-section">
              <TogglesSelectAll isChecked={allProducts} onCheckBoxToggle={toggleAllProducts} />
              <div className="toggles-container">
                {products
                  ? products.map((product) => {
                      return (
                        <section className="toggle-group" key={product.id}>
                          <span className="toggle-title">{product.name}</span>
                          <div className="toggle-container">
                            <PivotToggle
                              checked={selectedProducts.has(product.id)}
                              locked={false}
                              onToggleClick={() => handleProductToggle(product.id)}
                            />
                          </div>
                        </section>
                      );
                    })
                  : 'No products found'}
              </div>
              <TogglesStatus itemCount={products?.length || 0} selectionCount={selectedProducts.size} />
            </div>
            <WizardActions
              section={WizardSection.Product}
              onContinue={goForward}
              onGoBack={goBack}
              disabled={planMovement === '' || selectedProducts.size === 0}
            />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.Location}>
          <StepLabel classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>Select Location(s)</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <div className="toggles-section">
              <TogglesSelectAll isChecked={allLocations} onCheckBoxToggle={toggleAllLocations} />
              <div className="toggles-container">
                {locations
                  ? locations.map((location) => {
                      return (
                        <section className="toggle-group" key={location.id}>
                          <span className="toggle-title toggle-title-upper">
                            {!isNil(locationDisplay) ? location[locationDisplay] : location.id}
                          </span>
                          <div className="toggle-container">
                            <PivotToggle
                              checked={selectedLocations.has(location.id)}
                              locked={false}
                              onToggleClick={() => handleLocationToggle(location.id)}
                            />
                          </div>
                        </section>
                      );
                    })
                  : 'No locations found'}
              </div>
              <TogglesStatus itemCount={locations?.length || 0} selectionCount={selectedLocations.size} />
            </div>
            <WizardActions
              section={WizardSection.Location}
              onContinue={goForward}
              onGoBack={goBack}
              disabled={planMovement === '' || selectedProducts.size === 0 || selectedLocations.size === 0}
            />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.Time}>
          <StepLabel classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>Select Time</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <div className="dropdown-group">
              <div className="dropdown-group-label">Select Plan Time</div>
              <Dropdown
                fluid={true}
                data-qa="submission-wizard-time-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={timeOptions}
                value={time}
                onChange={handleTimeDropdownChange}
                scrolling={true}
              />
            </div>
            <WizardActions
              section={WizardSection.Time}
              onContinue={goForward}
              onGoBack={goBack}
              disabled={planMovement === '' || selectedProducts.size === 0 || selectedLocations.size === 0 || !time}
            />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.Summary}>
          <StepLabel classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>Summary</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details-ignore-width' }}>
            <Tabs classes={{ indicator: 'selected' }} value={summaryTab} onChange={handleTabChange}>
              <Tab label={'Summary'} />
              <Tab label={'Details'} />
            </Tabs>
            <TabPanel value={summaryTab} index={0}>
              <section className="summary-container">
                <Typography className="summary-label first-label">Version to Actualize:</Typography>
                <Typography className="summary-label second-label">Products:</Typography>
                <Typography className="summary-label third-label">Locations:</Typography>
                <Typography className="summary-label fourth-label">Time:</Typography>
                <Typography className="summary-details first-details">{toText || '- no selection -'}</Typography>
                <Typography className="summary-details second-details" noWrap={true}>
                  {Array.from(selectedProducts).join(', ')}
                </Typography>
                <Typography className="summary-details third-details" noWrap={true}>
                  {Array.from(selectedLocations).join(', ')}
                </Typography>
                <Typography className="summary-details fourth-details">{time}</Typography>
              </section>
            </TabPanel>
            <TabPanel value={summaryTab} index={1}>
              <section className="grid-container" style={{ height: '250px' }}>
                <AgGridReact
                  rowHeight={40}
                  rowData={plans}
                  onFirstDataRendered={onDataRendered}
                  columnDefs={[
                    {
                      // TODO: make the getter here configureable,
                      field: 'space.product',
                      valueGetter: getMemberNameFromId,
                      headerName: 'Product',
                      suppressMovable: true,
                      suppressMenu: true,
                      width: 150,
                      cellStyle: { textAlign: 'center' },
                    },
                    {
                      field: 'space.location',
                      headerName: 'Location',
                      suppressMovable: true,
                      suppressMenu: true,
                      width: 75,
                      cellStyle: { textAlign: 'center' },
                    },
                    {
                      field: 'space.time',
                      headerName: 'Time',
                      suppressMovable: true,
                      suppressMenu: true,
                      width: 75,
                      cellStyle: { textAlign: 'center' },
                    },
                    {
                      field: 'version',
                      headerName: 'Version',
                      suppressMovable: true,
                      suppressMenu: true,
                      width: 75,
                      cellStyle: { textAlign: 'center' },
                    },
                    {
                      field: 'createdAt',
                      valueFormatter: formatDate,
                      suppressMovable: true,
                      suppressMenu: true,
                      width: 150,
                      cellStyle: { textAlign: 'center' },
                    },
                    {
                      field: 'authoredBy.email',
                      suppressMovable: true,
                      suppressMenu: true,
                      width: 150,
                      cellStyle: { textAlign: 'center' },
                    },
                  ]}
                ></AgGridReact>
              </section>
            </TabPanel>
            <WizardActions
              section={WizardSection.Summary}
              onContinue={openModal}
              onGoBack={goBack}
              loading={isLoading}
              disabled={planMovement === '' || selectedProducts.size === 0 || selectedLocations.size === 0 || !time}
            />
          </StepContent>
        </Step>
      </Stepper>
      <TitledModal
        title={'Mass Actualize Plans'}
        show={isModalVisible}
        data-qa={'copy-plan-modal'}
        className={'import-version-modal'}
      >
        <Modal.Body>
          <Segment>
            This action will actualize all <strong>{plans ? plans.length : ''}</strong> plans for &quot;
            <strong>{toValue?.toLocaleUpperCase()}</strong>&quot;. Do you wish to proceed?
          </Segment>
        </Modal.Body>
        <Modal.Footer>
          <Button content="Cancel" onClick={closeModal} />
          <Button
            content="Submit"
            className="import-version-modal-button"
            data-qa="copy-plan-btn-copy"
            onClick={handleSubmitMassCopy}
            loading={isLoading}
          />
        </Modal.Footer>
      </TitledModal>
    </div>
  );
};

export default connect<SubmissionWizardValueProps, SubmissionWizardDispatchProps, SubmissionWizardOwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionWizardActualize);
