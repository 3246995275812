/* eslint-disable no-console */
import Axios from 'src/services/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';

/* eslint-disable @typescript-eslint/naming-convention */
enum LoggingSeverity {
  SEVERITY_TRACE = 'TRACE',
  SEVERITY_INFO = 'INFO',
  SEVERITY_DEBUG = 'DEBUG',
  SEVERITY_WARN = 'WARN',
  SEVERITY_ERROR = 'ERROR',
}
/* eslint-disable @typescript-eslint/naming-convention */

type LoggingPayloadRequired = {
  severity: LoggingSeverity;
  message: string;
};
type LoggingPayloadOptional = {
  payload: {
    [s: string]: Record<string, unknown> | string | undefined;
  };
  userId: string;
  sessionId: string;
};
type LoggingPayload = LoggingPayloadOptional | LoggingPayloadRequired;

const isAxiosError = (error: object): error is AxiosError => {
  return 'isAxiosError' in error;
};

export const errorToLoggingPayload = (error?: unknown): string => {
  const noErrorInformationMessage = 'No additional error information';
  const unknownErrorInformationMessage = 'Unknown Error Occured';

  if (error === undefined || error === null) {
    return noErrorInformationMessage;
  }
  // "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
  switch (typeof error) {
    case 'function':
    case 'undefined':
      return noErrorInformationMessage;
    case 'bigint':
    case 'number':
    case 'boolean':
    case 'symbol':
      return error.toString();
    case 'string':
      return error;
    case 'object':
      const errorObj = error;
      if (errorObj instanceof Error) {
        return JSON.stringify(errorObj);
      }
      if (isAxiosError(errorObj)) {
        const serverResponse = errorObj.response ? errorToLoggingPayload(errorObj.response) : null;
        const serverCode = errorObj.code ? errorObj.code : 'No Code Returned';
        return serverResponse ? `HTTP Error: ${serverCode} Response: ${serverResponse}` : `HTTP Error: ${serverCode}`;
      }
      return unknownErrorInformationMessage;
    default:
      return unknownErrorInformationMessage;
  }
};

export function logError(message: string, error: unknown) {
  ServiceContainer.loggingService.error(message, errorToLoggingPayload(error));
}

export function toastAndLog(message: string, error?: unknown) {
  if (error) {
    toast.error(message);
    logError(message, error);
  } else {
    toast.info(message);
    ServiceContainer.loggingService.info(message);
  }
}
class LoggingService {
  // TODO: pull the userid from the request and pass it in as a constructor param
  // to be send to the server

  public trace(message: string, stack?: string, skipLocalLog = false) {
    if (!skipLocalLog) {
      console.trace(`${message}\n${stack}`);
    }

    return this.postLogs({
      severity: LoggingSeverity.SEVERITY_TRACE,
      message: message,
      payload: {
        stack,
      },
    });
  }
  public info(message: string, stack?: string, skipLocalLog = false) {
    if (!skipLocalLog) {
      console.info(`${message}\n${stack}`);
    }

    return this.postLogs({
      severity: LoggingSeverity.SEVERITY_INFO,
      message: message,
      payload: {
        stack,
      },
    });
  }
  public debug(message: string, stack?: string, skipLocalLog = false) {
    if (!skipLocalLog) {
      console.debug(`${message}\n${stack}`);
    }

    return this.postLogs({
      severity: LoggingSeverity.SEVERITY_DEBUG,
      message: message,
      payload: {
        stack,
      },
    });
  }
  public warn(message: string, stack?: string, skipLocalLog = false) {
    if (!skipLocalLog) {
      console.warn(`${message}\n${stack}`);
    }

    return this.postLogs({
      severity: LoggingSeverity.SEVERITY_WARN,
      message: message,
      payload: {
        stack,
      },
    });
  }
  public error(message: string, stack?: string, skipLocalLog = false) {
    if (!skipLocalLog) {
      console.error(`${message}\n${stack}`);
    }

    return this.postLogs({
      severity: LoggingSeverity.SEVERITY_ERROR,
      message: message,
      payload: {
        stack,
      },
    });
  }

  private postLogs(log: LoggingPayload) {
    // remote server takes a list of errors
    // TODO: batch errors and send them in one request
    return Axios.post<AxiosResponse>(`/api/log`, [log]).then((response) => response.data);
  }
}

export default LoggingService;
