import React, { Component, Fragment } from 'react';
import { isNil, pick, omit, isBoolean, isArray, isEmpty } from 'lodash';
import { classes } from 'typestyle';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  TextField,
} from '@material-ui/core';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Subheader from 'src/components/Subheader/Subheader.container';
import {
  ParameterTogglesProps,
  ParameterTogglesState,
  ParameterTogglesField,
  ParameterTogglesFieldRadio,
} from 'src/pages/Allocation/ParameterToggles/ParameterToggles.types';
import {
  SectionToggle,
  SectionDropdown,
  LightTooltip,
} from 'src/pages/Allocation/ParameterToggles/ParameterToggles.utils';
import { getProductItem } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { fabBtn } from 'src/pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.styles';
import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import styles from 'src/pages/Allocation/ParameterToggles/ParameterToggles.styles';
import Axios from 'src/services/axios';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import InputInteger from 'src/common-ui/components/Inputs/Integer/InputInteger';
import { getTooltipTitle } from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails';
import columnDetailStyles from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails.styles';

const ATTR_UPSERT = 'api/attribute/upsert';
const PanelKeyText = 'parameters_panel';
const UnknownEditorType = 'Unknown Editor';

export default class ParameterToggles extends Component<ParameterTogglesProps, ParameterTogglesState> {
  constructor(props: ParameterTogglesProps) {
    super(props);

    this.state = {
      currentPanel: `${PanelKeyText}${0}`, // default to first panel open
      data: null,
    };
  }

  componentDidMount() {
    this.props.onShowView();
    this.fetchParameterSettings();
  }

  async fetchParameterSettings() {
    const settings = await getProductItem(this.props.scopeParams.productMember);
    this.setState({
      data: settings.data.data,
    });
  }

  executeAllocationPlan = () => {
    const config = this.props.config;
    if (config == null) return;
    this.props.onSubmitPlan(config.allocPlanModel, this.state.data as Record<string, string | boolean> | null);
  };

  handlePanelChange = (newPanel: string) => {
    const lastPanel = this.state.currentPanel;
    const panelClosing = lastPanel === newPanel;
    const panel = panelClosing ? null : newPanel;

    this.setState({
      currentPanel: panel,
    });
  };

  handleParameterChangeAll = async (keys: string[], checked: boolean[]) => {
    const response = await Axios.post(
      ATTR_UPSERT,
      {
        product: this.props.scopeParams.productMember,
        ...keys.reduce((prev, news, i) => ({ ...prev, [keys[i]]: checked[i] }), {}),
      },
      {
        params: {
          appName: 'Assortment',
        },
      }
    );

    this.setState({
      data: response.data.data,
    });
  };

  handleParameterChange = async (key: string, checked: boolean) => {
    const response = await Axios.post(
      ATTR_UPSERT,
      {
        [key]: checked,
        product: this.props.scopeParams.productMember,
      },
      {
        params: {
          appName: 'Assortment',
        },
      }
    );

    this.setState({
      data: response.data.data,
    });
  };

  handleDropdownChange = async (key: string, selection: { value: string }[] | { value: string }) => {
    const convertSelection = !isArray(selection) ? [selection] : selection;
    const data = {
      [key]: convertSelection.map((i) => i.value),
      product: this.props.scopeParams.productMember,
    };

    const response = await Axios.post<undefined, any>(ATTR_UPSERT, data, {
      params: {
        appName: 'Assortment',
      },
    });

    this.setState({
      data: response.data.data,
    });
  };

  handleRadioGroupChange = async (event: React.ChangeEvent<HTMLInputElement>, panel: ParameterTogglesFieldRadio) => {
    const { data } = this.state;
    const keys: string[] = [],
      values: boolean[] = [];

    const newSelectedDataIndex = event.target.value;
    if (data && data[newSelectedDataIndex] === true) {
      return;
    }
    panel.radioFields.forEach(async (x) => {
      if (x.dataIndex !== newSelectedDataIndex) {
        keys.push(x.dataIndex);
        values.push(false);
      }
    });
    keys.push(newSelectedDataIndex);
    values.push(true);
    await this.handleParameterChangeAll(keys, values);
  };

  handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleNumericInputChange = (id: string, value: string | number) => {
    this.setState({
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  sendTextInput = async (key: string, value: string | number) => {
    const data = {
      product: this.props.scopeParams.productMember,
      [key]: value,
    };
    const response = await Axios.post<undefined, any>(ATTR_UPSERT, data, {
      params: {
        appName: 'Assortment',
      },
    });

    this.setState({
      data: response.data.data,
    });
  };

  renderSectionParameters = (panelFields: ParameterTogglesField[]) => {
    const { data } = this.state;

    if (isNil(data)) {
      return [];
    }

    return panelFields.map((field, index) => {
      switch (field.editor) {
        case 'toggle':
          const toggleValue = data[field.dataIndex];
          if (!isBoolean(toggleValue)) {
            return UnknownEditorType;
          }

          return (
            <SectionToggle
              key={`toggle-${field.dataIndex}-${index}`}
              {...pick(field, ['text', 'infoText'])}
              isChecked={toggleValue}
              onChange={(_event, checked) => this.handleParameterChange(field.dataIndex, checked)}

            />
          );
        case 'multiselect':
          const selectValue: string[] = ((data[field.dataIndex] as unknown) as string[]) || []; // FIXME: remove fallback once dataIndex is ready
          const selectValueString = `{${selectValue.join(',')}}`;
          return (
            <SectionDropdown
              key={`dropdown-${field.dataIndex}-${index}`}
              {...omit(field, ['editor'])}
              id={this.props.scopeParams.locationMember}
              selection={selectValueString}
              multiSelect={true}
              inputHeight={110}
              onDropdownChange={(selection) => this.handleDropdownChange(field.dataIndex, selection)}
              dataQa="parameter-multi-dropdown"
            />
          );
        case 'singleSelect':
          const selectionVal: string[] = (data[field.dataIndex] as unknown) as string[];
          const selectionString = `${selectionVal}`;
          return (
            <SectionDropdown
              key={`dropdown-${field.dataIndex}-${index}`}
              {...omit(field, ['editor'])}
              id={this.props.scopeParams.locationMember}
              selection={selectionString}
              inputHeight={55}
              multiSelect={false}
              onDropdownChange={(selection) => this.handleDropdownChange(field.dataIndex, selection)}
              dataQa="parameter-single-dropdown"
            />
          );
        case 'radiogroup':
          const labels = field.radioFields.map(({ text, dataIndex }) => (
            <FormControlLabel key={text} value={dataIndex} control={<Radio />} label={text} />
          ));
          const selected = field.radioFields.find(({ dataIndex }) => data[dataIndex] === true);
          return (
            <FormControl style={{ marginLeft: '5rem' }}>
              <FormLabel id="radio-buttons-group-label">{field.text}</FormLabel>
              <RadioGroup
                name="radio-buttons-group"
                data-qa="parameter-radio-button-container"
                value={selected ? selected.dataIndex : field.radioFields[0].dataIndex}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleRadioGroupChange(event, field)}
              >
                {labels}
              </RadioGroup>
            </FormControl>
          );
        case 'string':
          const textValue = data[field.dataIndex] as string;
          return (
            <div className={styles.sectionParameter}>
              <div className="text-container">
                <div className="text">{field.text}</div>
                {field.infoText && !isEmpty(field.infoText) && (
                  <LightTooltip title={field.infoText}>
                    <i className="fal fa-info-circle" />
                  </LightTooltip>
                )}
              </div>
              <Tooltip
                className={columnDetailStyles.dataItem}
                data-qa="multiselect-tooltip"
                title={getTooltipTitle(field.infoText || ' ')}
                placement="right"
              >
                <TextField
                  id={`${field.dataIndex}`}
                  variant="outlined"
                  onChange={this.handleTextInputChange}
                  onBlur={() => this.sendTextInput(field.dataIndex, textValue)}
                  data-qa={'allocation-input-text'}
                  value={textValue}
                />
              </Tooltip>
            </div>
          );
        case 'integer':
          const maybeNumberValue = (data[field.dataIndex] as any) as number;
          const boundHandleNumericInputChange = (value: string | number | null) => {
            if (value && typeof value === 'number') {
              return this.handleNumericInputChange(field.dataIndex, value);
            } else if (value && typeof value === 'string') {
              this.handleTextInputChange(({
                target: { id: field.dataIndex, value },
              } as any) as React.ChangeEvent<HTMLInputElement>);
            }
          };
          return (
            <React.Fragment>
              <FormControlLabel key={field.text} value={field.dataIndex} control={<div />} label={field.text} />
              <InputInteger
                id={`${field.dataIndex}`}
                onChange={boundHandleNumericInputChange}
                onBlur={() => this.sendTextInput(field.dataIndex, maybeNumberValue)}
                data-qa={'allocation-input-number'}
                editable={field.editable}
                value={maybeNumberValue}
              />
            </React.Fragment>
          );
        default:
          return UnknownEditorType;
      }
    });
  };

  renderSectionPanels = () => {
    if (isNil(this.props.config)) {
      return [];
    }

    return this.props.config.sections.map((sectionConfig, index) => {
      const panelKey = `${PanelKeyText}${index}`;
      const expanded = this.state.currentPanel === panelKey;

      return (
        <Accordion expanded={expanded} onChange={() => this.handlePanelChange(panelKey)} key={panelKey} data-qa="parameter-section">
          <AccordionSummary
            className={classes(
              expanded ? classes(styleEditStyles.stickyHeaderArrowExpanded) : styleEditStyles.stickyHeaderArrow
            )}
            expandIcon={<i className={`fas fa-angle-down`} />}
          >
            <div className={styles.sectionPanel}>
              <i className={classes(sectionConfig.icon, 'panel-icon')} />
              <span className={styleEditStyles.sectionText}>{sectionConfig.title}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ minHeight: 'initial' }} className={styleEditStyles.scrollableSectionDetails}>
            {expanded ? this.renderSectionParameters(sectionConfig.fields) : <div />}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  render() {
    const { isLoading, config } = this.props;

    if (isLoading || isNil(config) || isNil(this.state.data)) {
      return <Overlay type="loading" visible={true} />;
    }

    const subheaderProps: SubheaderOwnProps = {
      title: config.title,
      showAlternateFlowStatus: false,
      showAlternateSearch: false,
    };

    return (
      <div className={styles.toggleContainer} data-qa="parameter-container">
        <Subheader {...subheaderProps} />
        <section className={styles.accordionContainer}>{this.renderSectionPanels()}</section>
        <section className={fabBtn}>
          <Tooltip title="Allocate all worklist items" enterDelay={0} arrow>
            <Fab color="secondary" aria-label="Execute Allocation Plan" onClick={this.executeAllocationPlan}>
              <i style={{ color: 'white' }} className={classes('fal fa-large fa-forward')} />
            </Fab>
          </Tooltip>
        </section>
      </div>
    );
  }
}
