import * as React from 'react';
import { isNil, forEach, isEmpty } from 'lodash';
import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';
import { PickerProps } from './RangePickerEditor.container';
import { NumberedWeek } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import { makeModalOverlay } from './Editor.styles';
import { createRowSelectionObj } from './Editors.utils';
import { fillNulls } from 'src/utils/Functions/ArrayUtils';

type RangePickerSelectionLabel = {
  label: string;
  selected?: boolean;
};

type PickerState = {
  overlayWidth: number;
  overlayHeight: number;
};

function generateDefaultLabels(labelCount: number) {
  const labels: RangePickerSelectionLabel[] = [];

  for (let index = 0; index < labelCount; index++) {
    labels.push({ label: `Week ${index + 1}` });
  }

  return labels;
}

export class RangePickerEditor extends React.Component<PickerProps, PickerState> {
  constructor(props: PickerProps) {
    super(props);
    this.state = {
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    };

    window.addEventListener('resize', this.resizeOverlay.bind(this));
  }

  resizeOverlay() {
    this.setState({
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    });
  }

  render() {
    const {
      mergedRangeList,
      onNewWeeksSelectedMulti,
      onCalendarCancel,
      styleOverrides,
      column,
      api,
      makeModal,
      disableOverlap,
      planCurrent,
      startWithPlanCurrent,
      checkboxIndices,
      lifecycleData,
      updateLifecycleData,
      col,
      planEnd,
    } = this.props;

    let allowFrom = '';
    let allowTo = '';

    const rangeListToUse = mergedRangeList && mergedRangeList.end_date;
    const rangeKeys = rangeListToUse && Object.keys(rangeListToUse);
    if (rangeKeys) {
      const orderedDates = rangeKeys.sort((a, k) => Number(a.replace(/-/g, '')) - Number(k.replace(/-/g, '')));
      allowFrom = startWithPlanCurrent ? planCurrent : rangeListToUse[orderedDates[0]];
      allowTo = rangeListToUse[orderedDates[orderedDates.length - 1]];
    }

    // get's stylecolor id for getting correct data value
    const colId = column.getColId();
    let initialSelectedArray: NumberedWeek[] = [];
    let selectionLabels: RangePickerSelectionLabel[] = [];

    // set initial selected values from dataIndices in config
    forEach(this.props.multiRangeDataIndices, (value, key) => {
      if (isNil(api)) {
        return;
      }

      const defaultRowNode = api.getRowNode(key);
      let dataValue = defaultRowNode && api.getValue(colId, defaultRowNode);

      if ((isNil(dataValue) || dataValue === '') && !isNil(value.fallbackDataIndex)) {
        if (value.fallbackDataIndex === '$PLAN_CURRENT') {
          dataValue = planCurrent;
        } else {
          // attempt to set initial value to fallback value
          const fallbackRowNode = api.getRowNode(value.fallbackDataIndex);
          dataValue = fallbackRowNode && api.getValue(colId, fallbackRowNode);
        }
      }

      const selectionObj = createRowSelectionObj(dataValue, mergedRangeList);
      initialSelectedArray = [...initialSelectedArray, selectionObj];
      selectionLabels = [...selectionLabels, { label: value.optionLabel, selected: key === col }];
    });

    const hasCheckboxes = checkboxIndices && lifecycleData && updateLifecycleData;
    const checkboxOptions =
      hasCheckboxes &&
      Object.keys(checkboxIndices).map((x) => ({
        dataIndex: x,
        label: checkboxIndices[x].optionLabel,
        value: lifecycleData[x],
      }));

    initialSelectedArray = fillNulls(initialSelectedArray); // should this be down in the `WeekRangePicker`?
    const content = (
      <WeekRangePicker
        autoSelect={true}
        allowFrom={allowFrom}
        allowTo={allowTo}
        dateToNameMap={mergedRangeList}
        initialSelectedArray={initialSelectedArray}
        // @ts-ignore
        onNewWeeksSelectedMulti={onNewWeeksSelectedMulti}
        onCalendarCancel={onCalendarCancel}
        selectionOptions={
          !isEmpty(selectionLabels) ? selectionLabels : generateDefaultLabels(initialSelectedArray.length)
        }
        hideInput={true}
        styleOverrides={styleOverrides}
        disableOverlap={disableOverlap}
        checkboxOptions={checkboxOptions}
        updateLifecycleData={
          hasCheckboxes
            ? (newValue: { [s: string]: string }) => updateLifecycleData(lifecycleData.id, newValue)
            : undefined
        }
        planEnd={planEnd}
      />
    );

    if (makeModal) {
      return (
        <div
          className={makeModalOverlay(true)}
          style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}
        >
          {content}
        </div>
      );
    }
    return content;
  }
}
