import React, { useMemo } from 'react';
import type { GridCellProps } from 'react-virtualized';
import { Grid } from 'react-virtualized/dist/es/Grid';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { isEmpty, isNil } from 'lodash';
import { CustomNoRowsOverlay } from 'src/components/ConfigurableGrid/EditableGrid/EditableGrid.subcomponents';
import {
  calcTrendCardWidth,
  calcTrendCardHeight,
  TrendCardProps,
  TrendCard,
  TrendCardType,
} from 'src/components/TrendCard/TrendCard';
import styles from 'src/components/TrendCard/TrendCard.styles';
import { convertToMatrix } from 'src/common-ui/components/CardsGrid/CardsGrid';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import Renderer from 'src/utils/Domain/Renderer';
import { generatePillRendererProps } from '../TrendDetails/TrendDetails.utils';
import { VariancePillConfig } from '../TrendDetails/TrendDetails';

export interface TrendSummaryCardConfig {
  trendCardType: TrendCardType;
  title: string;
  trendDataIndex: string;
  trendRenderer: string;
  trendSummary: string;
  currentDataIndex: string;
  currentRenderer: string;
  pills: VariancePillConfig[];
}

export interface TrendSummaryConfig {
  cards: TrendSummaryCardConfig[];
}

export interface TrendSummaryProps {
  config: TrendSummaryConfig;
  data: BasicPivotItem[];
}

export const TrendSummary = ({ data, config }: TrendSummaryProps) => {
  const cardWidth = calcTrendCardWidth();
  const cardHeight = calcTrendCardHeight();
  const summaryData: BasicPivotItem | undefined = useMemo(() => {
    return !isNil(data[0]) ? data[0] : undefined;
  }, [data]);

  const gridItems: TrendCardProps[] = useMemo(() => {
    return summaryData
      ? config.cards.map(
          ({ trendCardType, title, trendDataIndex, trendRenderer, currentDataIndex, currentRenderer, pills }) => {
            const trendValueRenderer = Renderer[trendRenderer];
            const trendValue = summaryData[trendDataIndex] || 0;
            const trendValueText = !isNil(trendValueRenderer) ? trendValueRenderer(trendValue) : trendValue;
            const currentValueRenderer = Renderer[currentRenderer];
            const currentValue = summaryData[currentDataIndex] || 0;
            const currentValueFormatted = !isNil(currentValueRenderer)
              ? currentValueRenderer(currentValue)
              : currentValue;
            const [primary, secondary] = generatePillRendererProps(summaryData, pills);

            return {
              trendCardType,
              title,
              trendValue,
              trendValueText,
              trendSummary: '',
              currentValue: currentValueFormatted,
              primaryPill: primary,
              secondaryPill: secondary,
            };
          }
        )
      : [];
  }, [config.cards, summaryData]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className={styles.cardContainer}
      data-qa="StandardCardViewContainer"
    >
      <div>Trend Summary</div>
      {isEmpty(data) ? (
        <CustomNoRowsOverlay />
      ) : (
        <AutoSizer>
          {({ height: autoSizerHeight, width: autoSizerWidth }) => {
            const columnCount = Math.floor(autoSizerWidth / cardWidth);
            const rowCount = Math.ceil(gridItems.length / columnCount);

            if (columnCount === 0 || rowCount === 0) {
              return null;
            }

            // convert gridItems to matrix for grid lookup based on row/column indices
            const itemsMatrix = convertToMatrix<TrendCardProps>(gridItems, columnCount);
            return (
              <Grid
                cellRenderer={({ columnIndex, rowIndex, key, style }: GridCellProps) => {
                  if (itemsMatrix[rowIndex] == null || itemsMatrix[rowIndex][columnIndex] == null) return;
                  return (
                    <div style={style}>
                      <TrendCard key={key} {...itemsMatrix[rowIndex][columnIndex]} />
                    </div>
                  );
                }}
                height={autoSizerHeight}
                width={autoSizerWidth}
                columnCount={columnCount}
                columnWidth={cardWidth}
                rowCount={rowCount}
                rowHeight={cardHeight}
                overscanRowCount={10}
              />
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
};
