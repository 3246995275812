import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import './_Review.scss';
import {
  mapStateToProps,
  mapDispatchToProps,
  ReviewCorporatePlansDispatchProps,
} from './review-corporate-plans.container';
import { PlanMetadata, VERSION_SAVED, VERSION_SMART } from 'src/state/scope/codecs/PlanMetadata';
import PlanMetadataGrid, { PlanMetadataRef } from 'src/components/Mfp/PlanMetadataGrid/PlanMetadataGrid';
import { formatDate } from 'src/utils/Domain/time.utils';
import {
  dayJsGridFilterComparator,
  dayJsGridSortComparator,
} from 'src/components/Mfp/PlanMetadataGrid/PlanMetadataGrid.utils';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { ContextMfpModule } from 'src/services/configuration/codecs/confdefn';
export interface ReviewPrivatePlansValueProps {
  currentModule: ContextMfpModule | undefined;
  publishVersions: string[] | null;
}

interface ReviewPrivatePlansOwnProps {
  serviceEnv: typeof ServiceContainer;
}

type ReviewPrivatePlansGridProps = ReviewCorporatePlansDispatchProps &
  ReviewPrivatePlansValueProps &
  ReviewPrivatePlansOwnProps;

const ReviewPrivatePlans = (props: ReviewPrivatePlansGridProps) => {
  const { deletePlan } = props;
  const client = ServiceContainer.axios;
  // TODO: fix this type as I believe this will break with react-redux > 5.x
  const gridRef = useRef<{ getWrappedInstance: () => PlanMetadataRef }>();

  const handleDeletePlan = useCallback(
    (plan: PlanMetadata) => {
      const planId = plan.id;
      deletePlan({ client, planId }).then(() => {
        if (gridRef.current) {
          gridRef.current.getWrappedInstance().refreshData();
        }
      }).catch(() => {
        toast(<div>Failed to delete plan.</div>, {
          position: toast.POSITION.TOP_RIGHT,
          type: 'error',
        })
      });
    },
    [client, deletePlan]
  );

  return (
    <div className={'review-container'} data-qa={'review-plans'}>
      <h1 className="title">Review Private Plans</h1>
      <PlanMetadataGrid
        // @ts-ignore
        // ref={gridRef}
        extraColDefs={[
          {
            field: 'name',
            colId: 'name',
          },
          {
            field: 'createdAt',
            colId: 'createdAt',
            headerName: 'Saved At',
            valueFormatter: formatDate,
            comparator: dayJsGridSortComparator,
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: dayJsGridFilterComparator,
              newRowsAction: 'keep',
            },
          },
          {
            colId: 'deletePlan',
            headerName: 'Delete Plan',
            cellRenderer: 'deleteIconRenderer',
            cellRendererParams: {
              onClick: handleDeletePlan,
            },
          },
        ]}
        extraFilters={{ version: [VERSION_SAVED.value, VERSION_SMART.value], ownedBySelf: true }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPrivatePlans);
