import { MenuItemDef, GridApi, IRowNode, GetContextMenuItemsParams } from '@ag-grid-community/core';
import { WorklistService } from 'src/services/Worklist/Worklist.service';
import { FunctionProps } from 'src/components/ListGridPair/ListGridPair';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { isEmpty, isNil } from 'lodash';
import { WorklistApiPayload } from 'src/dao/Worklist.client';
import { XOR } from 'src/types/Primitive';

const ICON_PREFIX = 'fa fa-fw';

const iconClassToIconElement = (iconClass: string) => {
  const iconElement = window.document.createElement('i');
  iconElement.className = `${ICON_PREFIX} ${iconClass}`;
  return iconElement;
};

const iconClassToString = (iconClass: string) => {
  return `${ICON_PREFIX} ${iconClass}`;
};

export interface NeededGetContextMenUItemsParams {
  node: Pick<IRowNode, 'data'>;
}

export enum ContextMenuType {
  grid = 'grid',
  card = 'card',
  stylePreview = 'stylePreview',
}
export type ContextMenuCreationParams =
  | {
      identityField: string;
      parentIdentityField: string;
      type: ContextMenuType.grid;
      openStylePane: FunctionProps['openStylePane'];
      onPostAction?: () => void;
    }
  | {
      identityField: string;
      parentIdentityField: string;
      type: ContextMenuType.card | ContextMenuType.stylePreview;
      openStylePane: () => void;
      onPostAction?: () => void;
    };

export enum WorklistContextMenuAction {
  add = 'Add to Worklist',
  addSelected = 'Add Selected to Worklist',
  snoozeDay = 'Snooze until Next Day',
  snoozeDaySelected = 'Snooze Selected until Next Day',
  snoozeWeek = 'Snooze until Next Week',
  snoozeWeekSelected = 'Snooze Selected until Next Week',
  markReviewed = 'Mark as Reviewed',
  markReviewedSelected = 'Mark Selected as Reviewed',
  remove = 'Remove from Worklist',
  removeSelected = 'Remove Selected from Worklist',
  reviewStyleDetails = 'Review Style Details',
  editParams = 'Edit Parameters',
  editPricingFlow = 'Edit Pricing and Flow',
  poApproval = 'PO Approval View',
}

const NO_ITEMS_TEXT = 'No items selected for action.';

interface WorklistPreActions {
  onPreAction?: () => void;
}
type WorklistPostActions = Pick<ContextMenuCreationParams, 'onPostAction'>;
type SingleWorklistAction = {
  singleAction: (params: string) => Promise<WorklistApiPayload>;
  param: string;
};
type MultiWorklistAction = {
  multiAction: (params: string[]) => Promise<WorklistApiPayload>;
  params: string[];
};

type WorklistActions = XOR<SingleWorklistAction, MultiWorklistAction> & {
  actions: WorklistPreActions & WorklistPostActions;
  errorMessage: string;
};

const getSelectedItems = (identityField: string, getGridApi: () => GridApi | undefined): string[] => {
  const selectedRows: any = [];
  const gridApi = getGridApi();

  if (isNil(gridApi)) {
    return [];
  }

  gridApi.forEachNodeAfterFilter((node) => {
    if (node.data.exceptionselect) {
      selectedRows.push(node.data);
    } else if (node.isSelected()) {
      selectedRows.push(node.data);
    }
  });

  return selectedRows.map((row: any) => row[identityField]);
};

const runWorklistActions = ({ singleAction, multiAction, param, params, actions, errorMessage }: WorklistActions) => {
  let action;

  if (singleAction && param) {
    action = singleAction(param);
  } else if (multiAction && params) {
    action = multiAction(params);
  } else {
    return; // do nothing just in case
  }

  if (actions.onPreAction) {
    actions.onPreAction();
  }

  action
    .then(() => {
      // TODO: used to refetchWorklist() here, what now
    })
    .catch((e) => {
      toast.error(errorMessage);
      ServiceContainer.loggingService.error(errorMessage, e.stack);
    })
    .finally(() => {
      if (actions.onPostAction) {
        actions.onPostAction();
      }
    });
};

export const getWorklistMultiselectMenuItems = (
  identityField: string,
  getGridApi: () => GridApi | undefined,
  actions: WorklistPreActions & WorklistPostActions
): MenuItemDef[] => {
  const worklistService = WorklistService();
  return [
    {
      name: WorklistContextMenuAction.addSelected,
      action() {
        const selectedItems = getSelectedItems(identityField, getGridApi);
        if (isEmpty(selectedItems)) {
          toast.warn(NO_ITEMS_TEXT);
          return;
        }

        runWorklistActions({
          multiAction: worklistService.addItemsToWorklist,
          params: selectedItems,
          actions,
          errorMessage: 'An error occured adding items to the worklist',
        });
      },
      icon: iconClassToString('fal fa-plus-circle'),
      tooltip: 'Add selected items to the worklist',
    },
    {
      name: WorklistContextMenuAction.snoozeDaySelected,
      action() {
        const selectedItems = getSelectedItems(identityField, getGridApi);
        if (isEmpty(selectedItems)) {
          toast.warn(NO_ITEMS_TEXT);
          return;
        }

        runWorklistActions({
          multiAction: worklistService.snoozeItemsOneDay,
          params: selectedItems,
          actions,
          errorMessage: 'An error occured snoozing items',
        });
      },
      icon: iconClassToString('fal fa-bed'),
      tooltip: 'Snooze selected items until tomorrow',
    },
    {
      name: WorklistContextMenuAction.snoozeWeekSelected,
      action() {
        const selectedItems = getSelectedItems(identityField, getGridApi);
        if (isEmpty(selectedItems)) {
          toast.warn(NO_ITEMS_TEXT);
          return;
        }

        runWorklistActions({
          multiAction: worklistService.snoozeItemsOneWeek,
          params: selectedItems,
          actions,
          errorMessage: 'An error occured snoozing items',
        });
      },
      icon: iconClassToString('fal fa-alarm-clock'),
      tooltip: 'Snooze selected item for seven days',
    },
    {
      name: WorklistContextMenuAction.markReviewedSelected,
      action() {
        const selectedItems = getSelectedItems(identityField, getGridApi);
        if (isEmpty(selectedItems)) {
          toast.warn(NO_ITEMS_TEXT);
          return;
        }

        runWorklistActions({
          multiAction: worklistService.markItemsAsReviewed,
          params: selectedItems,
          actions,
          errorMessage: 'An error occured marking items for review',
        });
      },
      icon: iconClassToString('fal fa-clipboard-check'),
      tooltip: 'Mark selected exceptions as having been reviewed, and no longer show them',
    },
    {
      name: WorklistContextMenuAction.removeSelected,
      action() {
        const selectedItems = getSelectedItems(identityField, getGridApi);
        if (isEmpty(selectedItems)) {
          toast.warn(NO_ITEMS_TEXT);
          return;
        }

        runWorklistActions({
          multiAction: worklistService.removeItemsFromWorklist,
          params: selectedItems,
          actions,
          errorMessage: 'An error occured removing items from the worklist',
        });
      },
      icon: iconClassToString('fal fa-minus-circle'),
      tooltip: 'Remove selected items from the worklist',
    },
  ];
};

export const getWorklistContextMenuItems = (
  extraData: ContextMenuCreationParams,
  params: GetContextMenuItemsParams
): ((MenuItemDef & { hidden?: boolean }) | string)[] => {
  const colDef = params.column && params.column.getColDef();
  if (colDef?.cellClass !== 'highlight') {
    return [];
  }
  const worklistService = WorklistService();
  const param: string = params.node ? params.node.data[extraData.identityField] : '';
  const actions: WorklistPostActions = {
    onPostAction: extraData.onPostAction,
  };

  return [
    {
      name: WorklistContextMenuAction.add,
      action() {
        runWorklistActions({
          singleAction: worklistService.addToWorklist,
          param,
          actions,
          errorMessage: 'An error occured adding an item to the worklist',
        });
      },
      icon: iconClassToIconElement('fal fa-plus-circle'),
      tooltip: 'Add this item to the worklist',
    },
    {
      name: WorklistContextMenuAction.snoozeDay,
      action() {
        runWorklistActions({
          singleAction: worklistService.snoozeOneDay,
          param,
          actions,
          errorMessage: 'An error occured snoozing an item',
        });
      },
      icon: iconClassToIconElement('fal fa-bed'),
      tooltip: 'Snooze this item until tomorrow',
    },
    {
      name: WorklistContextMenuAction.snoozeWeek,
      action() {
        runWorklistActions({
          singleAction: worklistService.snoozeOneWeek,
          param,
          actions,
          errorMessage: 'An error occured snoozing an item',
        });
      },
      icon: iconClassToIconElement('fal fa-alarm-clock'),
      tooltip: 'Snooze this item for seven days',
    },
    {
      name: WorklistContextMenuAction.markReviewed,
      action() {
        runWorklistActions({
          singleAction: worklistService.markAsReviewed,
          param,
          actions,
          errorMessage: 'An error occured marking an item for review',
        });
      },
      icon: iconClassToIconElement('fal fa-clipboard-check'),
      tooltip: 'Mark this exceptions as having been reviewed, and no longer show it',
    },
    {
      name: WorklistContextMenuAction.remove,
      action() {
        runWorklistActions({
          singleAction: worklistService.removeFromWorklist,
          param,
          actions,
          errorMessage: 'An error occured removing an item from the worklist',
        });
      },
      icon: iconClassToIconElement('fal fa-minus-circle'),
      tooltip: 'Remove this item from the worklist',
    },
    'separator',
    {
      name: WorklistContextMenuAction.reviewStyleDetails,
      action() {
        // check for stylepaneable, if not, do nothing
        if (
          params.node &&
          params.node.data[extraData.identityField] &&
          params.node.data[extraData.parentIdentityField]
        ) {
          if (extraData.type === ContextMenuType.card) {
            extraData.openStylePane();
          } else {
            extraData.openStylePane({
              id: params.node.data[extraData.identityField],
              parentId: params.node.data[extraData.parentIdentityField],
            });
          }

          // handle any post actions
          if (extraData.onPostAction) {
            extraData.onPostAction();
          }
        }
      },
      icon: iconClassToIconElement('fal fa-binoculars'),
      tooltip: 'Open the style pane for this item to view its details',
    },
    {
      name: WorklistContextMenuAction.editParams,
      action: () => undefined,
      icon: iconClassToIconElement('fal fa-edit'),
      tooltip: "Edit this item's parameters (currently disabled)",
      disabled: true,
      hidden: true,
    },
    {
      name: WorklistContextMenuAction.editPricingFlow,
      action: () => undefined,
      icon: iconClassToIconElement('fal fa-tags'),
      tooltip: "Edit this item's pricing and flow (currently disabled)",
      disabled: true,
      hidden: true,
    },
    {
      name: WorklistContextMenuAction.poApproval,
      action: () => undefined,
      icon: iconClassToIconElement('fal fa-boxes'),
      tooltip: 'Navigate to the PO Approval view for this item',
      disabled: true,
      hidden: true,
    },
  ];
};
