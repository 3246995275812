import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { LYTargetData } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { ColDef, ValueParserParams, ValueGetterParams, ValueFormatterParams } from '@ag-grid-community/core';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import { SalesAdjustmentConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/SalesAdjustmentEditor';
import { ScrollTo } from 'src/common-ui/components/DataGrid/DataGrid';
import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { ParamedCalc } from 'src/utils/LibraryUtils/MathUtils';
import { StyleEditConfigColumn } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { AppThunkDispatch } from 'src/store';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ClientDataApi, DataApi } from 'src/services/configuration/codecs/confdefnView';
import { MassEditConfig } from '../MassEdit/MassEditv2';
import { z } from 'zod';
import { ConfigurableGridComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { SelectorSubheaderDropdownProps } from './ConfigurableGrid.selectors';
import { ValidValuesCache } from 'src/services/validValuesCache';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { ConfigurablePostAction } from 'src/services/configuration/codecs/viewdefns/general';
import { Scope } from 'src/types/Scope';
import { ViewDataState } from 'src/types/Domain';
import { Option } from '../Configure/ConfigureModal';
import { ParseResult } from '../Configure/Configure';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FabProps } from 'src/components/higherOrder/withFab';
import { WithRouterProps } from 'src/components/higherOrder/withRouter';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';

export interface GridField {
  headerName?: string;
  colId?: string;
  pinned?: boolean;
  cellClass?: string | string[];
  renderer?: string;
  hide?: boolean;
  valueParser?: (params: ValueParserParams) => any | string;
  valueGetter?: ((params: ValueGetterParams) => any) | string;
  valueFormatter?: (params: ValueFormatterParams) => string | string;
  footerValueGetter?: ((params: ValueGetterParams) => any) | string;
  editable?: boolean;
  field?: string;
  aggFunc?: string;
  dataIndex?: string;
}

export enum AggOptions {
  Sum = 'sum',
  Min = 'min',
  Max = 'max',
  Count = 'count',
  Avg = 'avg',
  First = 'first',
  Last = 'last',
  TotalName = 'totalName',
  Eval = 'eval',
}
export interface ColumnConfig extends Omit<TenantConfigViewItem, 'pinned'> {
  pinned?: boolean;
  editable?: boolean;
  calculation?: string;
  inputType?: string;
  dataApi?: DataApi;
  configApi?: ClientDataApi;
  renderer?: string;
  hidden?: boolean;
  width?: number;
  children?: ColumnConfig[];
  headerName?: string;
  aggregator?: AggOptions;
  enableValue?: boolean;
}

export interface TrueColDef extends ColDef {
  renderer?: string;
  footerValueGetter?: () => string; // subject to extensive change. Lazy right now
}

export interface ValueProps {
  config: ColumnConfig[] | null;
  rowData: BasicItem[];
}

export interface FunctionProps {
  onSave: (rowData: LYTargetData[]) => void;
  reinitialize: () => void;
  setConfigurations?: (configurations: any) => void;
}

export interface Props extends ValueProps, FunctionProps {}

export interface ConfigurableGridColumnDef extends StyleEditConfigColumn {
  useMassEditUpdate: boolean; // uses new endpoint for updates (currently single datapoint update only)
  postArrayAsString?: boolean;
  width?: number;
  dataApiLifecycle?: ClientDataApi;
  dataApiStore?: ClientDataApi;
  lifecycleConfig?: ClientDataApi;
  storeConfig?: ClientDataApi;
  dependentsApi?: ClientDataApi;
  cascadeGroupSelection?: boolean;
  dependents?: string[];
}

export interface ConfigurableGridDispatchProps {
  dispatch: AppThunkDispatch;
  setFloorsetSelection: ActionCreatorWithPayload<string>;
  setGroupBySelection: ActionCreatorWithPayload<ConfigurableGridGroupBySelection>;
  resetGroupBySelection: ActionCreatorWithoutPayload;
  onUpdateConfig: (config: any) => void;
  updateAssortmentPlan: () => void;
  onRefreshConfigurableGridData: () => void;
  submitPayload: (payload: CoarseEditPayload) => Promise<void>;
  updateConfigureSelections?(selections: Option[]): void;
  addSelectedItemsToCart(): void;
  toggleSelections(selections: BasicPivotItem | BasicPivotItem[]): void;
}

export interface ConfigurableGridValueProps {
  /** @param identifier this is used for linking components (f/e companion list and grid) */
  identifier: string;
  /** @param  idProp is used for grid level row identification (for example, row selection) */
  leafIdProp: string;
  configLoaded: boolean;
  dataLoaded: boolean;
  favoritesList: FavoriteResponseItem[];
  groupBySelection?: ConfigurableGridGroupBySelection;
  configureOptions?: ParseResult;
  configureSelections?: Option[];
  columnDefs: ConfigurableGridColumnDef[];
  clientActionHandlers?: ClientActionHandler;
  dependentCalcs: {
    [key: string]: ParamedCalc;
  };
  massEditConfig?: MassEditConfig;
  updateCoordinateMap?: {
    [s: string]: string;
  };
  // TODO: these need to point to viewdefn validator type
  // and fix types that have been ignored trickling down
  configuratorViewDefn?: any;
  unmodifiedViewDefn?: any;
  gridRowHeight?: number;
  groupRowHeight?: number;
  defaultCompanionSortField?: string;
  companionSortOptions: SortOption[];
  groupByDropdownProps?: SelectorSubheaderDropdownProps;
  floorsetDropdownProps?: SelectorSubheaderDropdownProps;
  data: BasicPivotItem[];
  topAttributesData: BasicPivotItem | undefined;
  salesAdjustmentConfig?: SalesAdjustmentConfig;
  flowStatus: number[];
  search: string;
  showPublish?: boolean;
  hideUnpublish?: boolean;
  adornments: AdornmentType[];
  publishText?: string;
  publishAttribute?: string;
  configurablePostAction: ConfigurablePostAction;
  scopeSelection: Scope;
  viewDataState: ViewDataState;
  showUndo: boolean;
  isWorklistActive: boolean;
  fab: FabProps;
  assortmentCartLink?: string;
  cartCount?: number;
  allowWorklistFunctionality?: boolean;
  enableCheckboxSelection?: boolean;
}

// These remain a type because of the or in ConfigurableGridOwnProps
export type ConfigurableGridProps = ConfigurableGridOwnProps &
  ConfigurableGridDispatchProps &
  ConfigurableGridValueProps &
  StyleDetailsPopoverProps &
  WithRouterProps;

export interface ConfigurableGridOwnProps extends z.infer<typeof ConfigurableGridComponentProps>, PrintProps {}

export interface ConfigurableGridState {
  /** used to signal to MassEdit that the grid is ready */
  gridRdyCnt: number;
  selectedIndex?: number;
  selectedId?: string;
  gridScrollTo?: ScrollTo;
  companionScrollTo?: ScrollTo;
  companionCollapsed: boolean;
  companionSortDirection: SortByDirection;
  companionSortField?: string;
  activeStyleColor: string;
  subheaderDropdownConfig?: SubheaderDropdownProps[];
  favoritesList?: FavoriteResponseItem[];
  groupByIndexTemp?: number;
  notificationModal: boolean;
  actionType: 'publish' | 'unpublish' | undefined;
  validValuesCache: ValidValuesCache;
  configureIsOpen?: boolean;
  currentConfigureSelections?: Option[];
}

export interface TemplateRendererParams {
  mask: string;
  maskParams: Record<string, string>;
}
export interface ConfigurableGridConfigItem extends StyleEditConfigColumn {
  rendererParams?: TemplateRendererParams;
  width?: number;
  dataApiLifecycle?: ClientDataApi;
  dataApiStore?: ClientDataApi;
  lifecycleConfig?: ClientDataApi;
  storeConfig?: ClientDataApi;
  dependentsApi?: ClientDataApi;
  postArrayAsString?: boolean;
  cascadeGroupSelection?: boolean;
  tabIndex?: number;
  dependents?: string[];
  wrapText?: boolean;
  wrapHeaderText?: boolean;
}

export interface ClientActionHandler {
  [clientHandler: string]: string[]; // value is array of dataIndices that require clientHandler logic
}

export interface ClientActionHandlersConfig {
  [action: string]: ClientActionHandler;
}

/**
 * State required to be implemented by any popup editors that are enabled for aggregate cell edits
 * */
export interface ConfigurableGridAggCellEditorState {
  valueChosen: boolean;
}

export interface MassColumnUpdateParams {
  value: string;
  dataIndex: string;
  nodes: BasicPivotItem[];
}

export enum AsyncCellState {
  Idle = 'Idle',
  Processing = 'Processing',
  Redecorating = 'Redecorating',
}
