import React from 'react';
import { get, identity, isEmpty, isNil, noop } from 'lodash';
import { connect } from 'react-redux';
import ServiceContainer from 'src/ServiceContainer';

import { AppState, AppThunkDispatch } from 'src/store';
import { WrappedDispatch } from 'src/utils/Redux/Dispatch';
import { getDisplayName } from './ScopeSensitive';
import { getScopeReadyData, getScopeId } from 'src/state/scope/Scope.types';
import { DependentScopeType } from 'src/services/configuration/codecs/confdefn';
import { TopMembers, ScopeCreateRequest } from 'src/services/Scope.client';
import { getScope, newScope } from 'src/state/scope/Scope.actions';
import { narrowWorkflow } from '../Mfp/MfpScopeSelector/MfpScopebar.container';
import { WithRouterProps } from './withRouter';
import { withRouter } from 'src/components/higherOrder/withRouter';
import ScopebarOptionModal from '../Mfp/MfpScopebarOption/ScopebarOptionModal';
import InitializePlan from '../Mfp/InitializePlan/InitializePlan';

export interface MfpScopeCreate {
  onNewScope?(): void;
}

interface InjectedProps<ParentProps> {
  requiresDependentScope: boolean;
  hasMfpReadyScope: boolean;
  isFetchingScope: boolean;
  hasAvailableMembers: boolean;
  scopeId: string | undefined;
  showInitiliazePlan: boolean;
  parentProps: ParentProps;
}

export const checkIfRequireMfpScope = (
  perspectiveConfig: AppState['appConfig']['tenantConfig']['perspective']
): boolean => {
  const scopeConfig = perspectiveConfig?.scopeConfig;
  return (
    scopeConfig?.type === 'AssortmentScopeSelector' &&
    !isNil(scopeConfig?.dependentScopes) &&
    scopeConfig?.dependentScopes?.findIndex((el) => el.type === DependentScopeType.enum.MfpScope) >= 0
  );
};

function mergeProps<ParentProps>(
  state: AppState,
  dispatchProps: WrappedDispatch,
  parentProps: ParentProps
): InjectedProps<ParentProps> {
  const scopeId = getScopeId(state.mfpScope);
  const readyScope = getScopeReadyData(state.mfpScope);
  const scopeConfig = state.appConfig.tenantConfig.perspective?.scopeConfig;
  const hasAvailableMembers = !!state.viewConfigSlice.availableMembers;
  const isFetchingScope = get(state.mfpScope, 'isFetching', false);
  const requiresDependentScope = checkIfRequireMfpScope(state.appConfig.tenantConfig.perspective);

  if (!scopeConfig || !readyScope) {
    return {
      requiresDependentScope,
      hasMfpReadyScope: false,
      hasAvailableMembers: false,
      scopeId: undefined,
      parentProps,
      isFetchingScope,
      showInitiliazePlan: false,
      ...dispatchProps,
    };
  }

  // Only when in an assortment perspective that requires mfp dependent scopes
  // does this component attempt to create a matching mfp scope for the current assortment scope
  // as well as handles showing the initialization modal

  const hasMfpReadyScope = !!readyScope;

  const showInitiliazePlan =
    scopeConfig &&
    !isEmpty(readyScope.mainConfig.uninitializedPlans) &&
    !!scopeId &&
    // !isScopeVisible &&
    !isFetchingScope;

  return {
    requiresDependentScope,
    hasMfpReadyScope,
    hasAvailableMembers,
    scopeId,
    parentProps,
    isFetchingScope,
    showInitiliazePlan,
    ...dispatchProps,
  };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    onNewScope: (topMembers: TopMembers, workflow: string) => {
      const request: ScopeCreateRequest = {
        initParams: { type: 'with-wp' },
        workflow: narrowWorkflow(workflow),
        anchor: topMembers,
      };
      return dispatch(() => {
        return dispatch<Promise<unknown>>(newScope(ServiceContainer.axios, request));
      });
    },
    getScope: (scopeId: string) => {
      dispatch(getScope({ scopeId }));
    },
    // Spike what should we do here?
    clearScope: () => (window.location.hash = '#/'),
  };
};

type MfpScopeSensitiveProps = ReturnType<typeof mergeProps> & ReturnType<typeof mapDispatchToProps> & WithRouterProps;
interface MfpScopeSensitiveState {
  isFetchingScope: boolean;
}

export function makeMfpScopeSensitive(WrappedComponent: React.ComponentType) {
  class MfpScopeSensitive extends React.Component<MfpScopeSensitiveProps, MfpScopeSensitiveState> {
    displayName = `MfpScopeSensitive(${getDisplayName(WrappedComponent)})`;
    wrappedComponentRef: React.RefObject<MfpScopeSensitiveProps> = React.createRef();
    constructor(props: MfpScopeSensitiveProps) {
      super(props);
      this.state = {
        isFetchingScope: false,
      };
      this.createNewScopeIfNeeded.bind(this);
    }

    createNewScopeIfNeeded() {
      // SPIKE: can we easily move this all to an epic that listens only to mfp views?
      const [search] = this.props.routerSearch;
      const queryParamScopeId = search.get('scope');

      // if we need a scope, don't have one and there isn't an id in the url
      // make a new one
      if (
        this.props.requiresDependentScope &&
        !this.props.hasMfpReadyScope &&
        !this.state.isFetchingScope &&
        !queryParamScopeId
      ) {
        this.setState(
          {
            isFetchingScope: true,
          },
          () => {
            // @ts-ignore
            this.props.onNewScope({}, 'pre-season');
          }
        );
      }
    }

    componentDidMount(): void {
      this.createNewScopeIfNeeded();
    }

    componentDidUpdate(): void {
      // SPIKE: can we easily move this all to an epic that listens only to mfp views?
      this.createNewScopeIfNeeded();
    }

    render() {
      const { showInitiliazePlan } = this.props;

      return (
        <React.Fragment>
          <div className="scope-options">
            <ScopebarOptionModal
              isVisible={showInitiliazePlan || false}
              modalTitle={'Initialize The Plan'}
              modalClassName={'initialize-plan-modal'}
              modalBodyComponent={InitializePlan}
              modalBodyComponentProps={{
                copyPeriod: undefined,
                copyVersionOptions: undefined,
                copyVersion: undefined,
                onItemChange: noop,
                onCancel: this.props.clearScope, // TODO make this go back to bubbles screen?
                onSubmit: noop,
                loading: false,
              }}
            />
          </div>
          {/* @ts-ignore */}
          <WrappedComponent ref={this.wrappedComponentRef} {...this.props.parentProps} />
        </React.Fragment>
      );
    }
  }
  // @ts-ignore
  return connect(identity, mapDispatchToProps, mergeProps)(withRouter(MfpScopeSensitive));
}
