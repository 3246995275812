import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { style, classes } from 'typestyle';
import * as StyleEditSectionStyles from '../StyleEditSection.styles';
import { isEqual, intersection, isArray, isEmpty } from 'lodash';
import { isString } from 'lodash';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';

const styles = {
  validsizesContainer: style({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: `${StyleEditSectionStyles.validSizesRowHeight - 10}px`,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '5px 0',
  }),
  validsize: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px lightgrey solid',
    margin: '3px 1px',
    padding: '0 2px',
    height: '22px',
  }),
  checked: style({}),
  unchecked: style({
    border: '1px lightgrey solid',
    background: 'lightgrey',
    color: 'grey',
  }),
  disabled: style({
    cursor: 'not-allowed',
    color: '#555',
  }),
};

interface ValidSizesProps extends ICellRendererParams {
  dataApi?: ClientDataApi;
  options?: string[];
  selectedOptions: string[];
  editable: boolean;
  cursorStyle?: string;
}

interface ValidSizesState {
  dataApiOptions: string[];
}

export class ValidSizesRenderer extends React.Component<ValidSizesProps, ValidSizesState> {
  constructor(props: ValidSizesProps) {
    super(props);
    this.state = {
      dataApiOptions: [],
    };
  }

  componentDidMount() {
    const selections: string[] = this.props.getValue ? this.props.getValue() : [];
    if (this.props.dataApi) {
      const url = getUrl(this.props.dataApi);
      getValidValues(url, false, false, false)
        .then((validValues) => {
          this.setState({
            dataApiOptions: validValues.map((i: { value: string }) => i.value),
          });
        })
        .catch((err) => window.console.log(err));
    } else {
      if (isEmpty(selections) || intersection(selections, this.props.options).length < selections.length) {
        this.selectAllOptions();
      }
    }
  }

  componentDidUpdate(oldProps: ValidSizesProps) {
    if (!isEqual(oldProps.options, this.props.options)) {
      this.selectAllOptions();
    } else if (!isEqual(oldProps.dataApi, this.props.dataApi) && this.props.dataApi != null) {
      const url = getUrl(this.props.dataApi);
      getValidValues(url, false, false, false)
        .then((validValues) => {
          const selections = validValues.map((i: { value: string }) => i.value);
          const newState: Pick<ValidSizesState, keyof ValidSizesState> = {
            dataApiOptions: validValues.map((i: { value: string }) => i.value),
          };
          this.setState(newState, () => {
            this.props.setValue && this.props.setValue(selections);
          });
        })
        .catch((err) => window.console.log(err));
    }
  }

  processSelections(selections: string | string[]) {
    if (isString(selections)) {
      return arrayStringToArray(selections);
    } else {
      return selections;
    }
  }

  getOptions() {
    const selections: string[] = this.props.getValue ? this.props.getValue() : [];
    if (this.props.dataApi != null) {
      return this.state.dataApiOptions;
    } else if (this.props.options != null) {
      return this.props.options;
    } else if (isArray(selections)) {
      return selections as string[];
    } else {
      return [];
    }
  }
  selectAllOptions() {
    const options = this.getOptions();
    if (this.props.editable) {
      this.props.setValue && this.props.setValue(options);
    }
  }

  onClickOption = (optionClicked: string) => {
    const options = this.getOptions();
    if (!this.props.editable) {
      return;
    }
    const selections: string[] = this.props.getValue ? this.props.getValue() : [];
    let newSelections = selections;
    if (options && options.length > 0) {
      newSelections = newSelections.filter((selection) => {
        return options.indexOf(selection) !== -1;
      });
    }
    if (newSelections.indexOf(optionClicked) === -1) {
      newSelections.push(optionClicked);
    } else {
      newSelections = newSelections.filter((selection) => selection !== optionClicked);
    }

    this.props.setValue && this.props.setValue(newSelections);
  };

  render() {
    const { editable } = this.props;
    const selections = this.props.getValue ? this.props.getValue() : [];

    const renderedOptions = this.getOptions();

    return (
      <div className={styles.validsizesContainer}>
        {renderedOptions.map((option) => {
          const className =
            selections.indexOf(option) !== -1 ? `${styles.validsize}` : `${styles.validsize} ${styles.unchecked}`;
          return (
            <div
              className={classes(className, editable ? '' : styles.disabled)}
              style={{ cursor: this.props.cursorStyle }}
              key={option}
              onClick={() => this.onClickOption(option)}
            >
              {option}
            </div>
          );
        })}
      </div>
    );
  }
}
