import _, { get, isNil, sortBy, Dictionary } from 'lodash';
import { SettingsByKey } from '../../../services/Settings';
import { Dimension } from '../../../pivot/Dimension';
import { Group } from '../../../pivot/Group';
import { HierarchyMember } from '../../../pivot/Member';
import { ServerScope, HierData } from '../../../state/scope/Scope.types';
import { ConfigGroup, ConfigItem } from './PivotConfigurator.types';
import {
  METRICS,
  SINGLE_VERSION,
  ROWS,
  COLUMNS,
  VARIANCE_VERSION,
  PERCENT_TO_TOTAL_VERSION,
  PERCENT_TO_GRAND_TOTAL_VERSION,
} from '../../../utils/Domain/Constants';
import { PERCENTAGE_VARTYPE, DELTA_VARTYPE } from 'src/services/Pivot.types';
import { PRODUCT } from 'src/utils/Domain/Constants';
import { AxiosResponse } from 'axios';
import { InlineTree, Tree } from 'src/utils/types';
import { z, ZodTypeAny } from 'zod';
import { RequiredDimension } from 'src/space';

export interface DimensionItem {
  dimension: string;
  items: string[];
  hierarchy?: string; // define an optional alternate hierarchy
}

// TODO: export static types from these and use them everywhere
const zDimensionItem = z.object({
  dimension: z.string(),
  items: z.array(z.string()),
});

export const zPivotOptions = z.object({
  [ROWS]: z.array(zDimensionItem),
  [COLUMNS]: z.array(zDimensionItem),
  name: z.string().optional(),
  groupBy: z
    .object({
      dimension: z.string(),
      item: z.string(),
    })
    .optional(),
  readOnly: z.boolean(),
});

export interface PivotOptions {
  name?: string;
  [ROWS]: DimensionItem[];
  [COLUMNS]: DimensionItem[];
  groupBy?: {
    dimension: string;
    item: string;
  };
}

export interface EffectiveGroups {
  available: ConfigGroup[];
  row: ConfigGroup[];
  column: ConfigGroup[];
}

export function getEffeciveGroups(superGroup: ConfigGroup[], pivotOptions: PivotOptions): EffectiveGroups {
  const rowDimensions = pivotOptions.rows.map((entry) => entry.dimension);
  const colDimensions = pivotOptions.columns.map((entry) => entry.dimension);

  const rowMemberInfo: ConfigGroup[] = rowDimensions.map((dim) => {
    return superGroup.find((sg) => sg.dimension === dim)!;
  });
  const colMemberInfo: ConfigGroup[] = colDimensions.map((dim) => {
    return superGroup.find((sg) => sg.dimension === dim)!;
  });
  const availableMemberInfo = superGroup.filter((sg) => {
    //everything else
    const isInRows = rowMemberInfo.findIndex((r) => r.dimension === sg.dimension) !== -1;
    const isInCols = colMemberInfo.findIndex((c) => c.dimension === sg.dimension) !== -1;

    return !isInRows && !isInCols;
  });

  // check and set disabled bool so configurator can match the state of the grid
  colMemberInfo.forEach((colMember) => {
    setConfigItemDisabled(colMember, pivotOptions);
    const sortByList = ['sortIndex', 'group'];
    if (colMember.id === METRICS) {
      sortByList.push('name');
    } else if (colMember.id === PRODUCT) {
      sortByList.push('id');
    }
    colMember.children = recursiveSort(colMember.children!, sortByList);
  });
  // don't forget to do the rows
  rowMemberInfo.forEach((rowMember) => {
    setConfigItemDisabled(rowMember, pivotOptions);
    const sortByList = ['sortIndex', 'group'];
    if (rowMember.id === METRICS) {
      sortByList.push('name');
    } else if (rowMember.id === PRODUCT) {
      sortByList.push('id');
    }
    rowMember.children = recursiveSort(rowMember.children!, sortByList);
  });

  return {
    available: availableMemberInfo,
    row: rowMemberInfo,
    column: colMemberInfo,
  };
}

function recursiveSort(items: ConfigItem[], sortList: string[]): ConfigItem[] {
  items.forEach((itm) => {
    if (itm.children && itm.children.length > 0) {
      itm.children = recursiveSort(itm.children, sortList);
    }
  });
  return sortBy(items, sortList);
}

export function transformPayload<A>(vObj: Tree<A>): InlineTree<A> {
  const memberInfo = vObj.v;
  const children = vObj.children;
  return {
    ...memberInfo,
    children: children.map(transformPayload),
  };
}

export function getAvailableListing(
  mainConfig: ServerScope,
  settings: SettingsByKey,
  altHier?: DimensionItem[]
): ConfigGroup[] {
  const memberTrees = mainConfig.memberTrees;
  const groups: ConfigGroup[] = [];

  for (const dimId of Object.keys(memberTrees)) {
    const dimensionId = dimId as RequiredDimension;
    const maybeAlternateHier = altHier?.find((d) => d.dimension === dimensionId);
    if (maybeAlternateHier && maybeAlternateHier.hierarchy) {
      const vObj = getAlternateHierarchy(memberTrees[dimensionId], maybeAlternateHier.hierarchy);
      groups.push({
        id: dimensionId,
        name: dimensionId,
        dimension: dimensionId,
        hierarchy: maybeAlternateHier.hierarchy,
        // @ts-ignore
        children: vObj.map(transformPayload),
      });
    } else if (memberTrees[dimensionId]) {
      const vObj = getPrimary(memberTrees[dimensionId]);
      groups.push({
        id: dimensionId,
        name: dimensionId,
        dimension: dimensionId,
        // @ts-ignore
        children: vObj.map(transformPayload),
      });
    }
  }

  const metrics = mainConfig.metrics || [];
  groups.push({
    id: 'metrics',
    name: 'metrics',
    dimension: 'metrics',
    children: metrics.map((metric) => {
      const fieldId = metric.id;

      return {
        name: _.defaultTo(metric.name, fieldId),
        id: fieldId,
        format: metric.formats,
        group: _.defaultTo(get(metric, 'group'), undefined) as string | undefined,
        alwaysHidden: metric.hidden,
        type: 'metric',
        children: [],
      };
    }),
  });

  // TODO move this to settings? Maybe another client would want a different label
  const varTypeToName = {
    [DELTA_VARTYPE]: 'Diff',
    [PERCENTAGE_VARTYPE]: '%',
  };
  const altSimpleVersionLookup = {
    [PERCENT_TO_TOTAL_VERSION]: ' % to Total',
    [PERCENT_TO_GRAND_TOTAL_VERSION]: ' % to Grand Total',
  };
  const revisions = mainConfig.revisions;
  groups.push({
    id: 'revisions',
    name: 'Versions',
    dimension: 'revisions',
    children: revisions
      .filter(
        (v) =>
          v.type === SINGLE_VERSION ||
          v.type === VARIANCE_VERSION ||
          v.type === PERCENT_TO_TOTAL_VERSION ||
          v.type === PERCENT_TO_GRAND_TOTAL_VERSION
      )
      .map((vers) => {
        let id = vers.version;
        const verLookup = settings[`revision.${id}.display`];
        let verName = verLookup ? verLookup.value : id.toLocaleUpperCase();

        if (vers.type === VARIANCE_VERSION) {
          // variance versions have a different shape
          const againstLookup = settings[`revision.${vers.against}.display`];
          const againstName = againstLookup ? againstLookup.value : vers.against.toLocaleUpperCase();
          return {
            name: `${verName} to ${againstName} ${varTypeToName[vers.varType]}`,
            id: `${vers.version}||${vers.against}||${vers.varType}`,
            varType: vers.varType,
            type: vers.type,
            alwaysHidden: vers.hidden,
            children: [],
          };
        }
        verName = vers.type === SINGLE_VERSION ? verName : `${verName}${altSimpleVersionLookup[vers.type]}`;
        id = vers.type === SINGLE_VERSION ? vers.version : `${vers.version}${vers.type}`;

        return {
          id,
          name: verName,
          children: [],
          type: vers.type,
          alwaysHidden: vers.hidden,
        };
      }),
  });
  return groups;
}

export function setConfigItemDisabled(configItem: ConfigItem, faveOpts: PivotOptions): void {
  const dimension = {
    id: configItem.id,
    name: configItem.id,
    description: configItem.id,
  };

  function checkChildren(cm: ConfigItem, dim: Dimension): boolean {
    let isDisabled = true;
    const dimColCfg = _.find(faveOpts.columns, (vp) => vp.dimension === dim.id);
    const dimRowCfg = _.find(faveOpts.rows, (vp) => vp.dimension === dim.id);

    const dimCfg = !isNil(dimColCfg) ? dimColCfg : dimRowCfg;

    if (isNil(dimCfg)) {
      return isDisabled;
    }

    const items = dimCfg.items;

    items.forEach((item, i) => {
      if (`level:${cm.level}` === item) {
        isDisabled = false;
        cm.disabled = isDisabled;
        cm.sortIndex = items.findIndex((itm) => itm === `level:${cm.level}`);
      } else if (`member:${cm.id}` === item) {
        isDisabled = false;
        cm.disabled = isDisabled;
        cm.sortIndex = items.findIndex((itm) => itm === `member:${cm.id}`);
      } else if (cm.id === item) {
        isDisabled = false;
        cm.disabled = isDisabled;
        cm.sortIndex = items.findIndex((itm) => itm === `${cm.id}`);
      } else {
        cm.disabled = isDisabled;
      }
    });
    if (cm.children) {
      cm.children.map((child) => checkChildren(child, dim));
    }

    return isDisabled;
  }

  configItem.disabled = checkChildren(configItem, dimension);
}

export function getGroupFromConfigItem(
  configItem: ConfigItem,
  faveOpts: PivotOptions,
  levelsMap: Dictionary<
    HierData<
      {
        id: string;
        name: string;
        description: string | undefined;
      }[]
    >
  >
): Group {
  const name: string = configItem.name || configItem.id;
  const dimension = {
    id: configItem.id,
    name,
    description: configItem.id,
    hierarchy: configItem.hierarchy,
  };
  const level = {
    name: `${name} Level`,
    description: configItem.id,
    ...levelsMap[configItem.level!],
    id: `${configItem.id}Level`,
    dimension,
  };

  const root: SortedHierarchyMember = {
    level,
    id: configItem.id,
    name: configItem.name || '',
    description: configItem.description || '',
    isHidden: true,
    areChildrenColumnsVisible: true,
  };

  interface SortedHierarchyMember extends HierarchyMember {
    sortValue?: number;
    alwaysHidden?: boolean;
    children?: SortedHierarchyMember[];
  }

  function getChildren(hm: SortedHierarchyMember, cm: ConfigItem, dim: Dimension): SortedHierarchyMember {
    const childLevel = {
      ...cm,
      name: `${cm.name || cm.id} Level`,
      description: cm.id,
      alwaysHidden: cm.alwaysHidden,
      ...levelsMap[cm.level!],
      id: `${cm.id}Level`,
      dimension: dim,
    };

    const newRoot: SortedHierarchyMember = {
      id: cm.id,
      name: cm.name || '',
      description: cm.name || '',
      alwaysHidden: cm.alwaysHidden,
      level: childLevel,
      parent: hm,
      areChildrenColumnsVisible: true,
    };
    if (!isNil(cm.format)) {
      newRoot.format = cm.format;
    }
    if (!isNil(cm.group)) {
      newRoot.group = cm.group;
    }
    if (!isNil(cm.varType)) {
      newRoot.varType = cm.varType;
    }
    if (!isNil(cm.type)) {
      newRoot.type = cm.type as any;
    }

    let isHidden = true;
    [ROWS, COLUMNS].forEach((rcType) => {
      const dimCfg = _.find(faveOpts[rcType], (vp) => vp.dimension === dim.id);
      if (!dimCfg || !dimCfg.items) {
        return;
      }
      const items: string[] = dimCfg.items;

      items.forEach((item, i) => {
        if (`level:${cm.level}` === item) {
          isHidden = false;
        }
        if (`member:${cm.id}` === item) {
          isHidden = false;
          newRoot.sortValue = i;
        }
        if (cm.id === item) {
          isHidden = false;
          newRoot.sortValue = i;
        }
      });
    });
    let newChildren: SortedHierarchyMember[] = [];
    if (cm.children) {
      newChildren = cm.children.map((child) => getChildren(newRoot, child, dim));
    }

    newChildren = _.sortBy(newChildren, (child) => child.sortValue);
    newRoot.isHidden = isHidden;
    newRoot.children = newChildren;
    return newRoot;
  }
  let rootChildren: SortedHierarchyMember[] = [];
  if (configItem.children) {
    rootChildren = configItem.children.map((child) => getChildren(root, child, dimension));
  }

  rootChildren = _.sortBy(rootChildren, (child) => child.sortValue);
  root.children = rootChildren;
  return new Group(root, true);
}

export function getUnFilteredGroupFromConfigItem(
  row: ConfigItem,
  levelsMap: Dictionary<
    HierData<
      {
        id: string;
        name: string;
        description: string | undefined;
      }[]
    >
  >
): Group {
  const name = row.name || row.id;
  const dimension = {
    id: row.id,
    name,
    description: row.id,
    hierarchy: row.hierarchy,
  };
  const level = {
    name: `${name} Level`,
    description: row.id,
    ...levelsMap[row.level!],
    id: `${row.id}Level`,
    dimension,
  };

  const root: HierarchyMember = {
    level,
    id: row.id,
    name: row.name || '',
    description: row.description || '',
    isHidden: true,
    areChildrenColumnsVisible: true,
  };

  function getChildren(hm: HierarchyMember, cm: ConfigItem, dim: Dimension): HierarchyMember {
    const childLevel = {
      name: `${cm.name || cm.id} Level`,
      description: cm.id,
      alwaysHidden: cm.alwaysHidden,
      ...levelsMap[cm.level!],
      id: `${cm.id}Level`,
      dimension: dim,
    };
    const newRoot: HierarchyMember = {
      id: cm.id,
      name: cm.name || '',
      description: cm.name || '',
      type: (cm.type as any) || undefined,
      level: childLevel,
      parent: hm,
      isHidden: !!cm.disabled,
      areChildrenColumnsVisible: true,
    };
    if (!isNil(cm.format)) {
      newRoot.format = cm.format;
    }
    if (!isNil(cm.varType)) {
      newRoot.varType = cm.varType;
    }
    if (!isNil(cm.type)) {
      newRoot.type = cm.type as any;
    }
    if (cm.children) {
      newRoot.children = cm.children.map((child) => getChildren(newRoot, child, dim));
    }

    return newRoot;
  }

  root.children = row.children!.map((child) => getChildren(root, child, dimension));
  return new Group(root, true);
}

export function makeErrorLog(message: string) {
  return (e: any) => {
    // eslint-disable-next-line no-console
    console.error(message, ': ', e);
    return Promise.reject(e);
  };
}

export function getPrimaryData<T>(hiers: readonly HierData<T>[]): HierData<T> {
  const primary = hiers.find((p) => p.primary);
  if (!primary) {
    // Denver said it was Ok
    throw new Error('broken data has no primary');
  }
  return primary;
}

export function getPrimary<T>(hiers: HierData<T>[]): T {
  return getPrimaryData(hiers).data;
}

export function getPrimaryId<T>(hiers: readonly HierData<T>[]): string {
  return getPrimaryData(hiers).id;
}

export function getAlternateHierarchyData<T>(hiers: readonly HierData<T>[], hierId: string): HierData<T> {
  const altHier = hiers.find((p) => p.id === hierId);
  if (!altHier) {
    // Denver said it was Ok
    throw new Error('Requested alt hier could not be found');
  }
  return altHier;
}

export function getAlternateHierarchy<T>(hiers: HierData<T>[], hierId: string): T {
  return getAlternateHierarchyData<T>(hiers, hierId).data;
}
