import BoundSectionZipper from 'src/pages/NavigationShell/NavigationZipper/BoundSectionZipper';
import { BoundView } from 'src/services/configuration/codecs/bindings.types';

export default class BoundViewZipper {
  readonly type: 'view' = 'view';
  private parent: Readonly<BoundSectionZipper>;
  private view: Readonly<BoundView>;

  constructor(parent: Readonly<BoundSectionZipper>, view: Readonly<BoundView>) {
    this.parent = parent;
    this.view = view;
  }

  public getParent(): Readonly<BoundSectionZipper> {
    return this.parent;
  }

  public getView(): Readonly<BoundView> {
    return this.view;
  }
}
