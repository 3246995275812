import { AxiosInstance } from 'axios';
import { ViewConfig } from 'src/state/ViewConfig/ViewConfig.types';
import { zReportConfig, ReportConfig } from './Reports.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { makeErrorLog } from 'src/components/Mfp/PivotConfigurator/utils';
import { zSmartPlanPlayload } from 'src/state/scope/codecs/SmartPlan/SmartPlanSection';

export const CONFIG_BASE = `${API_BASE_URL}/silo/mfp/config`;

export class ConfigService {
  protected client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public getViewConfig(): Promise<ViewConfig> {
    return this.client.get<ViewConfig>(`${CONFIG_BASE}/view-config`).then((response) => response.data);
  }

  public getReportConfig() {
    return this.client
      .get<ReportConfig>(`${CONFIG_BASE}/reports`)
      .then((response) => zReportConfig.parse(response.data));
  }

  public getSmartPlanWizardConfig() {
    return (
      this.client
        .get<unknown>(`${CONFIG_BASE}/smartplanwizard`)
        .then((resp) => zSmartPlanPlayload.parse(resp.data))
        // TODO this may or may not be wrapped in .data
        .then((r) => r)
        .catch(makeErrorLog('failed to get smartplan config'))
    );
  }
}

export default ConfigService;
