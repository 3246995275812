import React, { Component } from 'react';
import { BindAll } from 'lodash-decorators';
import {
  SplitViewState,
  SplitViewProps,
  SplitViewValueProps,
  SplitViewDispatchProps,
  SplitViewGridType,
} from './split-view.types';
import { connect } from 'react-redux';
import { AppState } from 'src/store';
import { mapStateToProps, mapDispatchToProps } from './split-view.container';
import AgPivot, { AgPivot as IAgPivot } from 'src/components/Mfp/Pivot/AgPivot';
import PivotConfigurator from 'src/components/Mfp/PivotConfigurator/PivotConfigurator';
import {
  getAvailableListing,
  DimensionItem,
  getUnFilteredGroupFromConfigItem,
  getGroupFromConfigItem,
} from 'src/components/Mfp/PivotConfigurator/utils';
import { isScopeNotReady } from 'src/state/scope/Scope.types';
import _ from 'lodash';
import PivotConfig from 'src/pivot/PivotConfig';
import { ConfigGroup } from 'src/components/Mfp/PivotConfigurator/PivotConfigurator.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { PivotCell } from 'src/pivot/PivotCell';
import { getCellStateFromCells } from 'src/pivot/PivotCells.utils';
import { VisualizeContextMenu } from 'src/components/VisualizeContextMenu/VisualizeContextMenu';
import { ReportStatus } from 'src/services/Reports.client';
import { CONTEXT_PENDING, CONTEXT_READY } from 'src/state/workingSets/workingSets.types';
import { toast } from 'react-toastify';
import DraggableContainer from 'src/components/DraggableContainer/DraggableContainer';
import MinimizableItem from 'src/components/DraggableContainer/MinimizableItem';
import GridErrorBoundary from 'src/components/ErrorBoundary/GridErrorBoundary';
import { Segment, Icon, Button } from 'semantic-ui-react';
import TitledModal from 'src/components/Modal/TitledModal';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';
import { generateSplitViewState, getGridTypeProp } from './split-view.utils';
import './_visualize-summary-pivot.scss';
import MfpSubheader from 'src/components/Mfp/MfpSubheader/MfpSubheader';
import ServiceContainer from 'src/ServiceContainer';

@BindAll()
export class SplitView extends Component<SplitViewProps, SplitViewState> {
  topPivot: IAgPivot | undefined;
  bottomPivot: IAgPivot | undefined;
  configurator: PivotConfigurator | undefined;

  constructor(props: SplitViewProps) {
    super(props);

    const initialGridState = {
      allAvailableListing: [],
      availableGroup: [],
      rowGroup: [],
      colGroup: [],
      contextItems: [],
    };

    const initialState = {
      isSaving: false,
      isConfigModalOpen: false,
      configuringGrid: null,
      topGrid: { ...initialGridState, viewParams: props.viewParams!.topGrid },
      bottomGrid: { ...initialGridState, viewParams: props.viewParams!.bottomGrid },
      configuringGridType: null,
    };

    this.state = SplitView.getDerivedStateFromProps(props, initialState);
  }

  componentDidUpdate(prevProps: SplitViewProps) {
    const { topGrid: prevTopGridProps, bottomGrid: prevBottomGridProps } = prevProps;
    const { topGrid: topGridProps, bottomGrid: bottomGridProps } = this.props;

    if (prevTopGridProps.gridAsyncState !== topGridProps.gridAsyncState) {
      this.updateContextMenus();
    } else if (prevBottomGridProps.gridAsyncState !== bottomGridProps.gridAsyncState) {
      this.updateContextMenus();
    }

    if (this.state.topGrid.xlsDownloadStatus) {
      try {
        const authToken = ServiceContainer.lastBearerToken();
        this.props.reports.forEach((rep) => {
          if (rep.id === this.state.topGrid.xlsDownloadStatus?.id && rep.status === CONTEXT_READY) {
            const nextState = {
              ...this.state,
              topGrid: {
                ...this.state.topGrid,
                xlsDownloadStatus: undefined,
              },
            };
            this.setState(
              nextState,
              () => (window.location.href = `${API_BASE_URL}/drop/${rep.id}?token=${authToken}`)
            );
          }
        });
      } catch (e) {
        toast.error('An error has occued downloading your report');
        ServiceContainer.loggingService.error('An error has occued downloading your report');
      }
    } else if (this.state.bottomGrid.xlsDownloadStatus) {
      try {
        const authToken = ServiceContainer.lastBearerToken();
        this.props.reports.forEach((rep) => {
          if (rep.id === this.state.bottomGrid.xlsDownloadStatus?.id && rep.status === CONTEXT_READY) {
            const nextState = {
              ...this.state,
              bottomGrid: {
                ...this.state.bottomGrid,
                xlsDownloadStatus: undefined,
              },
            };
            this.setState(
              nextState,
              () => (window.location.href = `${API_BASE_URL}/drop/${rep.id}?token=${authToken}`)
            );
          }
        });
      } catch (e) {
        toast.error('An error has occued downloading your report');
        ServiceContainer.loggingService.error('An error has occued downloading your report');
      }
    }
  }

  static getDerivedStateFromProps(props: SplitViewProps, state: SplitViewState) {
    const newState = { ...state };
    const mainConfig = props.mainConfig;
    const settings = props.settings;

    if (!mainConfig || !settings || isScopeNotReady(mainConfig)) {
      return newState;
    }

    const maybeAlternateDimsTop = [
      props.viewParams!.topGrid.rows.find((rd) => rd.hierarchy),
      props.viewParams!.topGrid.columns.find((cd) => cd.hierarchy),
    ].filter((dd) => !!dd);
    const maybeAlternateDimsBottom = [
      props.viewParams!.bottomGrid.rows.find((rd) => rd.hierarchy),
      props.viewParams!.bottomGrid.columns.find((cd) => cd.hierarchy),
    ].filter((dd) => !!dd);

    newState.topGrid.allAvailableListing = getAvailableListing(
      mainConfig,
      settings,
      maybeAlternateDimsTop as DimensionItem[]
    );
    newState.bottomGrid.allAvailableListing = getAvailableListing(
      mainConfig,
      settings,
      maybeAlternateDimsBottom as DimensionItem[]
    );

    return generateSplitViewState(newState, state, props.viewParams!);
  }

  onLayoutManagerOpen(gridType: SplitViewGridType) {
    this.setState({
      isConfigModalOpen: true,
      configuringGridType: gridType,
    });
  }

  onLayoutManagerClose() {
    this.setState({
      isConfigModalOpen: false,
      configuringGridType: null,
    });
  }

  onLayoutManagerSubmit(gridType: SplitViewGridType | null) {
    const mainConfig = this.props.mainConfig;
    if (!mainConfig || !this.configurator || isScopeNotReady(mainConfig)) {
      return;
    }

    const gridProp = getGridTypeProp(gridType!);
    const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
    const availalbe = this.configurator.state.groups[0].children;
    const rows = this.configurator.state.groups[1].children;
    const columns = this.configurator.state.groups[2].children;
    const rGroups = [];
    const cGroups = [];

    for (const row of rows!) {
      rGroups.push(getUnFilteredGroupFromConfigItem(row, levelsMap));
    }
    for (const col of columns!) {
      cGroups.push(getUnFilteredGroupFromConfigItem(col, levelsMap));
    }

    const pivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: rGroups,
      columns: cGroups,
    });

    this.setState(
      {
        ...this.state,
        [gridProp]: {
          ...this.state[gridProp],
          rowGroup: rows,
          colGroup: columns,
          pivotConfig,
          availableGroup: availalbe as ConfigGroup[],
        },
        isConfigModalOpen: false,
      },
      () => {
        this.updateContextMenus();
      }
    );
  }

  public toggleLock() {
    const { isLockToggled, onUnlockAllClick, scopeId } = this.props;

    if (isLockToggled && isLockToggled && scopeId) {
      if (onUnlockAllClick) {
        onUnlockAllClick(this, ServiceContainer.axios, scopeId);
      }
    }

    this.topPivot?.setFocusedCell();
    this.bottomPivot?.setFocusedCell();
  }

  onClickDownload(gridType: SplitViewGridType) {
    const gridProp = getGridTypeProp(gridType);
    const { scopeId } = this.props;
    const pivotName = this.props[gridProp].pivotName;
    const tokenValue = ServiceContainer.lastBearerToken();
    const requestGenerateReportUrl = `${API_BASE_URL}/context/${scopeId}/pivot/${pivotName}/export?token=${tokenValue}`;

    ServiceContainer.axios
      .get<ReportStatus>(requestGenerateReportUrl)
      .then((rep) => {
        const nextState = {
          ...this.state,
          [gridProp]: {
            ...this.state[gridProp],
            xlsDownloadStatus: {
              id: rep.data.id,
              status: CONTEXT_PENDING,
            },
          },
        };
        this.setState(nextState);
      })
      .catch(() => {
        toast.error('An error has occued downloading your report', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  }

  onClickUndo(gridType: SplitViewGridType) {
    const { scopeId, onClickUndo } = this.props;
    if (scopeId) {
      onClickUndo(scopeId).then(() => {
        const activePivot = gridType === SplitViewGridType.top ? this.topPivot : this.bottomPivot;
        activePivot?.setFocusedCell();
      });
    }
  }

  onClickRedo(gridType: SplitViewGridType) {
    const { scopeId, onClickRedo } = this.props;
    if (onClickRedo && scopeId) {
      onClickRedo(scopeId).then(() => {
        const activePivot = gridType === SplitViewGridType.top ? this.topPivot : this.bottomPivot;
        activePivot?.setFocusedCell();
      });
    }
  }

  freezeCells = (gridType: SplitViewGridType) => {
    const pivot = gridType === SplitViewGridType.top ? this.topPivot : this.bottomPivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }

    cells = pivot.getSelectedCells();

    pivot.state.manager
      .lockCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenus);
        });
      });
  };

  lockCells = (gridType: SplitViewGridType) => {
    const pivot = gridType === SplitViewGridType.top ? this.topPivot : this.bottomPivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }
    cells = pivot.getSelectedCells();

    pivot.state.manager
      .constrainCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenus);
        });
      });
  };

  relaxCells = (gridType: SplitViewGridType) => {
    const pivot = gridType === SplitViewGridType.top ? this.topPivot : this.bottomPivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }
    cells = pivot.getSelectedCells();

    pivot.state.manager
      .relaxCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenus);
        });
      });
  };

  onPivotChange() {
    this.updateContextMenus();
  }

  renderContextMenu(pivot: IAgPivot | undefined, gridType: SplitViewGridType) {
    let cells: PivotCell[] = [];
    const gridProp = getGridTypeProp(gridType);

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const { topGrid, bottomGrid } = this.props;
    const gridAsyncState = gridType === SplitViewGridType.top ? topGrid.gridAsyncState : bottomGrid.gridAsyncState;
    const gridAvailableGroup =
      gridType === SplitViewGridType.top ? this.state.topGrid.availableGroup : this.state.bottomGrid.availableGroup;
    const cellState = getCellStateFromCells(cells);
    const onUndo = this.onClickUndo.bind(null, gridType);
    const onRedo = this.onClickRedo.bind(null, gridType);
    const onOpen = this.onLayoutManagerOpen.bind(null, gridType);
    const onDownload = this.onClickDownload.bind(null, gridType);
    const onFreeze = this.freezeCells.bind(null, gridType);
    const onLock = this.lockCells.bind(null, gridType);
    const onRelax = this.relaxCells.bind(null, gridType);
    const contextItems = (
      <VisualizeContextMenu
        hasEditableRevision={this.props.hasEditableRevision}
        cellState={cellState}
        favoritesLoading={this.state[gridProp].xlsDownloadStatus?.status === CONTEXT_PENDING}
        gridAsyncState={gridAsyncState}
        availableGroup={gridAvailableGroup}
        handleClickUndo={onUndo}
        handleClickRedo={onRedo}
        handleClickLayoutManagerOpen={onOpen}
        handleClickDownload={onDownload}
        freezeCells={onFreeze}
        lockCells={onLock}
        relaxCells={onRelax}
      />
    );

    return contextItems;
  }

  updateContextMenus() {
    const topPivot = this.topPivot;
    const bottomPivot = this.bottomPivot;

    const topContextItems = this.renderContextMenu(topPivot, SplitViewGridType.top);
    const bottomContextItems = this.renderContextMenu(bottomPivot, SplitViewGridType.bottom);
    const nextGridState = {
      ...this.state,
      topGrid: {
        ...this.state.topGrid,
        contextItems: topContextItems,
      },
      bottomGrid: {
        ...this.state.bottomGrid,
        contextItems: bottomContextItems,
      },
    };

    this.setState({ ...nextGridState });
  }

  render() {
    const mainConfig = this.props.mainConfig;
    if (isScopeNotReady(mainConfig) || !this.props.scopeReady) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          Scope not ready. Please wait while your scope is prepared
        </Segment>
      );
    }
    if (!mainConfig) {
      return <React.Fragment />;
    }
    if (!this.props.initialized && !this.props.isFetchingScope && this.props.scopeReady) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          Plan not initialized. Please Initialize the Plan
        </Segment>
      );
    }

    const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
    const topViewParams = this.state.topGrid.viewParams;
    const bottomViewParams = this.state.bottomGrid.viewParams;
    if (!topViewParams) {
      return <div>No Top Grid view params...</div>;
    }
    if (!bottomViewParams) {
      return <div>No Bottom Grid view params...</div>;
    }

    const topRowDimensions = topViewParams.rows.map((entry) => entry.dimension);
    const topColDimensions = topViewParams.columns.map((entry) => entry.dimension);
    const bottomRowDimensions = bottomViewParams.rows.map((entry) => entry.dimension);
    const bottomColDimensions = bottomViewParams.columns.map((entry) => entry.dimension);

    const topDimRowKeyToIndex = _.mapValues(
      _.keyBy(
        _.map(topRowDimensions, (d, i) => ({ d, i })),
        'd'
      ),
      'i'
    );
    const topDimColKeyToIndex = _.mapValues(
      _.keyBy(
        _.map(topColDimensions, (d, i) => ({ d, i })),
        'd'
      ),
      'i'
    );
    const bottomDimRowKeyToIndex = _.mapValues(
      _.keyBy(
        _.map(bottomRowDimensions, (d, i) => ({ d, i })),
        'd'
      ),
      'i'
    );
    const bottomDimColKeyToIndex = _.mapValues(
      _.keyBy(
        _.map(bottomColDimensions, (d, i) => ({ d, i })),
        'd'
      ),
      'i'
    );

    let topRowMemberInfo: ConfigGroup[] = this.state.topGrid.rowGroup;
    let topColMemberInfo: ConfigGroup[] = this.state.topGrid.colGroup;
    let bottomRowMemberInfo: ConfigGroup[] = this.state.bottomGrid.rowGroup;
    let bottomColMemberInfo: ConfigGroup[] = this.state.bottomGrid.colGroup;

    topRowMemberInfo = _.sortBy(topRowMemberInfo, (g) => topDimRowKeyToIndex[g.id]);
    topColMemberInfo = _.sortBy(topColMemberInfo, (g) => topDimColKeyToIndex[g.id]);
    bottomRowMemberInfo = _.sortBy(bottomRowMemberInfo, (g) => bottomDimRowKeyToIndex[g.id]);
    bottomColMemberInfo = _.sortBy(bottomColMemberInfo, (g) => bottomDimColKeyToIndex[g.id]);

    // @ts-ignore
    const topPivotRows = topRowMemberInfo.map((mi) => getGroupFromConfigItem(mi, topViewParams, levelsMap));
    // @ts-ignore
    const topPivotCols = topColMemberInfo.map((mi) => getGroupFromConfigItem(mi, topViewParams, levelsMap));
    // @ts-ignore
    const bottomPivotRows = bottomRowMemberInfo.map((mi) => getGroupFromConfigItem(mi, bottomViewParams, levelsMap));
    // @ts-ignore
    const bottomPivotCols = bottomColMemberInfo.map((mi) => getGroupFromConfigItem(mi, bottomViewParams, levelsMap));

    const topPivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: topPivotRows,
      columns: topPivotCols,
    });
    const bottomPivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: bottomPivotRows,
      columns: bottomPivotCols,
    });

    return (
      <div className="visualize-summary-pivot">
        <MfpSubheader
          title={this.props.viewParams?.title || ''}
          lockToggleParams={{
            active: this.props.isLockToggled,
            onClick: this.toggleLock,
            hidden: !this.props.hasEditableRevision,
          }}
        />

        <DraggableContainer>
          <MinimizableItem title="" optionChildren={this.state.topGrid.contextItems} disableMinimize={true}>
            <GridErrorBoundary errorTitle={'Something has gone wrong with the grid'}>
              <AgPivot
                config={this.state.topGrid.pivotConfig || topPivotConfig}
                onDataChange={this.onPivotChange}
                onSelectionChange={this.onPivotChange}
                onMount={this.onPivotChange}
                pivotName={this.props.topGrid.pivotName}
                handleDownloadXLS={this.onClickDownload.bind(null, SplitViewGridType.top)}
                // @ts-ignore
                ref={(el) => {
                  if (el) {
                    // @ts-ignore
                    const wrappedPivot = el.getWrappedInstance();
                    if (this.topPivot !== wrappedPivot) {
                      this.topPivot = wrappedPivot;
                      this.updateContextMenus();
                    }
                  }
                }}
              />
            </GridErrorBoundary>
          </MinimizableItem>
          <MinimizableItem title="" optionChildren={this.state.bottomGrid.contextItems} disableMinimize={true}>
            <GridErrorBoundary errorTitle={'Something has gone wrong with the grid'}>
              <AgPivot
                config={this.state.bottomGrid.pivotConfig || bottomPivotConfig}
                onDataChange={this.onPivotChange}
                onSelectionChange={this.onPivotChange}
                onMount={this.onPivotChange}
                pivotName={this.props.bottomGrid.pivotName}
                handleDownloadXLS={this.onClickDownload.bind(null, SplitViewGridType.bottom)}
                // @ts-ignore
                ref={(el) => {
                  if (el) {
                    // @ts-ignore
                    const wrappedPivot = el.getWrappedInstance();
                    if (this.bottomPivot !== wrappedPivot) {
                      this.bottomPivot = wrappedPivot;
                      this.updateContextMenus();
                    }
                  }
                }}
              />
            </GridErrorBoundary>
          </MinimizableItem>
        </DraggableContainer>
        <TitledModal title="Layout Manager" show={this.state.isConfigModalOpen}>
          <div className={'layout-modal-body'}>
            <PivotConfigurator
              // @ts-ignore
              ref={(el) => (this.configurator = el)}
              available={
                this.state.configuringGridType === SplitViewGridType.top
                  ? this.state.topGrid.availableGroup
                  : this.state.bottomGrid.availableGroup
              }
              rows={
                this.state.configuringGridType === SplitViewGridType.top
                  ? this.state.topGrid.rowGroup
                  : this.state.bottomGrid.rowGroup
              }
              columns={
                this.state.configuringGridType === SplitViewGridType.top
                  ? this.state.topGrid.colGroup
                  : this.state.bottomGrid.colGroup
              }
              settings={this.props.settings}
            />
          </div>
          <div className={'layout-footer'}>
            <Button content={'CANCEL'} onClick={this.onLayoutManagerClose} />
            <Button
              content={'SUBMIT'}
              className="layout-submit-modal-button"
              onClick={this.onLayoutManagerSubmit.bind(null, this.state.configuringGridType)}
            />
          </div>
        </TitledModal>
        {this.state.isSaving && <LoadingMask />}
      </div>
    );
  }
}

export default connect<SplitViewValueProps, SplitViewDispatchProps, null, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(SplitView);
