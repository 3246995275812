import { connect } from 'react-redux';
import { GeoTrends } from './GeoTrends';
import selectAndProjectState, { FunctionProps } from './GeoTrends.selectors';
import container from 'src/ServiceContainer';

import {
  cleanUp,
  receiveTenantConfig,
  receiveError,
  receiveGeoData,
  requestGeoData,
  geotrendsRequestChartData,
  geotrendsReceiveChartData,
  TenantResponse,
  selectPoint,
  resetPoint,
  selectGeoLevel,
} from './GeoTrends.slice';

import serviceContainer from 'src/ServiceContainer';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { TOP_DOWN } from 'src/utils/Domain/Constants';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { getTopMembers } from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.container';
import { AppState, AppThunkDispatch } from 'src/store';
import { AnyAction as BaseAction } from 'redux';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import {
  GeoTrendsMapDefn,
  zGeoTrendsChartsDefn,
  zGeoTrendsMapDefn,
  GeoTrendsChartsDefn,
} from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { zGeoTrendsComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { ListDataApi } from 'src/services/configuration/codecs/confdefnView';

export interface GeoTrendsOwnProps extends z.infer<typeof zGeoTrendsComponentProps> {}

export function asyncGetMapData(dataApi: ListDataApi, extraAggBy?: string) {
  const service = serviceContainer.pivotService;

  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    const state = getState();
    const { selectedGeoLevel } = state.pages.hindsighting.geoTrends;
    const { flowStatus = [] } = state.subheader;

    if (selectedGeoLevel) {
      dispatch(requestGeoData());
      return service
        .listData(dataApi.defnId, dataApi.params.appName, {
          aggBy: selectedGeoLevel.groupingKey + (extraAggBy ? ',' + extraAggBy : ''),
          flowStatus: flowStatus.join(','),
          topMembers: getTopMembers(getState().scope.scope),
        })
        .then((resp) => {
          const props = { data: resp, selectedGeoLevel: selectedGeoLevel };
          dispatch(receiveGeoData(props));
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.data,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.geoTrends,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    }
    return Promise.resolve();
  };
}

function asyncGetChartData(dataApi: ListDataApi) {
  const service = serviceContainer.pivotService;

  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<void> | void => {
    const { selectedItem } = getState().pages.hindsighting.geoTrends;

    if (selectedItem) {
      const itemId = selectedItem.mId;
      dispatch(geotrendsRequestChartData());
      service
        .listData(dataApi.defnId, dataApi.params.appName, {
          aggBy: dataApi.params.aggBy ? dataApi.params.aggBy : '',
          topMembers: itemId,
        })
        .then((resp) => {
          dispatch(geotrendsReceiveChartData(resp.tree));
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.data,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.geoTrends,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: dataApi.defnId,
            })
          )
        );
    }
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: GeoTrendsOwnProps): FunctionProps {
  const client = container.tenantConfigClient;

  return {
    onShowView() {
      const config: TenantResponse = {
        mapConfig: undefined,
        chartConfig: undefined,
        showLookBackPeriod: ownProps.showLookBackPeriod,
        showFlowStatus: ownProps.showFlowStatus,
      };

      client
        .getTenantViewDefns({
          defnIds: [ownProps.defns.view[0], ownProps.defns.view[1]],
          appName: TOP_DOWN,
          validationSchemas: [zGeoTrendsMapDefn, zGeoTrendsChartsDefn],
        })
        .then((resp) => {
          config.mapConfig = (resp[0] as unknown) as GeoTrendsMapDefn;
          config.chartConfig = (resp[1] as unknown) as GeoTrendsChartsDefn;
          dispatch(receiveTenantConfig(config));
          return resp[0];
        })
        .then((mapConfig) => {
          const defaultSelection = mapConfig.default;
          const defaultItem = mapConfig.view.find((item) => item.dataIndex === defaultSelection);
          if (defaultItem) {
            dispatch(selectGeoLevel(defaultItem));
          }
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.geoTrends,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    onUpdate() {
    },
    // @ts-ignore
    onUpdateGeoLevel(selectedGeo: TenantConfigViewItem) {
      dispatch(selectGeoLevel(selectedGeo));
      dispatch(resetPoint());
    },
    onSelectPoint(selectedItem: ExtendedPointObject) {
      dispatch(selectPoint(selectedItem));
      dispatch(asyncGetChartData(ownProps.graphDataApi));
    },
    onDestroy() {
      dispatch(cleanUp());
    },
  };
}

const scopeSensitiveComponent = makeScopeSensitive(GeoTrends);

export default connect(selectAndProjectState, dispatchToProps)(scopeSensitiveComponent);
