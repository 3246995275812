import { SCOPETYPE_ACTUALS, SCOPETYPE_PLAN } from 'src/utils/Domain/Constants';
import { createSlice } from '@reduxjs/toolkit';
import { ScopeMemberInfo } from 'src/services/Scope.client';
import { receivedCreateScope } from './Scope.slice';
import { PlanId } from './codecs/PlanMetadata';
import { getPublishVersions } from './ScopeManagement.actions';

export interface SeedPlan {
  applyTo: PlanId;
  seedType: typeof SCOPETYPE_PLAN;
  seedTime: string;
  name: string;
  planId: PlanId;
}
export interface SeedActuals {
  applyTo: PlanId;
  seedType: typeof SCOPETYPE_ACTUALS;
  seedTime: string;
  name: typeof SCOPETYPE_ACTUALS;
  planId: null;
}
// init state
export interface SeedNull {
  applyTo: null;
  seedType: null;
  seedTime: null;
  planId: null;
}

interface Publishing {
  publishVersions: string[] | null;
}

export interface ScopeManagement extends Publishing {}

const initScopeManagementState: ScopeManagement = {
  publishVersions: null,
};

const scopeManagementSliceReducer = createSlice({
  name: 'scopeManagement',
  initialState: initScopeManagementState as ScopeManagement, // this needs to be asserted
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublishVersions.pending, (state, _action) => {
      return state; // TODO: loading flag
    });
    builder.addCase(getPublishVersions.fulfilled, (state, action) => {
      state.publishVersions = action.payload && Array.isArray(action.payload) ? action.payload : state.publishVersions;
    });
    builder.addCase(getPublishVersions.rejected, (state) => {
      return state;
    });
    builder.addCase(receivedCreateScope, (state) => {
      // reset everything on new scope
      state = initScopeManagementState;
    });
    return builder;
  },
});

// this takes an array of time members and a time member id, and finds the immediately preceding time member
export const getLastTimeMember = (timePeriods: readonly ScopeMemberInfo[], currentTimeId: string): ScopeMemberInfo => {
  const currentTimeMember = timePeriods.findIndex((year) => year.id === currentTimeId);
  // go back one year
  // this relies on timemembers being in order
  // SPIKE: what order???
  const lastTimePeriod = timePeriods[Math.max(currentTimeMember - 1, 0)];
  return lastTimePeriod;
};

export default scopeManagementSliceReducer.reducer;
