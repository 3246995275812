import { _, Component, ICellEditorComp, ICellEditorParams, KeyCode } from '@ag-grid-community/core';
import { noop, toNumber } from 'lodash';
import { validCharactersRegex } from 'src/services/configuration/MathJS.service';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const KEY_DELETE = 'Delete';

// navigations keys
const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';

KeyCode;

/**
 * useFormatter: used when the cell value needs formatting prior to editing,
 * such as when you don't want the cellFormatter
 *               to run before the value is passed to the <Input>
 * isPercent:
 */
export interface ITextCellEditorParams extends ICellEditorParams {
  useFormatter: boolean;
  percentChecker: (params: ITextCellEditorParams) => boolean | boolean;
}

export class OptionalFormatterTextCellEditor extends Component implements ICellEditorComp {
  constructor() {
    super(OptionalFormatterTextCellEditor.TEMPLATE);
    this.eInput = this.getGui().querySelector('input') as HTMLInputElement;
  }
  destroy(): void {
    noop();
  }

  // eslint-disable-next-line max-len
  private static TEMPLATE = '<div class="ag-input-text-wrapper"><input class="ag-cell-edit-input" type="text"/></div>';

  private highlightAllOnFocus = false;
  private focusAfterAttached = true;
  private params!: ITextCellEditorParams;
  private eInput: HTMLInputElement;
  private isPercent = false;

  public init(params: ITextCellEditorParams): void {
    this.params = params;
    this.isPercent = params.percentChecker(params);
    this.addGuiEventListener('keydown', this.onKeyDown.bind(this));

    const eInput = this.eInput;
    let startValue: string;

    // cellStartedEdit is only false if we are doing fullRow editing
    if (params.cellStartedEdit) {
      this.focusAfterAttached = true;

      const keyPressBackspaceOrDelete = params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE;

      if (keyPressBackspaceOrDelete) {
        startValue = '';
      } else {
        startValue = this.getStartValue(params);
        if (!toNumber(params.eventKey)) {
          this.highlightAllOnFocus = true;
        }
      }
    } else {
      this.focusAfterAttached = false;
      startValue = this.getStartValue(params);
    }

    if (_.exists(startValue)) {
      eInput.value = startValue;
    }

    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((eventType) => {
      this.eInput.addEventListener(eventType, (_event) => {
        // the great input filter
        const cleanedValue = eInput.value.replace(validCharactersRegex, '');
        if (cleanedValue !== eInput.value) {
          eInput.value = cleanedValue;
        }
      });
    });
  }

  public onKeyDown(event: any) {
    const key = event.key;
    if (
      key === KeyCode.LEFT ||
      key === KeyCode.UP ||
      key === KeyCode.RIGHT ||
      key === KeyCode.DOWN ||
      key === KeyCode.PAGE_UP ||
      key === KeyCode.PAGE_UP ||
      key === KeyCode.PAGE_HOME ||
      key === KeyCode.PAGE_END ||
      (event.shiftKey && key === KeyCode.ENTER)
    ) {
      event.stopPropagation();
    }
  }

  public afterGuiAttached(): void {
    if (!this.focusAfterAttached) {
      return;
    }

    if (this.highlightAllOnFocus) {
      this.eInput.select();
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = this.eInput.value ? this.eInput.value.length : 0;
      if (length > 0) {
        this.eInput.setSelectionRange(length, length);
        this.eInput.focus();
      }
    }
  }

  // gets called when tabbing trough cells and in full row edit mode
  public focusIn(): void {
    const eInput = this.eInput;
    eInput.focus();
    eInput.select();
  }

  public getValue(): any {
    const eInput = this.eInput;
    const stringValue = eInput.value;
    if (!Number.isFinite(toNumber(stringValue))) {
      // TODO add better number safety
      return stringValue;
    }
    const adjustedValue = !this.isPercent ? stringValue : (toNumber(stringValue) / 100).toString();
    return adjustedValue;
  }

  private getStartValue(params: ITextCellEditorParams) {
    const oldValue = params.value;
    const newValue = toNumber(params.eventKey);
    const adjustedValue = !this.isPercent ? newValue || oldValue : (toNumber(newValue || oldValue) * 100).toString();

    return params.useFormatter ? params.formatValue(adjustedValue) : adjustedValue;
  }
}
