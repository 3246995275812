import * as React from 'react';
import { ICellEditorParams } from '@ag-grid-community/core';
import { isNil, isString, round } from 'lodash';

import { style, classes } from 'typestyle';
import coalesce from 'src/utils/Functions/Coalesce';
import { evaluate } from 'mathjs';

export interface InputParams {
  min?: number;
  max?: number;
  nullable?: boolean;
  valueTests?: string[];
  percent?: boolean;
}

export interface IntegerEditorProps extends ICellEditorParams {
  passedInt: number;
  regularPosition?: boolean;
  inputParams?: InputParams;
}

interface IntegerEditorState {
  int: number | string | null;
}

export const isValueValid = (val: number | null = null, tests?: string[]): boolean => {
  const value = !Number.isNaN(Number(val)) ? Number(val) : val;
  const testsPassed = () => {
    if (tests && tests.length > 0) {
      if (val === null) {
        return tests.indexOf('NOT_NULLABLE') === -1;
      }

      const testMap: boolean[] = tests.filter((t) => t !== 'NOT_NULLABLE').map((test) => evaluate(test, { value }));
      if (testMap.every((test) => test === true)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };
  const didTestsPass = testsPassed();

  return didTestsPass;
};

function formatInput(value: string): string | number {
  // don't strip values if potentially starting to type a decimal (i.e. '1.', '1.0' is not stripped)
  const decimalInProgress = value.search(/\d+.\d*$/) >= 0;

  if (decimalInProgress) {
    return value;
  }

  while (!Number.isFinite(parseFloat(value)) && value.length > 0) {
    value = value.slice(0, -1);
  }

  // will return 'NaN' for empty string or a non-decimal numeric value
  return parseFloat(value);
}

const inputStyle = style({
  width: '100%',
  height: '100%',
});

export const arrowlessNumberInputStyle = style({
  '-moz-appearance': 'textfield', // hide number spinners in firefox
  $nest: {
    '&::-webkit-outer-spin-button': {
      display: 'none',
    },
    '&::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
});

export default class IntegerEditor extends React.Component<IntegerEditorProps, IntegerEditorState> {
  constructor(props: IntegerEditorProps) {
    super(props);

    const nullable = props.inputParams ? props.inputParams.nullable : undefined;
    let processedInt = coalesce(props.passedInt, props.value, null)
    const isValid = this.isNumberValid(processedInt) && processedInt !== '';
    let parsedInt = isValid ? processedInt : nullable ? null : 0;;
    if (!isNil(parsedInt) && this.props.inputParams?.percent) {
      parsedInt = round(parsedInt * 100, 2);
    }

    this.state = {
      int: parsedInt,
    };
  }
  inputRef!: HTMLInputElement;

  componentDidMount() {
    // For some reason, ag-grid render misses the focus here if we don't
    // wait a cycle before stealing focus into new element.
    setTimeout(() => {
      if (this.inputRef != null) {
        this.inputRef.focus();
      }
    }, 40);
  }

  componentDidUpdate = (_prevProps: IntegerEditorProps, prevState: IntegerEditorState) => {
    if (!this.isNumberValid(this.state.int)) {
      this.setState({
        int: prevState.int,
      });
    }
  };

  isNumberValid = (value: number | string | null) => {
    // add test to make sure it is a number
    let tests: string[] = ['isNumeric(value)'];
    if (this.props.inputParams) {
      const { valueTests, min, max, nullable } = this.props.inputParams;
      if (!isNil(min)) {
        tests.push(`value >= ${min}`);
      }
      if (!isNil(max)) {
        tests.push(`value <= ${max}`);
      }
      if (nullable === false) {
        tests.push(`value != null`);
      }
      if (valueTests) {
        tests = tests.concat(valueTests);
      }
    }

    // strings at this point should only be decimals in progress '2.'
    // need to return true for isNumberValid for decimals in progress
    return !isString(value) && !isValueValid(value, tests) ? false : true;
  };

  getValue() {
    const { inputParams } = this.props;
    const value = this.innerGetValue();

    if (isNil(value) || (value as unknown) === '') {
      return null;
    } else if (inputParams && inputParams.percent && !isString(value)) {
      return value * 0.01;
    }

    return value;
  }

  innerGetValue() {
    const { int } = this.state;
    const { passedInt, inputParams } = this.props;

    if (isString(int)) {
      // in case user tries to submit value with decimalInProgress '2.', value is parsed as 2
      return parseFloat(int);
    } else if (!isNil(int) && !this.isNumberValid(int)) {
      return this.isNumberValid(passedInt) ? passedInt : 0;
    } else if (inputParams && inputParams.nullable) {
      return int;
    }
    return isNil(int) ? 0 : int;
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedInput = formatInput(value); // potential output formats (2, NaN for empty strings, '2.')
    const setToNull = !isString(formattedInput) && isNaN(formattedInput);
    this.setState({
      int: setToNull ? null : formattedInput,
    });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.props.stopEditing();
    }
  };

  render() {
    const { int } = this.state;
    const { inputParams } = this.props;
    const extraAttrs = {
      min: 0, // min is defaults to 0
    };

    if (inputParams && !isNil(inputParams.min)) {
      extraAttrs['min'] = inputParams.min;
    }

    if (inputParams && !isNil(inputParams.max)) {
      extraAttrs['max'] = inputParams.max;
    }

    const value = int === null ? '' : int;
    const position = this.props.regularPosition ? 'inherit' : 'absolute';
    return (
      <input
        type="text"
        ref={(ref) => {
          if (ref) this.inputRef = ref;
        }}
        value={value}
        onChange={this.onChange}
        onKeyDown={this.handleKeyDown}
        style={{ position }}
        className={classes(inputStyle, arrowlessNumberInputStyle)}
        {...extraAttrs}
      />
    );
  }
}
