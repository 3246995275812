import { AxiosInstance } from 'axios';
import { AppType, BoundEnabledPerspective, Bubble, TenantConfig } from './codecs/bindings.types';
import { BoundTab, BoundTenant, BoundPerspective } from './codecs/bindings.types';
import { bindTenant, bindPerspectives } from './bindings.utils';
import { UIConfDefn, validateConfDefn } from './codecs/confdefn';
import { BehaviorSubject, Observable, firstValueFrom, filter, map, defer } from 'rxjs';
import {
  PerspectiveConfig,
  getPerspectiveConfig,
  isEnabledPerspective,
  isEnabledPerspectiveOrBubble,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection';
import { get, isUndefined, kebabCase } from 'lodash';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
import { UNINITIALIZED } from 'src/utils/Domain/Constants';
const appConfigPath = '/api/uidefn/uiconfig';
const fingerPath = '/api/uidefn/config/fingerprint';

export interface IsDelayed {
  whenDone(): Promise<void>;
}

export interface BoundTabs {
  defaultPathSlot: string;
  tabs: BoundTab[];
}

export interface HasPerspectives {
  getEnabledPerspectives(): BoundEnabledPerspective[];
  getAllPerspectives(): BoundPerspective[];
}

export interface HasBindings {
  getBindings(perspective: BoundPerspective): BoundTenant;
}

interface AppConfiguration
  extends Record<
    string,
    {
      tenant: TenantConfig;
      perspective: PerspectiveConfig;
    }
  > {}

const perspectiveToValue = (perspective: BoundEnabledPerspective | Bubble): string[] => {
  return 'view' in perspective
    ? ((perspective.view as unknown) as BoundEnabledPerspective[])
        .filter(isEnabledPerspective)
        .flatMap(perspectiveToValue)
    : [perspective.appType];
};
export const findPerspective = (allPerspectives: Record<string, any>, key: string) => {
  if (isUndefined(allPerspectives[key])) {
    // Check if perspective is inside the Bubble's view array
    const bubbleView = allPerspectives.Bubble.perspective.view;
    if (Array.isArray(bubbleView)) {
      const matchingPerspective = bubbleView.find((viewItem) => viewItem.perspective.pathSlot === key);
      return matchingPerspective;
    }
  } else {
    return allPerspectives[key];
  }
  return undefined;
};
/**
 * Takes the `pathSlot` of a perspective and generates the lookup key
 * (e.g. top-down => TopDown)
 */
export function getPerspectiveLookupKey(p: BoundPerspective) {
  return isEnabledPerspective(p) ? p.pathSlot : kebabCase(p.title);
}

export async function fetchAllConfdefns(axios: AxiosInstance) {
  const allPerspectives: Record<string, any> = {};
  const perspectiveRes = (await getPerspectiveConfig()).data;
  perspectiveRes.view.forEach((p) => {
    if (p.type === 'Bubble') {
      // TODO: need to make sure to include parent in allPerspectives mapping so they can be displayed properly
      // grab leaf items for nested perspectives
      const nestedView = p.view.map((nestedPerspective) => {
        // at this level are expected to be enabled
        return { perspective: nestedPerspective, tenant: null };
      });
      allPerspectives[p.type] = { perspective: { ...p, view: nestedView } };
    } else {
      const perspectiveLookupKey = getPerspectiveLookupKey(p);
      allPerspectives[perspectiveLookupKey] = { perspective: p, tenant: null };
    }
  });

  const confDefns = await Promise.all(
    perspectiveRes.view
      .filter(isEnabledPerspectiveOrBubble)
      .flatMap(perspectiveToValue)
      .map(async (value) => {
        try {
          if (value === AppType.TDAnalysis) {
            // eslint-disable-next-line no-console
            console.warn(
              `Fetching confdefn with appName ${AppType.TDAnalysis}, using ${AppType.Assortment} to avoid 404`
            );
            return await axios.get(`${appConfigPath}?appName=${AppType.Assortment}`);
          }
          return await axios.get(`${appConfigPath}?appName=${value}`);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return Promise.resolve({});
        }
      })
  );

  confDefns.forEach((cd) => {
    const cdData: UIConfDefn = get(cd, 'data.data', ({
      id: UNINITIALIZED,
      context: {},
      perspectives: [],
      tabs: [],
    } as unknown) as UIConfDefn);
    const validatedConfdefn = validateConfDefn(cdData, ServiceContainer.loggingService);

    // assign to all available perspectives defined by the confdefn
    const perspectives = validatedConfdefn.perspectives;
    perspectives.forEach((perspective) => {
      const foundPerspective = findPerspective(allPerspectives, perspective);
      if (foundPerspective) {
        foundPerspective.tenant = validatedConfdefn;
      }
    });
  });
  return allPerspectives;
}

export interface ConfigurationService extends IsDelayed, HasPerspectives, HasBindings {
  getOrThrow(): TenantConfig;
  configuration$: Observable<AppConfiguration>;
  errored$: Observable<Error | null>;
}

export const fetchFingerPrint = (axios: AxiosInstance) => {
  return () => {
    return axios.get(`${fingerPath}`).then((resp): string => {
      return resp.data;
    });
  };
};

export default (axios: AxiosInstance) => (): ConfigurationService => {
  // Log the initialization
  // We make no effort to dispose subscriptions so this is an easy way of
  // verify that we aren't leaking

  // Most recent tenant config
  const configuration$: BehaviorSubject<AppConfiguration | null> = new BehaviorSubject<AppConfiguration | null>(null);
  const error$: BehaviorSubject<Error | null> = new BehaviorSubject<Error | null>(null);

  // Immediatley attempt to load the configuration.
  // Immediatley attempt to load the configuration.
  function requestConfig(): Observable<AppConfiguration> {
    return defer(() => fetchAllConfdefns(axios));
  }

  requestConfig().subscribe({
    next: (v) => configuration$.next(v),
    error: (e) => {
      toast.error(`Configuration terminally failed`);
      ServiceContainer.loggingService.error(`Configuration terminally failed`, e);
      error$.next(e);
    },
  });

  function assertReady(): AppConfiguration {
    const e = error$.getValue();
    if (e) {
      throw e;
    }
    const c = configuration$.getValue();
    if (!c) {
      throw new Error('Configuration has not been received yet.');
    }
    return c;
  }

  function whenDone(): Promise<void> {
    return firstValueFrom(configuration$.pipe(filter((v) => !!v)).pipe(map(() => undefined)));
  }

  function getEnabledPerspectives(): BoundEnabledPerspective[] {
    assertReady();
    // Safe due to assertReady
    // TODO filter by type
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const persps = Object.values(configuration$.getValue()!).flatMap((p) => {
      //@ts-ignore
      return bindPerspectives(p.tenant, p.perspective);
    });
    return persps;
  }

  function getAllPerspectives(): BoundPerspective[] {
    assertReady();
    // Safe due to assertReady
    // @ts-ignore
    return Object.values(configuration$.getValue()!).flatMap((p) => {
      if (p.perspective.type === 'Bubble') {
        return {
          ...p.perspective,
          view: p.perspective.view,
        };
      } else {
        return p.perspective;
      }
    });
  }

  function getBindings(perspective: BoundEnabledPerspective): BoundTenant {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const value = configuration$.getValue()!;
    const perspectiveLookupKey = getPerspectiveLookupKey(perspective);

    const foundPerspective = findPerspective(value, perspectiveLookupKey);
    const appTenant = foundPerspective ? foundPerspective.tenant : undefined;

    return bindTenant(perspective)(appTenant);
  }

  // Only extant configs for downstream to use
  const loadedConfiguration$ = configuration$.pipe(filter((v) => !!v)).pipe(map((v) => v!));

  function getOrThrowConf() {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return configuration$.getValue()!['Assortment'].tenant;
  }

  return {
    whenDone,
    getEnabledPerspectives,
    getAllPerspectives,
    getBindings,
    getOrThrow: getOrThrowConf,
    configuration$: loadedConfiguration$,
    errored$: error$,
  };
};
