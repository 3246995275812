import { AppEpic, AppState } from 'src/store';
import { requestRefreshGrid, receivedScope, receivedCreateScope } from 'src/state/scope/Scope.slice';
import { ofType } from 'redux-observable';
import { concatMap, filter, mergeMap, map, catchError } from 'rxjs/operators';
import { empty, from } from 'rxjs';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  setMetricsToSummarize,
  requestSummarizedScope,
  receivedSummarizedScope,
  summaryError,
  setSelcetedMacroAnchors,
} from './MacroSummaries.slice';
import {
  getAvailableListing,
  getEffeciveGroups,
  PivotOptions,
  getGroupFromConfigItem,
} from 'src/components/Mfp/PivotConfigurator/utils';
import { getMetricsToSummarize, getVersionsToSummarize } from './MacroSummaries.selector';
import { getScopeReadyData, ScopeStateUnion, ScopeReadyData } from 'src/state/scope/Scope.types';
import PivotManager from 'src/pivot/Pivot.client';
import PivotConfig from 'src/pivot/PivotConfig';
import { keyBy, flatMap, isNil, isEmpty, mapValues, values, pickBy } from 'lodash';
import { PivotCell } from 'src/pivot/PivotCell';
import { AxiosInstance } from 'axios';
import { AnyAction } from '@reduxjs/toolkit';
import { SettingsState } from 'src/state/settings/settings.slice';
import { TopMembers } from 'src/services/Scope.client';
import { planFromSpace } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';
import { inputIsNotNullOrUndefined, topMemInWorklistSelected } from 'src/utils/Functions/epicsFunctions';
import { setActiveNonWorkingScope } from 'src/state/workingSets/nonWorkingSets.slice';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import {
  ConfDefnComponentType,
  MfpSummaryGridComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';

// TODO make a new epic for review and favorites
// TODO: figure out how to set this only once
export const setMetricConfigEpic: AppEpic = (action$, state$, deps): Observable<AnyAction> | Observable<never> => {
  const client = deps.axios;
  const macroData = deps.macroData;
  return action$.pipe(
    ofType(setActivePage.type, setActiveNonWorkingScope.type, receivedScope.type, receivedCreateScope.type),
    map(() => {
      return maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpSummaryGrid);
    }),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    concatMap((props) => {
      const sumMets: string[] | undefined = props.viewParams?.summaryMetrics.metrics.items;
      const sumVers: string[] | undefined = props.viewParams?.summaryMetrics.revisions.items;
      const scopeToSummarize: ScopeStateUnion | undefined = state$.value.mfpScope;

      const hasSummaryConfig = !!sumVers && !!sumMets;
      if (!sumVers || !sumMets || !scopeToSummarize) {
        return empty();
      }
      const readyScope = getScopeReadyData(scopeToSummarize);
      if (!readyScope) {
        return empty();
      }
      if (readyScope.hasEditableRevision) {
        readyScope.mainConfig.initializedPlans.forEach((plan) => {
          const maybePm = createPivoteManager(
            state$.value.settings.entriesByKey,
            readyScope,
            // @ts-ignore
            plan.space,
            sumMets,
            sumVers,
            client
          );
          if (maybePm) {
            macroData[Number(plan.id)] = maybePm;
          }
        });
      } else {
        const shouldBeReadyScope = readyScope;
        if (!readyScope || !shouldBeReadyScope.currentAnchors) {
          return empty();
        }
        let scopes: Record<string, string[]>[] = [];
        const nonMultiScopeDims = pickBy(shouldBeReadyScope.currentAnchors, (ids) => ids.length === 1);
        const multiScopeDims = pickBy(shouldBeReadyScope.currentAnchors, (ids) => ids.length > 1);
        mapValues(multiScopeDims, (v, k) => {
          v.forEach((id) => {
            scopes.push({
              ...nonMultiScopeDims,
              [k]: [id],
            });
          });
        });
        if (isEmpty(scopes)) {
          scopes = [shouldBeReadyScope.currentAnchors];
        }
        scopes.forEach((s) => {
          const macroId = Object.values(s)
            .sort()
            .join('');
          const maybePm = createPivoteManager(
            state$.value.settings.entriesByKey,
            readyScope!,
            // @ts-ignore
            s,
            sumMets,
            sumVers,
            client
          );
          if (maybePm) {
            macroData[macroId] = maybePm;
          }
        });
      }

      // TODO: reset pm when the settings change
      // may require moving the pm to the component
      const maybeSetSettings = hasSummaryConfig
        ? setMetricsToSummarize(sumMets, [sumVers[0], sumVers[1], sumVers[2]])
        : null;
      if (maybeSetSettings) {
        return of(maybeSetSettings);
      }
      return empty();
    })
  );
};

export const setMetricConfigEpicForMacroSummRenderer: AppEpic = (action$, state$, deps): Observable<AnyAction> | Observable<never> => {
  const client = deps.axios;
  const macroData = deps.macroData;
  return action$.pipe(
    ofType(setActivePage.type, setActiveNonWorkingScope.type, receivedScope.type, receivedCreateScope.type),
    map(() => {
      return maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpReviewPrivatePlans)
        || maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpReviewPlans);
    }),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    concatMap((props) => {
      const sumMets: string[] | undefined = props.viewParams?.summaryMetrics.metrics.items;
      const sumVers: string[] | undefined = props.viewParams?.summaryMetrics.revisions.items;
      const scopeToSummarize: ScopeStateUnion | undefined = state$.value.nonWorkingSets.activeNonWorkingScope;

      const hasSummaryConfig = !!sumVers && !!sumMets;
      if (!sumVers || !sumMets || !scopeToSummarize) {
        return empty();
      }
      const readyScope = getScopeReadyData(scopeToSummarize);
      if (!readyScope) {
        return empty();
      }
      if (readyScope.hasEditableRevision) {
        readyScope.mainConfig.initializedPlans.forEach((plan) => {
          const maybePm = createPivoteManager(
            state$.value.settings.entriesByKey,
            readyScope,
            // @ts-ignore
            plan.space,
            sumMets,
            sumVers,
            client
          );
          if (maybePm) {
            macroData[Number(plan.id)] = maybePm;
          }
        });
      } else {
        const shouldBeReadyScope = readyScope;
        if (!readyScope || !shouldBeReadyScope.currentAnchors) {
          return empty();
        }
        let scopes: Record<string, string[]>[] = [];
        const nonMultiScopeDims = pickBy(shouldBeReadyScope.currentAnchors, (ids) => ids.length === 1);
        const multiScopeDims = pickBy(shouldBeReadyScope.currentAnchors, (ids) => ids.length > 1);
        mapValues(multiScopeDims, (v, k) => {
          v.forEach((id) => {
            scopes.push({
              ...nonMultiScopeDims,
              [k]: [id],
            });
          });
        });
        if (isEmpty(scopes)) {
          scopes = [shouldBeReadyScope.currentAnchors];
        }
        scopes.forEach((s) => {
          const macroId = Object.values(s)
            .sort()
            .join('');
          const maybePm = createPivoteManager(
            state$.value.settings.entriesByKey,
            readyScope!,
            // @ts-ignore
            s,
            sumMets,
            sumVers,
            client
          );
          if (maybePm) {
            macroData[macroId] = maybePm;
          }
        });
      }

      // TODO: reset pm when the settings change
      // may require moving the pm to the component
      const maybeSetSettings = hasSummaryConfig
        ? setMetricsToSummarize(sumMets, [sumVers[0], sumVers[1], sumVers[2]])
        : null;
      if (maybeSetSettings) {
        return of(maybeSetSettings);
      }
      return empty();
    })
  );
};

export const requestSummarizedDataEpic: AppEpic = (
  action$,
  state$,
  deps
  // SPIKE: why does this return unknown?
): Observable<AnyAction> | Observable<never> | Observable<unknown> => {
  return action$.pipe(
    ofType(
      setActivePage.type,
      requestSummarizedScope.type,
      requestRefreshGrid.type,
      setSelcetedMacroAnchors.type,
      setMetricsToSummarize.type
    ),
    filter(() => {
      return shouldRefreshSummary(state$.value);
    }),
    filter(() => {
      return !isNil(state$.value.macroSummaries.selectedMacroAnchors);
    }),
    map(() => {
      return maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpSummaryGrid)
        || maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpReviewPrivatePlans)
        || maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpReviewPlans);
    }),
    filter(inputIsNotNullOrUndefined),
    map((_action) => state$.value.mfpScope),
    filter(inputIsNotNullOrUndefined),
    map(getScopeReadyData),
    filter(inputIsNotNullOrUndefined),
    mergeMap((readyScope) => {
      if (!readyScope || !state$.value.macroSummaries.selectedMacroAnchors) {
        throw new Error('Scope should be ready here');
      }
      let pm: PivotManager | undefined;
      if (readyScope.hasEditableRevision) {
        const plans = [...readyScope.mainConfig?.initializedPlans, ...readyScope.mainConfig.uninitializedPlans];
        if (isEmpty(plans)) {
          const aa = of(summaryError());
          return aa;
        }
        const currentMacroPlan = planFromSpace(plans, state$.value.macroSummaries.selectedMacroAnchors);
        pm = deps.macroData[currentMacroPlan.id];
      } else {
        const currentMacroPlan = Object.values(readyScope.currentAnchors!)
          .sort()
          .join('');
        pm = deps.macroData[currentMacroPlan];
      }
      if (!pm) {
        return empty();
      }
      const ret = from(loadSummaryCells(state$.value, pm!)).pipe(
        filter(inputIsNotNullOrUndefined),
        map((response) => response),
        catchError((error) => of(summaryError()))
      );
      return ret;
    })
  );
};

export const requestSummarizedDataEpicForMacroSummRenderer: AppEpic = (
  action$,
  state$,
  deps
  // SPIKE: why does this return unknown?
): Observable<AnyAction> | Observable<never> | Observable<unknown> => {
  return action$.pipe(
    ofType(
      setActivePage.type,
      requestSummarizedScope.type,
      requestRefreshGrid.type,
      setSelcetedMacroAnchors.type,
      setMetricsToSummarize.type
    ),
    filter(() => {
      return shouldRefreshSummary(state$.value);
    }),
    filter(() => {
      return !isNil(state$.value.macroSummaries.selectedMacroAnchors);
    }),
    map(() => {
      return maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpReviewPrivatePlans)
        || maybeGetComponentProps<MfpSummaryGridComponent>(state$.value, ConfDefnComponentType.mfpReviewPlans);
    }),
    filter(inputIsNotNullOrUndefined),
    map((_action) => state$.value.nonWorkingSets.activeNonWorkingScope),
    filter(inputIsNotNullOrUndefined),
    map(getScopeReadyData),
    filter(inputIsNotNullOrUndefined),
    mergeMap((readyScope) => {
      if (!readyScope || !state$.value.macroSummaries.selectedMacroAnchors) {
        throw new Error('Scope should be ready here');
      }
      let pm: PivotManager | undefined;
      if (readyScope.hasEditableRevision) {
        const plans = [...readyScope.mainConfig?.initializedPlans, ...readyScope.mainConfig.uninitializedPlans];
        if (isEmpty(plans)) {
          const aa = of(summaryError());
          return aa;
        }
        const currentMacroPlan = planFromSpace(plans, state$.value.macroSummaries.selectedMacroAnchors);
        pm = deps.macroData[currentMacroPlan.id];
      } else {
        const currentMacroPlan = Object.values(readyScope.currentAnchors!)
          .sort()
          .join('');
        pm = deps.macroData[currentMacroPlan];
      }
      if (!pm) {
        return empty();
      }
      const ret = from(loadSummaryCells(state$.value, pm!)).pipe(
        filter(inputIsNotNullOrUndefined),
        map((response) => response),
        catchError((error) => of(summaryError()))
      );
      return ret;
    })
  );
};

const shouldRefreshSummary = (state: AppState): boolean => {
  // get summary existence from componentProps
  // TODO: add check for component props here
  const metricsToSummarize = getMetricsToSummarize(state);
  const versionsToSummarize = getVersionsToSummarize(state);

  if (!metricsToSummarize || !versionsToSummarize) return false;
  return true;
};

function loadSummaryCells(state: AppState, macroPivotManager: PivotManager): Promise<AnyAction | undefined> {
  const metricsToSummarize = getMetricsToSummarize(state);
  macroPivotManager;
  const versionsToSummarize = getVersionsToSummarize(state);

  if (!macroPivotManager || !metricsToSummarize || !versionsToSummarize) {
    // should only be called when everything needed is ready
    // eslint-disable-next-line no-console
    console.error("Don't have everything needed to get summary");
    return new Promise((resolve, reject) => {
      resolve(undefined);
    });
  }

  const results: PivotCell[] = [];
  const maxCols = macroPivotManager.getColCount();
  const maxRows = macroPivotManager.getRowCount();
  macroPivotManager.forAllPresent((cell) => {
    cell.needsReload = true;
  });
  return macroPivotManager
    .loadMoreCells({
      startRow: 0,
      endRow: maxRows,
      startColumn: 0,
      endColumn: maxCols,
    })
    .then(() => {
      for (let r = 0; r <= maxRows; r++) {
        for (let c = 0; c < maxCols; c++) {
          const cell = macroPivotManager.getCell(r, c);
          if (cell) {
            results.push(cell);
          }
        }
      }
      return receivedSummarizedScope(results);
    })
    .catch((err) => {
      return summaryError;
    });
}

function createPivoteManager(
  settings: SettingsState['entriesByKey'],
  scopeToSummarize: ScopeReadyData,
  selectedMacroAnchors: TopMembers,
  metricsToSummarize: string[],
  versionsToSummarize: string[],
  client: AxiosInstance
) {
  const mainConfig = scopeToSummarize.mainConfig;
  const scopeId = mainConfig.id;
  if (scopeId === undefined) {
    return undefined;
  }
  const all = getAvailableListing(mainConfig, settings);

  const deltaVersion = `${versionsToSummarize![0]}||${versionsToSummarize![1]}||percentage`;

  const multiScopeDims = pickBy(scopeToSummarize.currentAnchors, (ids) => ids.length >= 2);
  const selectedSpace = mapValues(multiScopeDims, (ids, dimension) => {
    return ids.map((id) => {
      return selectedMacroAnchors[dimension].includes(id) ? { id, selected: true } : { id, selected: false };
    });
  });
  const topMembersAsPivotConfig = values(
    mapValues(selectedSpace, (v, k) => {
      return {
        dimension: k,
        items: v.filter((i) => i.selected).map((i) => i.id),
      };
    })
  );
  const rows = [
    {
      dimension: 'revisions',
      items: [...versionsToSummarize, deltaVersion],
    },
    ...topMembersAsPivotConfig,
  ];
  const columns = [
    {
      dimension: 'metrics',
      items: metricsToSummarize,
    },
  ];
  const viewParams: PivotOptions = {
    rows,
    columns,
  };
  const effective = getEffeciveGroups(all, viewParams);
  const levelsMap = keyBy(flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
  const pivotCols = effective.column.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
  if (isEmpty(pivotCols) || isEmpty(pivotRows)) {
    // Safety check for invalid pivots being called
    // TODO: report and throw error here
    return undefined;
  }
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols,
  });
  const pm = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: `summaryPivot${JSON.stringify(selectedMacroAnchors)}`,
  });
  return pm;
}
