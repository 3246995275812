import { isNil, mapValues, values } from 'lodash';
import { availableMembers } from 'src/mockjson/members.mock';
import React from 'react';
import { connect } from 'react-redux';
import { AvailableMembers } from 'src/services/Scope.client';
import { Space } from 'src/space';
import { ServerScopeMember, getScopeReadyData } from 'src/state/scope/Scope.types';
import { AppState } from 'src/store';

const mapStateToProps = (state: AppState) => {
  const { mfpScope, settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(mfpScope);

  if (!readyScope || isNil(state.settings.dimensionLabelProperty) || !state.viewConfigSlice.availableMembers) {
    return;
  }
  const availableMembers = state.viewConfigSlice.availableMembers;

  return {
    dimensionLabel,
    availableMembers,
  };
};

type AnchorToLabelValueProps = ReturnType<typeof mapStateToProps>;

const AnchorToLabel = (
  props: {
    anchor: Record<string, string>;
  } & AnchorToLabelValueProps
) => {
  const { anchor, availableMembers, dimensionLabel } = props;

  if (!availableMembers || !dimensionLabel) {
    return (
      <React.Fragment>
        {values(
          mapValues(anchor, (memberId, dim) => {
            return <ul key={memberId}>{memberId}</ul>;
          })
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {values(
        mapValues(anchor, (memberId, dim) => {
          return (
            <ul key={memberId}>
              {availableMembers.space[dim].find((mem) => mem.id === memberId)![dimensionLabel[dim]]}
            </ul>
          );
        })
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(AnchorToLabel);
