import { getScopeReadyData } from './Scope.types';
import Scope from '../../services/Scope.client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from 'src/store';
import { PlanId } from './codecs/PlanMetadata';

export const getPublishVersions = createAsyncThunk<Error | string[], PlanId, ThunkApi>(
  'scopeManagement/getPublishVersions',
  async (applyTo, { extra, getState }) => {
    const scope = getScopeReadyData(getState().mfpScope);
    if (!scope) {
      throw new Error('Attempted to fetch publish versions without a ready scope');
    }
    return await new Scope(extra.axios).getPublishVersions(scope.mainConfig.id, applyTo);
  }
);
// eslint-disable-next-line max-len
export const publishToVersion = createAsyncThunk<void, { versionToPublishTo: string; applyTo: PlanId }, ThunkApi>(
  'scopeManagement/setPublishVersions',
  async (payload, { extra, getState, rejectWithValue }) => {
    const scope = getScopeReadyData(getState().mfpScope);
    if (!scope) {
      throw new Error('Attempted to fetch publish versions without a ready scope');
    }
    try {
      return await new Scope(extra.axios).publishToVersion(
        scope.mainConfig.id,
        payload.versionToPublishTo,
        payload.applyTo
      );
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);
