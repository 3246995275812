import { createSelector } from 'reselect';
import { AppState } from 'src/store';
import { HindsightingSlice } from 'src/pages/Hindsighting/Hindsighting.reducer';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { TenantConfigViewItem, GeoTrendsConfigData, isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { mapNameToDefaultUri } from 'src/utils/Component/GeoChart';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { GeoTrendsChartsDefn, GeoTrendsMapDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { isDataLoaded } from 'src/services/pivotServiceCache';

interface StateSelection {
  geoTrends: HindsightingSlice['geoTrends'];
  subheader: AppState['subheader'];
  configLoaded: boolean;
  chartDataLoaded: boolean;
  mapDataLoaded: boolean;
  dataLoaded: boolean;
}

export interface StateProjection {
  title: string;
  mapUri?: string;
  selectedItem?: ExtendedPointObject;
  selectedGeoLevel?: TenantConfigViewItem;
  mapConfig?: GeoTrendsConfigData;
  chartConfig?: GeoTrendsChartsDefn;
  chartDataLoaded: boolean;
  mapDataLoaded: boolean;
  dataLoaded: boolean;
  configLoaded: boolean;
  data: BasicPivotItem[];
  chartData: BasicPivotItem[];
  flowStatus: SubheaderSlice['flowStatus'];
  showLookBackPeriod: boolean;
  showFlowStatus: boolean;
}

export interface FunctionProps {
  onShowView: () => void;
  onUpdate: () => void;
  onUpdateGeoLevel: (selectedGeo: GeoTrendsMapDefn['view'][0] | undefined) => void;
  onSelectPoint: (selectedPoint: ExtendedPointObject) => void;
  onDestroy: () => void;
}
export interface GeoTrendsProps extends FunctionProps, StateProjection {}
function selectState(state: AppState) {
  const geoTrends = state.pages.hindsighting.geoTrends;
  const configLoaded = isViewDefnLoaded(geoTrends.viewDefnState);
  const chartDataLoaded = isDataLoaded(geoTrends.chartDataState);
  const mapDataLoaded = isDataLoaded(geoTrends.mapDataState);
  const dataLoaded = chartDataLoaded && mapDataLoaded;
  return {
    geoTrends,
    subheader: state.subheader,
    configLoaded,
    chartDataLoaded,
    mapDataLoaded,
    dataLoaded,
  };
}

function projectState(selection: StateSelection): StateProjection {
  const { geoTrends, subheader, configLoaded, chartDataLoaded, mapDataLoaded, dataLoaded } = selection;
  const { lookBackPeriod, flowStatus } = subheader;

  let data = geoTrends.mapData;
  const chartData = geoTrends.chartData;

  if (lookBackPeriod && geoTrends.showLookBackPeriod) {
    // Filter by lookBackPeriod
    data = cascadingFilter(geoTrends.mapData, makelookBackPredicate(lookBackPeriod));
  } else {
    data = geoTrends.mapData;
  }

  return {
    title: 'Geo Trends',
    configLoaded,
    dataLoaded,
    chartDataLoaded,
    mapDataLoaded,
    mapUri: mapNameToDefaultUri(geoTrends.mapConfig?.mapUri) || '',
    selectedItem: geoTrends.selectedItem,
    selectedGeoLevel: geoTrends.selectedGeoLevel,
    chartConfig: geoTrends.chartConfig,
    flowStatus,
    data,
    chartData,
    showLookBackPeriod: geoTrends.showLookBackPeriod,
    showFlowStatus: geoTrends.showFlowStatus,
    mapConfig: geoTrends.mapConfig,
  };
}

export default createSelector(selectState, projectState);
