import { ofType } from "redux-observable";
import { AppEpic } from "src/store";
import { mergeMap, map, filter, of } from 'rxjs';
import {
  cleanUp,
  receiveTenantConfig,
  receiveError,
  receiveGeoData,
  requestGeoData,
  geotrendsRequestChartData,
  geotrendsReceiveChartData,
  TenantResponse,
  selectPoint,
  resetPoint,
  selectGeoLevel,
} from './GeoTrends.slice';
import { setActivePage, setActiveSubPage } from "src/pages/NavigationShell/NavigationShell.slice";
import { receiveFilterStateAfterSubmission } from "src/components/FilterPanel/FilterPanel.slice";
import { receiveScopeRefreshTrigger } from "src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice";
import { updateFlowStatus } from "src/components/Subheader/Subheader.slice";
import {
    ConfDefnComponentType,
    GeoTrendsComponent,
    maybeGetComponentProps,
    isSameComponentType,
  } from 'src/services/configuration/codecs/confdefnComponents';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from "src/utils/Functions/epicsFunctions";
import { asyncGetMapData } from "./GeoTrends.container";
import { state } from "fp-ts";
import { isNil } from "lodash";

export const geoTrendsLoad: AppEpic = (action$, state$) => {
    return action$.pipe(
        ofType(
            receiveScopeRefreshTrigger.type,
            setActivePage.type,
            setActiveSubPage.type,
            receiveTenantConfig.type,
            updateFlowStatus.type,
            selectGeoLevel.type
        ),
        map(() => {
            return maybeGetComponentProps<GeoTrendsComponent>(state$.value, ConfDefnComponentType.geoTrends)
        }),
        filter(inputIsNotNullOrUndefined),
        filter(() => isScopeDefined(state$.value.scope)),
        mergeMap(({ dataApi }) => {
            return of(asyncGetMapData(dataApi));
        })
    )
}