import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from 'src/store';
import Settings, { AdvisoryStyle, AncillaryPayload, LocalizationPayload, SettingsEntry } from '../../services/Settings';

export const getSettings = createAsyncThunk<
  {
    settings: SettingsEntry[],
    localization: LocalizationPayload,
    ancillary: AncillaryPayload,
    advisoryStyles: AdvisoryStyle[]
  }, // return shape
  Settings,
  ThunkApi>(
    'settings/getSettings',
    async (settingsService) => {
      const proms: [
        Promise<SettingsEntry[]>,
        Promise<LocalizationPayload>,
        Promise<AncillaryPayload>,
        Promise<AdvisoryStyle[]>
      ] = [
          settingsService.getSettings(),
          settingsService.getLocalizationConfig(),
          settingsService.getAncillaryConfig(),
          settingsService.getAdvisoryConfig()
        ];
      return await Promise.all(proms).then((settingsData) => {
        return {
          settings: settingsData[0],
          localization: settingsData[1],
          ancillary: settingsData[2],
          advisoryStyles: settingsData[3]
        };
      });
    }
  );


