import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './_Comments.scss';
import { connect } from 'react-redux';
import type { AppState, AppThunkDispatch } from 'src/store';
import Comment, { DummyComment } from './Comment';
import type { ProtoComment } from './Comments.types';
import Overlay from 'src/common-ui/components/Overlay/Overlay';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Paper, TextField, ThemeProvider, Typography, withTheme } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { Button } from 'semantic-ui-react';
import { addComment } from './Comments.actions';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { isEmpty, isNil } from 'lodash';
import { useHandleControlEnterPress } from 'src/utils/Component/hooks/hooks';
import { toast } from 'react-toastify';
import AnchorDropdownSelect from 'src/components/AnchorDropdownSelect/AnchorDropdownSelect';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import { TopMembers } from 'src/services/Scope.client';
import { getScopeObject, planFromSpace } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: 'inline'
    }
  })
);

const mapStateToProps = (state: AppState) => {
  const maybeScope = getScopeReadyData(state.mfpScope);
  const maybeScopeRootMembers = maybeScope ? getScopeObject(maybeScope.mainConfig.memberTrees) : undefined;
  return {
    comments: Object.values(state.comments.entities),
    commentPlanId: state.comments.commentsPlanId,
    loading: state.comments.commentsAsyncState,
    // TODO replace this with comparable values from confdefn
    currentViewContext: {
      currentPerspective: 'state.viewConfigSlice.currentPerspective',
      currentViewId: 'state.viewConfigSlice.currentViewId',
      currentTabId: 'state.viewConfigSlice.currentTabId',
      currentFavoriteId: 'state.viewConfigSlice.currentTabId'
    },
    currentAnchors: maybeScope?.currentAnchors,
    initializedPlans: maybeScope?.mainConfig.initializedPlans,
    scopeRootMembers: maybeScopeRootMembers,
    labelDimension: state.settings.dimensionLabelProperty
  };
};
const mapDispatchToDrops = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedAddComment: (newComment: ProtoComment) => dispatch(addComment(newComment))
  };
};
type CommentsDispatchProps = ReturnType<typeof mapDispatchToDrops>;
type CommentsValueProps = ReturnType<typeof mapStateToProps>
type CommentsProps = CommentsValueProps & CommentsDispatchProps;

const Comments = (props: CommentsProps) => {
  const {
    comments,
    dispatchedAddComment,
    currentViewContext,
    loading,
    currentAnchors,
    initializedPlans,
    scopeRootMembers,
    labelDimension,
    commentPlanId
  } = props;
  const editable = loading === 'loadedScope' || loading === 'loadingScopeComments';
  const classes = useStyles();
  const [newCommentValue, setNewCommentValue] = useState<string>('');
  const textfieldRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCommentValue(event.target.value);
  };

  useEffect(() => {
    // this exists to re-focus the element after adding a comment and the fresh list is loaded
    const ta = textfieldRef.current;
    if (ta && loading === 'loadedScope') {
      ta.focus();
    }
  }, [loading]);

  const [currentCommentPlanId, setCurrentCommentPlanId] = useState<PlanId | null>(
    !isNil(initializedPlans) && !isEmpty(initializedPlans) ? initializedPlans[0].id : null
  );
  const handleChangeCommentPlanId = useCallback((newMembers: TopMembers) => {
    if (isNil(initializedPlans) || isEmpty(initializedPlans)) { return; }
    const newPlanId = planFromSpace(initializedPlans, newMembers).id;
    setCurrentCommentPlanId(newPlanId);
  }, [initializedPlans]);
  const currentPlanCommentIds = useMemo(() => {
    const ret = comments.filter((c) => !isNil(c)).filter((c) => c!.planId === currentCommentPlanId).map((c => c!.commentId));
    return ret;
  }, [comments, currentCommentPlanId]);
  useEffect(() => {
    // we store the selected admin plan id in redux, but selected scope plan id here
    if (loading === 'loadedPlanComments' && commentPlanId) {
      setCurrentCommentPlanId(commentPlanId);
    } else if (loading === 'loadedScope' && !commentPlanId) {

    }
  }, [commentPlanId, currentCommentPlanId, loading]);

  const handleClickAddComment = useCallback(async () => {
    if (newCommentValue && currentCommentPlanId) {
      const commentReponse = await dispatchedAddComment({
        content: newCommentValue,
        viewContext: JSON.stringify(currentViewContext), // spray and pray
        viewTemplateId: '', // unused
        planId: currentCommentPlanId
      });
      // createAsyncThunk returns the action itself, so we match it here and respond on the match()
      if (addComment.fulfilled.match(commentReponse)) {
        setNewCommentValue('');
      } else {
        if (addComment.rejected.match(commentReponse)) {
          toast.error(commentReponse.payload);
        } else {
          toast.error('An error occured adding your comment');
        }
      }
    }
  }, [newCommentValue, currentCommentPlanId, dispatchedAddComment, currentViewContext]);
  const handleControlEnterPress = useHandleControlEnterPress(handleClickAddComment);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={'comments'}>
      {(loading !== 'loadedPlanComments') && (loading !== 'loadedScope') && <Overlay visible={true} type="loading" />}
        <section className={'header-content'}>
          <Typography variant="h5" gutterBottom>
            {(() => {
              switch (loading) {
                case 'loadedPlanComments':
                  return 'Comments (Admin)';
                case 'loadedScope':
                  return 'Comments';
                case 'loadingPlanComments':
                case 'loadingScopeComments':
                  return '...loading';
                default:
                  return '';
              }
            })()}
          </Typography>
        </section>
        <section className='list'>
          <Paper className='paper'>
            <List className={classes.root}>
              {!isEmpty(currentPlanCommentIds) ?
                (currentPlanCommentIds.map((cId, index) => <Comment commentId={cId!.toString()} index={index} key={index} />)) :
                <DummyComment key='dummy' />
              }
            </List>
          </Paper>
          {editable ? (<section className={'controls'}>
            <form noValidate={true} autoComplete="off" className={'entry'}>
              {currentAnchors ?
                <AnchorDropdownSelect anchor={scopeRootMembers!} onUpdateAnchorSelections={handleChangeCommentPlanId} labelDimenion={labelDimension!} /> :
                null
              }
              <TextField
                inputRef={textfieldRef}
                autoFocus={true}
                disabled={loading === 'loadingScopeComments'}
                label="Enter Comment"
                multiline={true}
                rows={4}
                variant="outlined"
                required={true}
                value={newCommentValue}
                onChange={handleChange}
                onKeyPress={handleControlEnterPress}
              />
              <Button
                disabled={loading === 'loadingScopeComments'}
                variant="contained"
                size='tiny'
                onClick={handleClickAddComment}
                onKeyPress={handleControlEnterPress}
                loading={loading === 'loadingScopeComments'}
              >
                <div>
                  Comment
                  <span><i className="far fa-comment-alt-plus"></i></span>
                </div>
              </Button>
            </form>
          </section>) : null}
        </section>
      </div>
    </ThemeProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToDrops)(withTheme(Comments));
