import { z } from 'zod';
import {
  AssortmentAddBySearchComponentProps,
  AssortmentAddViewComponentProps,
  ConfigurableGridComponentProps,
  AssortmentPublishComponentProps,
  CanvasViewComponentProps,
  CategorySummaryComponentProps,
  CollectionViewComponentProps,
  ColumnGroupedViewComponentProps,
  EnhancedOvertimeComponentProps,
  GridViewComponentProps,
  HistoryGridComponentProps,
  NestedAttributeComponentProps,
  NestedOvertimeComponentProps,
  ParameterTogglesComponentProps,
  zParetoAnalysisComponentProps,
  zParetoDetailsComponentProps,
  zEditableOverTimeGridComponentProps,
  ProductDetailsComponentProps,
  ProductivityComponentProps,
  ProductMixComponentProps,
  QuickTrendsComponentProps,
  RouteToLocationComponentProps,
  SizeEligibilityListGridComponentProps,
  StyleEditComponentProps,
  SummaryViewComponentProps,
  TargetListComponentProps,
  TopPerformersComponentProps,
  WorklistComponentProps,
  ExceptionsSummaryComponentProps,
  AssortmentCartViewComponentProps,
  zGeoTrendsComponentProps,
  zMfpSummaryGridComponentProps,
  zMacroMixComponentProps,
  zSummaryTopdownComponentProps,
  zMfpSplitViewComponentProps,
  zMfpSmartPlanComponentProps,
  zBulkImportComponentProps,
  zMfpReviewPlansComponentProps,
} from './confdefnComponentProps';
import { BaseSectionView } from './confdefnView';
import { ProplessComponents } from './literals';
import { isNil } from 'lodash';
// import { AppState } from './bindings.types'; TODO fix
import { isWorklistTabWithComponentProps } from './Worklist';

const ProplessComponent = BaseSectionView.merge(
  z.object({
    component: ProplessComponents,
    // propless components are given an empty object to make the types easier
    // SPIKE: move this to BaseSectionView?
    componentProps: z.object({}).default({}),
  })
);
const AssortmentCartViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('AssortmentCart'),
    componentProps: AssortmentCartViewComponentProps,
  })
);

const CollectionViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('CollectionView'),
    componentProps: CollectionViewComponentProps,
  })
);
export interface CollectionViewComponent extends z.infer<typeof CollectionViewComponent> {}

const CanvasViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('CanvasView'),
    componentProps: CanvasViewComponentProps,
  })
);
export interface CanvasViewComponent extends z.infer<typeof CanvasViewComponent> {}

const SummaryViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('SummaryView'),
    componentProps: SummaryViewComponentProps,
  })
);
export interface SummaryViewComponent extends z.infer<typeof SummaryViewComponent> {}

const GridViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('GridView'),
    componentProps: GridViewComponentProps,
  })
);
export interface GridViewComponent extends z.infer<typeof GridViewComponent> {}

const ColumnGroupedViewComponent = BaseSectionView.merge(
  z.object({
    component: z.union([z.literal('FlowType'), z.literal('TopTYvsLY'), z.literal('FloorsetComparison')]),
    componentProps: ColumnGroupedViewComponentProps,
  })
);
export interface ColumnGroupedViewComponent extends z.infer<typeof ColumnGroupedViewComponent> {}

const QuickTrendsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('QuickTrends'),
    componentProps: QuickTrendsComponentProps,
  })
);
export interface QuickTrendsComponent extends z.infer<typeof QuickTrendsComponent> {}

const CategorySummaryComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('CategorySummary'),
    componentProps: CategorySummaryComponentProps,
  })
);
export interface CategorySummaryComponent extends z.infer<typeof CategorySummaryComponent> {}

const ProductMixComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ProductMix'),
    componentProps: ProductMixComponentProps,
    overflow: z
      .string()
      .optional()
      .default('auto'),
  })
);
export interface ProductMixComponent extends z.infer<typeof ProductMixComponent> {}

const ProductivityComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('Productivity'),
    componentProps: ProductivityComponentProps,
  })
);
export interface ProductivityComponent extends z.infer<typeof ProductivityComponent> {}

const NestedAttributeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('NestedAttribute'),
    componentProps: NestedAttributeComponentProps,
  })
);
export interface NestedAttributeComponent extends z.infer<typeof NestedAttributeComponent> {}

const ProductDetailsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ProductDetails'),
    componentProps: ProductDetailsComponentProps,
  })
);
export interface ProductDetailsComponent extends z.infer<typeof ProductDetailsComponent> {}

const WorklistComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('Worklist'),
    componentProps: WorklistComponentProps,
  })
);
export interface WorklistComponent extends z.infer<typeof WorklistComponent> {}

const RouteToLocationComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('RouteToLocation'),
    componentProps: RouteToLocationComponentProps,
  })
);
export interface RouteToLocationComponent extends z.infer<typeof RouteToLocationComponent> {}

const StyleEditComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('StyleEdit'),
    componentProps: StyleEditComponentProps,
  })
);
export interface StyleEditComponent extends z.infer<typeof StyleEditComponent> {}

const zEditableOverTimeGridComponent = BaseSectionView.merge(
  z.object({
    component: z.union([z.literal('PricingOverTime'), z.literal('FlowSheetByStyle')]),
    componentProps: zEditableOverTimeGridComponentProps,
  })
);
export interface EditableOverTimeGridComponent extends z.infer<typeof zEditableOverTimeGridComponent> {}

const GridComponent = BaseSectionView.merge(
  //@ts-ignore
  z.object({
    component: z.literal('ConfigurableGrid'),
    componentProps: ConfigurableGridComponentProps,
  })
);
export interface GridComponent extends z.infer<typeof GridComponent> {}

const AssortmentAddViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('AssortmentAddView'),
    componentProps: AssortmentAddViewComponentProps,
  })
);
export interface AssortmentAddViewComponent extends z.infer<typeof AssortmentAddViewComponent> {}

const AssortmentAddBySearchComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('AssortmentAddBySearch'),
    componentProps: AssortmentAddBySearchComponentProps,
  })
);
export interface AssortmentAddBySearchComponent extends z.infer<typeof AssortmentAddBySearchComponent> {}

const EnhancedOvertimeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('EnhancedOvertime'),
    componentProps: EnhancedOvertimeComponentProps,
  })
);
export interface EnhancedOvertimeComponent extends z.infer<typeof EnhancedOvertimeComponent> {}

const NestedOvertimeCombineComponent = BaseSectionView.merge(
  z.object({
    component: z.union([z.literal('NestedOvertime'), z.literal('NestedStyleOvertime')]),
    componentProps: NestedOvertimeComponentProps,
  })
);

export interface NestedOvertimeCombineComponent extends z.infer<typeof NestedOvertimeCombineComponent> {}

const AssortmentPublishComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('AssortmentPublish'),
    componentProps: AssortmentPublishComponentProps,
  })
);
export interface AssortmentPublishComponent extends z.infer<typeof AssortmentPublishComponent> {}

const TargetListComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('TargetList'),
    componentProps: TargetListComponentProps,
    // FIXME: this needs to be moved into componentProps
    allowNesting: z.boolean(),
  })
);
export interface TargetListComponent extends z.infer<typeof TargetListComponent> {}

const SizeEligibilityListGridComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('SizeEligibilityListGrid'),
    componentProps: SizeEligibilityListGridComponentProps,
  })
);
export interface SizeEligibilityListGridComponent extends z.infer<typeof SizeEligibilityListGridComponent> {}

const ParameterTogglesComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ParameterToggles'),
    componentProps: ParameterTogglesComponentProps,
  })
);
export interface ParameterTogglesComponent extends z.infer<typeof ParameterTogglesComponent> {}

const ParetoAnalysisComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ParetoSummary'),
    componentProps: zParetoAnalysisComponentProps,
  })
);
const ParetoDetailsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ParetoDetails'),
    componentProps: zParetoDetailsComponentProps,
  })
);

export interface ParetoAnalysisComponent extends z.infer<typeof ParetoAnalysisComponent> {}
export interface ParetoDetailsComponent extends z.infer<typeof ParetoDetailsComponent> {}

const TopPerformersComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('TopPerformers'),
    componentProps: TopPerformersComponentProps,
  })
);
export interface TopPerformersComponent extends z.infer<typeof TopPerformersComponent> {}

const ListAndNestedViewCombineComponent = BaseSectionView.merge(
  z.object({
    component: z.union([z.literal('NestedView'), z.literal('ListView')]),
    componentProps: HistoryGridComponentProps,
  })
);

export interface ListAndNestedViewCombineComponent extends z.infer<typeof ListAndNestedViewCombineComponent> {}

const zExceptionsSummaryComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ExceptionsSummary'),
    componentProps: ExceptionsSummaryComponentProps,
  })
);
export interface ExceptionsSummaryComponent extends z.infer<typeof zExceptionsSummaryComponent> {}

const zGeoTrendsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('GeoTrends'),
    componentProps: zGeoTrendsComponentProps,
  })
);
export interface GeoTrendsComponent extends z.infer<typeof zGeoTrendsComponent> {}

const zMacroMixComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('MacroMix'),
    componentProps: zMacroMixComponentProps,
  })
);
export interface MacroMixComponent extends z.infer<typeof zMacroMixComponent> {}

// MFP Components
const zMfpSummaryGridComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('MfpSummaryGrid'),
    componentProps: zMfpSummaryGridComponentProps,
  })
);
export interface MfpSummaryGridComponent extends z.infer<typeof zMfpSummaryGridComponent> {}

const zMfpFavoriteComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('MfpFavorite'),
    componentProps: zMfpSummaryGridComponentProps,
  })
);
const zSummaryMacroTopDownComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('Summary'),
    componentProps: zSummaryTopdownComponentProps,
  })
);
export interface GeoTrendsComponent extends z.infer<typeof zGeoTrendsComponent> {}
export interface MfpFavoriteComponent extends z.infer<typeof zMfpFavoriteComponent> {}

const zMfpSplitViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('MfpSplitView'),
    componentProps: zMfpSplitViewComponentProps,
  })
);
export interface MfpSplitViewComponent extends z.infer<typeof zMfpSplitViewComponent> {}

const zMfpSmartPlanComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('MfpSmartPlan'),
    componentProps: zMfpSmartPlanComponentProps,
  })
);
export interface MfpSmartPlanComponent extends z.infer<typeof zMfpSmartPlanComponent> {}

const zMfpReviewPlansComponent = BaseSectionView.merge(
  z.object({
    component: z.union([z.literal('MfpReviewPlans'), z.literal('MfpReviewPrivatePlans')]),
    componentProps: zMfpReviewPlansComponentProps
  })
);
export interface MfpReviewPlansComponent extends z.infer<typeof zMfpReviewPlansComponent> {}

const zBulkImportComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('BulkImport'),
    componentProps: zBulkImportComponentProps,
  })
);
export interface BulkImportComponent extends z.infer<typeof zBulkImportComponent> {}

// TODO: would like to intersect RouteComponentProps here instead of at each individual componets OwnProp declaration
// see this issue: https://github.com/colinhacks/zod/issues/53
// const RoutePropsSchema = toZod(RouteComponentProps);
// then .merge/.and(RoutPropsSchema)
export const SectionView = z.union([
  ProplessComponent,
  CollectionViewComponent,
  AssortmentCartViewComponent,
  CanvasViewComponent,
  SummaryViewComponent,
  GridViewComponent,
  ColumnGroupedViewComponent,
  CategorySummaryComponent,
  ProductMixComponent,
  ProductivityComponent,
  NestedAttributeComponent,
  ProductDetailsComponent,
  WorklistComponent,
  StyleEditComponent,
  zEditableOverTimeGridComponent,
  GridComponent,
  AssortmentAddViewComponent,
  AssortmentAddBySearchComponent,
  NestedOvertimeCombineComponent,
  ParetoAnalysisComponent,
  ParetoDetailsComponent,
  AssortmentPublishComponent,
  TargetListComponent,
  SizeEligibilityListGridComponent,
  ParameterTogglesComponent,
  TopPerformersComponent,
  ListAndNestedViewCombineComponent,
  EnhancedOvertimeComponent,
  QuickTrendsComponent,
  RouteToLocationComponent,
  zExceptionsSummaryComponent,
  zGeoTrendsComponent,
  zMfpSummaryGridComponent,
  zMacroMixComponent,
  zSummaryMacroTopDownComponent,
  zMfpFavoriteComponent,
  zMfpSplitViewComponent,
  zMfpSmartPlanComponent,
  zMfpReviewPlansComponent,
  zBulkImportComponent,
]);
export type SectionViewConf = z.infer<typeof SectionView>;

/**
 * Used to determine the name value of the desired component if used in `ofComponentType`.
 * The string value needs to map directly to the `component` property value in the component validator
 */
export enum ConfDefnComponentType {
  collectionView = 'CollectionView',
  canvasView = 'CanvasView',
  summaryView = 'SummaryView',
  gridView = 'GridView',
  flowType = 'FlowType',
  topTyLy = 'TopTYvsLY',
  categorySummary = 'CategorySummary',
  productMix = 'ProductMix',
  productivity = 'Productivity',
  nestedAttribute = 'NestedAttribute',
  productDetails = 'ProductDetails',
  worklist = 'Worklist',
  floorsetComparison = 'FloorsetComparison',
  styleEdit = 'StyleEdit',
  pricing = 'PricingOverTime',
  flowSheet = 'FlowSheetByStyle',
  receiptGrid = 'ReceiptGrid',
  assortmentAddView = 'AssortmentAddView',
  assortmentAddBySearch = 'AssortmentAddBySearch',
  nestedOvertime = 'NestedOvertime',
  nestedStyleOverTime = 'NestedStyleOvertime',
  assortmentPublish = 'AssortmentPublish',
  targetList = 'TargetList',
  sizeEligibility = 'SizeEligibilityListGrid',
  parameterToggles = 'ParameterToggles',
  paretoSummary = 'ParetoSummary',
  paretoDetails = 'ParetoDetails',
  topPerformers = 'TopPerformers',
  nestedView = 'NestedView',
  listView = 'ListView',
  visualize = 'Visualize',
  enhancedOvertime = 'EnhancedOvertime',
  quickTrends = 'QuickTrends',
  configurableGrid = 'ConfigurableGrid',
  configurableGridView = 'ConfigurableGridView',
  routeToLocation = 'RouteToLocation',
  quickReconcile = 'QuickReconcile',
  geoTrends = 'GeoTrends',
  macroMix = 'MacroMix',
  summary = 'Summary',
  mfpFavorite = 'MfpFavorite',
  mfpSplitView = 'MfpSplitView',
  mfpSmartPlan = 'MfpSmartPlan',
  // propless components are listed below to be referenced via component type for error handling reporting purposes
  reporting = 'Reporting',
  bulkImport = 'BulkImport',
  assortmentCart = 'AssortmentCart',
  exceptionSummary = 'ExceptionsSummary',
  configEditor = 'ConfigEditor',
  mfpSummaryGrid = 'MfpSummaryGrid',
  mfpReviewPlans = 'MfpReviewPlans',
  mfpReviewPrivatePlans = 'MfpReviewPrivatePlans',
  mfpReporting = 'MfpReporting',
  mfpMassCopy = 'MfpMassCopy',
  mfpMassActualize = 'MfpMassActualize',
}

function isOfType<T extends Record<string, unknown>>(
  confType: ConfDefnComponentType,
  sectionView: T | undefined
): sectionView is T {
  return !isNil(sectionView) && 'component' in sectionView && sectionView['component'] === confType;
}

/**
 * A partially dynamic helper method that removes the need for individual type predicates for each component above.
 * This is mainly used in epics to narrow down the types of the componentProps (a.k.a ownProps).
 *
 * e.g. isComponentType(ConfDefnComponentType.canvasView)
 *
 * @param {ConfDefnComponentType} componentType - the mapped name of the component type to verify.
 * @param {any} conf - the conf of the activePage or activeSubPage; the type is really a `SectionViewConf`
 *
 * @returns boolean
 */
export function isOfComponentType<T extends Record<string, unknown>>(
  type: ConfDefnComponentType,
  conf: any
): conf is T {
  return isOfType<T>(type, conf);
}

export function maybeGetComponentProps<T extends SectionViewConf>(
  state: any,
  componentType: ConfDefnComponentType
): T['componentProps'] | null {
  if (isNil(state.perspective)) {
    return null;
  }
  const activePageConf = state.perspective.activePageConf;
  const activeSubPage = state.perspective.activeSubPage;
  const worklistConf = state.worklist;

  if (isOfComponentType<T>(componentType, activePageConf)) {
    return activePageConf.componentProps;
  } else if (isOfComponentType<WorklistComponent>(ConfDefnComponentType.worklist, activePageConf)) {
    if (activeSubPage && worklistConf && worklistConf.viewDefn) {
      const tabConf = worklistConf.viewDefn.tabs.find((tab: any) => {
        return tab.pathSlot === activeSubPage;
      });
      if (tabConf && tabConf.componentType === componentType && isWorklistTabWithComponentProps(tabConf)) {
        return {
          //@ts-ignore
          ...tabConf.componentProps,
          topMembers: worklistConf.selectedItem,
        };
      }
    }
  }

  return null;
}

export function isWorklistActive(state: any): boolean {
  const activePageConf = state.perspective.activePageConf;
  return isOfComponentType<WorklistComponent>(ConfDefnComponentType.worklist, activePageConf);
}

/**
 * This only detects correctly when navigating from worklist to non worklist of the same component type
 *
 * i.e Worklist -> Summary View Tab to Summary View
 */
export function isSameComponentType<T extends SectionViewConf>(
  state: any,
  componentType: ConfDefnComponentType
): boolean {
  const activePageConf = state.perspective.activePageConf;
  const activeSubPage = state.perspective.activeSubPage;

  const isActivePage = isOfComponentType<T>(componentType, activePageConf);
  const isActiveSubPage = activeSubPage === componentType;

  return isActivePage && isActiveSubPage;
}
