import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UNINITIALIZED } from 'src/utils/Domain/Constants';
import { TenantConfig } from './codecs/bindings.types';

export interface AppConfigSlice {
  tenantConfig: TenantConfig;
  configFingerprint?: string;
  confDefns: Record<string, any>;
}

const defaultConfig = {
  id: UNINITIALIZED,
  defaultTab: UNINITIALIZED,
  flowStatus: {
    values: [],
    defaultSelection: [],
  },
  lookBackPeriods: [],
  perspectives: [],
  placeholder: {
    text: 'PLACEHOLDER',
  },
  context: {
    assortment: { tabs: [] },
    hindsighting: { tabs: [] },
    allocation: { tabs: [] },
  },
  tabs: [],
  printSizes: [],
  canAddNewStyles: true,
  showBookmarks: false,
  disableScope: true,
  disableFilters: true,
  fabTypes: {},
} as TenantConfig;

export const initialState: AppConfigSlice = {
  tenantConfig: defaultConfig,
  confDefns: {},
};

const appConfigSlice = createSlice({
  name: 'AppConfig',
  initialState,
  reducers: {
    updateAppConfig(state, action: PayloadAction<TenantConfig>) {
      state.tenantConfig = action.payload;
    },
    updateConfigFingerPrint(state, action: PayloadAction<string>) {
      state.tenantConfig = state.tenantConfig;
      state.configFingerprint = action.payload;
    },
    updateConfDefns(state, action: PayloadAction<Record<string, any>>) {
      state.confDefns = action.payload;
    },
  },
});

export const { updateAppConfig, updateConfigFingerPrint, updateConfDefns } = appConfigSlice.actions;

export default appConfigSlice.reducer;
