import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { TenantConfigViewData, ViewDefnState } from 'src/dao/tenantConfigClient';
import service from 'src/ServiceContainer';
import { TrendSummaryConfig } from 'src/components/TrendSummary/TrendSummary';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface QuickTrendsSlice {
  summaryViewDefn: TrendSummaryConfig | null;
  detailsViewDefn: TenantConfigViewData | null;
  viewDefnState: ViewDefnState;
  trendsSummaryDataState: ViewDataState;
  trendsSummaryCacheHash: string | null;
  trendsDetailsCacheHash: string | null;
}

const initialState: QuickTrendsSlice = {
  viewDefnState: ViewDefnState.idle,
  summaryViewDefn: null,
  detailsViewDefn: null,
  trendsSummaryDataState: ViewDataState.idle,
  trendsSummaryCacheHash: null,
  trendsDetailsCacheHash: null,
};

const quickTrendsReducer = createSlice({
  name: 'QuickTrends',
  initialState,
  reducers: {
    requestTrendsConfig: (state) => {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTrendsConfig: (
      state,
      action: PayloadAction<{ summaryViewDefn: TrendSummaryConfig; detailsViewDefn: TenantConfigViewData }>
    ) => {
      state.summaryViewDefn = action.payload.summaryViewDefn;
      state.detailsViewDefn = action.payload.detailsViewDefn;
      state.viewDefnState = ViewDefnState.loaded;
    },
    requestSummaryData(state) {
      state.trendsSummaryDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveSummaryCacheHash(state, action: PayloadAction<string>) {
      state.trendsSummaryCacheHash = action.payload;
    },
    receiveSummaryCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.trendsSummaryCacheHash) {
        state.trendsSummaryDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveSummaryLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.trendsSummaryCacheHash) {
        state.trendsSummaryDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  receiveTrendsConfig,
  requestTrendsConfig,
  requestSummaryData,
  receiveSummaryCacheHash,
  receiveSummaryCachedData,
  receiveSummaryLiveData,
  cleanUp,
  receiveError,
} = quickTrendsReducer.actions;

export function fetchQuickTrendsData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestSummaryData,
    receiveSummaryCacheHash,
    receiveSummaryCachedData,
    receiveSummaryLiveData
  );
}

export default quickTrendsReducer.reducer;
