import { z } from 'zod';
import { PlanId } from './PlanMetadata';
import { Space } from 'src/space';
import { isEmpty, isEqual, mapValues, uniq } from 'lodash';
import { TopMembers } from 'src/services/Scope.client';
import {
  Command,
  CommandAnchor,
  CommandAnchorDetail,
  CommandDetail,
  CommandImport,
  zCOMMAND_ACTUALS,
  zCOMMAND_BALANCE,
  zCOMMAND_IMPORT,
  zCOMMAND_PLAN,
  zCOMMAND_SEED,
} from './Commands';

export const isCommandSeed = (comm: Pick<Command, 'type'>): comm is CommandAnchor => comm.type === zCOMMAND_SEED.value;
export const isCommandBalance = (comm: Pick<Command, 'type'>): comm is CommandAnchor =>
  comm.type === zCOMMAND_BALANCE.value;
export const isCommandImport = (comm: Pick<Command, 'type'>): comm is CommandImport =>
  comm.type === zCOMMAND_IMPORT.value;

export const commandsFromPlanId = (
  commands: CommandDetail[] | undefined | null,
  planId: PlanId | null
): Command[] | undefined => {
  return commands?.find((c) => c.planId === planId)?.commands;
};

export const commandAnchorsFromPlanId = <T extends { planId: PlanId }>(
  commands: T[] | undefined,
  planId: PlanId | undefined
): T | undefined => {
  return commands?.find((c) => c.planId === planId);
};

export const getCommandsByType = (comm: CommandDetail, type: string): CommandDetail | CommandAnchorDetail => {
  return {
    ...comm,
    commands: comm.commands.filter((c) => c.type === type),
  };
};
// @ts-ignore
export const isCommandAnchor = (comm: CommandAnchor | CommandImport): comm is CommandAnchor => 'anchor' in comm.command;
export const isCommandAnchorDetail = (comm: CommandDetail | CommandAnchorDetail): comm is CommandAnchorDetail => {
  return comm.commands.every(isCommandAnchor);
};
export const getBalanceCommands = (comm: CommandDetail): CommandAnchorDetail => {
  return {
    ...comm,
    commands: comm.commands.filter(isCommandBalance),
  };
};
export const getImportCommands = (comm: CommandDetail): CommandDetail => {
  return {
    ...comm,
    commands: comm.commands.filter(isCommandImport),
  };
};
export const getSeedCommands = (comm: CommandDetail): CommandAnchorDetail => {
  return {
    ...comm,
    commands: comm.commands.filter(isCommandSeed),
  };
};

export const hasCommands = (comm: CommandDetail): boolean => !isEmpty(comm.commands);

export const anchorsFromCommandDetails = (comms: CommandDetail[]): Space<string[]> | undefined => {
  if(isEmpty(comms)) return;
  return mapValues(comms[0].anchor, (v, k) => {
    return uniq(comms.flatMap((c) => c.anchor[k]));
  }) as Space<string[]>;
};

export const commandDetailFromSpace = (comms: CommandDetail[], anchor: TopMembers): CommandDetail => {
  const flatAnchor = mapValues(anchor, (v, k) => v[0]);
  const foundCommD = comms.find((pln) => isEqual(pln.anchor, flatAnchor));
  if (!foundCommD) {
    throw new Error("Plan not found in set, this shouldn't happen");
  }
  return foundCommD;
};
