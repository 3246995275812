import classNames from 'classnames';
import _, { isNil } from 'lodash';
import React from 'react';
import { Draggable, Droppable, DroppableProvided, DraggableProvidedDraggableProps } from 'react-beautiful-dnd';
import { TIME, AVAILABLE } from '../../../utils/Domain/Constants';
import { ConfigItem } from './PivotConfigurator.types';
import PivotToggle from './PivotToggle';

export interface PivotEntryProps {
  index: number;
  configItem: ConfigItem;
  level?: number;
  parentId: string;
  currentDraggableId: string;
  toggleable?: boolean;
  onToggleClick: (dragId: string) => void;
  onCollapseExpand: (dragId: string) => void;
  onCheckAll?: (group: ConfigItem, parentId: string) => void;
  checkAll?: (group: ConfigItem, parentId: string) => void;
}

export interface PivotEntryState {
  level: number;
}

export default class PivotConfigEntry extends React.Component<PivotEntryProps, PivotEntryState> {
  public constructor(props: PivotEntryProps) {
    super(props);
    this.state = {
      level: this.props.level || 1,
    };
  }

  public isDropDisabled() {
    const draggableId = this.props.currentDraggableId;
    const parentId = this.props.parentId;

    if (draggableId === '' || draggableId.startsWith('$')) {
      return true;
    }

    const path = draggableId.split('/');
    const parentPath = parentId.split('/');
    const dragParentPath = path.slice(0, path.length - 1);
    parentPath.push(this.props.configItem.id);
    return !_.isEqual(parentPath, dragParentPath);
  }

  public renderChildren(provided: DroppableProvided) {
    const id = `${this.props.parentId}/${this.props.configItem.id}`;
    let children = this.props.configItem.children || [];

    // time cannot be sorted
    if (!id.includes(TIME)) {
      children.sort((lChild, rChild) => {
        return Number(lChild.disabled) - Number(rChild.disabled);
      });
    }

    children = children.filter((child) => !child.alwaysHidden);
    return (
      <div ref={provided.innerRef} {...provided.droppableProps} className="pivot-config-entry-items">
        {!this.props.configItem.collapsed &&
          children.map((cg, i) => (
            <PivotConfigEntry
              key={i}
              index={i}
              configItem={cg}
              checkAll={this.props.checkAll}
              level={this.state.level + 1}
              parentId={id}
              currentDraggableId={this.props.currentDraggableId}
              toggleable={true}
              onToggleClick={this.props.onToggleClick}
              onCollapseExpand={this.props.onCollapseExpand}
            />
          ))}
        {provided.placeholder}
      </div>
    );
  }

  public augmentDraggableProps(draggableProps: DraggableProvidedDraggableProps) {
    const newProps = { ...draggableProps };
    if (!newProps.style) {
      return newProps;
    }
    newProps.style = { ...newProps.style };
    if ('height' in newProps.style) {
      // @ts-ignore
      delete newProps.style.height;
    }
    return newProps;
  }

  public onCheckAll = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (this.props.onCheckAll) {
      this.props.onCheckAll(this.props.configItem, this.props.parentId);
    }
  };

  private handleCollapse = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (this.props.onCollapseExpand) {
      this.props.onCollapseExpand(`${this.props.parentId}/${this.props.configItem.id}`);
    }
  };

  private handleToggleEntry = (e: React.MouseEvent<HTMLDivElement>) => {
    // this isn't great
    if (e.nativeEvent.srcElement) {
      // @ts-ignore
      const targetClassList = e.nativeEvent.srcElement.classList;
      if (targetClassList.contains('ignore-toggle')) {
        return;
      }

      e.stopPropagation();
      const id = `${this.props.parentId}/${this.props.configItem.id}`;
      this.props.onToggleClick(id);
    }
  };

  public render() {
    const { configItem, parentId, toggleable, index, level } = this.props;
    const { hidden } = configItem;
    const id = `${parentId}/${configItem.id}`;
    const children = configItem.children || [];

    // eslint-disable-next-line max-len
    const isRootAvailableDimension =
      (id.startsWith(AVAILABLE) && !isNil(index) && isNil(level)) || !id.startsWith(AVAILABLE);
    const isTimeNotRoot = id.includes(TIME) && !isNil(level);
    const isHidden = !hidden ? false : hidden;

    let toggleButtonClass = 'no-toggle';
    if (children.length) {
      toggleButtonClass = configItem.collapsed ? 'fa-chevron-down' : 'fa-chevron-up';
    }
    const toggleButton = toggleable ? (
      <PivotToggle checked={!configItem.disabled} locked={!isRootAvailableDimension} />
    ) : (
      <React.Fragment />
    );

    return (
      <React.Fragment>
        <Draggable
          draggableId={id}
          index={this.props.index}
          isDragDisabled={!isRootAvailableDimension || isTimeNotRoot}
        >
          {(dragProvider, snapshot) => (
            <Droppable droppableId={id} isDropDisabled={this.isDropDisabled()}>
              {(provided) => (
                <div
                  className={classNames(
                    'pivot-config-entry',
                    !isRootAvailableDimension ? 'no-toggle' : null,
                    snapshot.isDragging ? 'dragging' : '',
                    isHidden ? 'hidden' : ''
                  )}
                  {...this.augmentDraggableProps(dragProvider.draggableProps)}
                >
                  <div onClick={this.handleToggleEntry} className="pivot-config-entry-title">
                    <div
                      className={classNames(
                        'draggy',
                        'ignore-toggle',
                        !isRootAvailableDimension || isTimeNotRoot ? 'hide' : null
                      )}
                      ref={dragProvider.innerRef}
                      {...dragProvider.dragHandleProps}
                    >
                      <i className={'fal fa-fw fa-bars ignore-toggle'} />
                    </div>
                    <i
                      className={classNames('pivot-config-entry-toggle', 'far', toggleButtonClass)}
                      onClick={this.handleCollapse}
                    />
                    <span>{this.props.configItem.name}</span>
                    {toggleButton}
                    {this.props.children}
                  </div>
                  {this.renderChildren(provided)}
                </div>
              )}
            </Droppable>
          )}
        </Draggable>
      </React.Fragment>
    );
  }
}
