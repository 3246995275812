import * as React from 'react';
import Axios from 'src/services/axios';
import { reduce, isNil, isEmpty, isEqual, noop } from 'lodash';
import { style } from 'typestyle';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { toast } from 'react-toastify';
import { ClientDataApi, ListDataApi } from 'src/services/configuration/codecs/confdefnView';
import ColorSwatch from 'src/common-ui/components/ColorSwatch/ColorSwatch';
import { BasicItem } from 'src/worker/pivotWorker.types';
import { STYLE_ID, STYLE_COLOR_IS_REMOVABLE } from 'src/utils/Domain/Constants';
import { PINKISH, GRAY, CURSOR_POINTER, CURSOR_NOT_ALLOWED } from 'src/utils/Style/Theme';
import { Overlay } from 'src/common-ui/index';
import ServiceContainer from 'src/ServiceContainer';
import { getSwatchUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
interface PaneItem {
  dataIndex: string;
  text?: string;
}

interface PaneActionItem {
  text: string;
  type: string;
  dataApi: ClientDataApi;
}

interface LeftPaneConfig {
  image: PaneItem;
  swatches: PaneItem;
}

interface RightPaneConfig {
  headerItems: PaneItem[];
  sections: PaneItem[];
}

interface StyleDetailsEditorConfig {
  actions: PaneActionItem[];
  leftPane: LeftPaneConfig;
  rightPane: RightPaneConfig;
}

interface ActionProps {
  action: PaneActionItem;
}

interface ActionButtonProps extends ActionProps {
  handleActionClick: (action: PaneActionItem) => void;
  isRemovable?: boolean;
}

export interface StyleDetailsEditorProps {
  dataApi?: ClientDataApi | ListDataApi;
  configApi: ClientDataApi;
  styleColorData?: BasicItem[];
  isLoading?: boolean;
}

export interface StyleDetailsEditorState {
  selectedIndex: number;
  config?: StyleDetailsEditorConfig;
  data?: BasicItem[];
  selectedData?: BasicItem;
  isLoading: boolean;
}

export const swatchStyles = style({
  $debugName: 'historyStylePaneSwatches',
  $nest: {
    div: {
      border: '1px solid #aca899',
      cursor: 'pointer',
      width: 20,
      height: 20,
      margin: '5px 3px',
      display: 'inline-block',
    },
  },
  whiteSpace: 'nowrap',
});

const ActionButton = (props: ActionButtonProps) => {
  const { isRemovable } = props;
  let color;
  let cursor;
  let disabled;

  if (!isRemovable && props.action.text === 'Remove Color') {
    color = GRAY; // gray
    cursor = CURSOR_NOT_ALLOWED;
    disabled = true;
  } else {
    color = PINKISH; // pink-ish
    cursor = CURSOR_POINTER;
    disabled = false;
  }

  return (
    <Button
      style={{
        textTransform: 'none',
        fontWeight: 'bold',
        cursor: cursor,
        color: color,
      }}
      size={'small'}
      onClick={() => props.handleActionClick(props.action)}
      disabled={disabled}
    >
      {props.action.text}
    </Button>
  );
};

export class StyleDetailsEditor extends React.Component<StyleDetailsEditorProps, StyleDetailsEditorState> {
  constructor(props: StyleDetailsEditorProps) {
    super(props);

    this.state = {
      selectedIndex: -1,
      isLoading: false,
    };
  }

  getConfig = async () => {
    const { configApi } = this.props;
    const resp = await Axios.get(configApi.url, {
      params: configApi.params,
    });
    const { data: config } = resp.data;
    this.setState({
      config,
    });
  };

  getData = async () => {
    const { dataApi, styleColorData } = this.props;

    if (dataApi && isNil(styleColorData)) {
      this.setState({
        isLoading: true,
      });

      if (dataApi.isListData) {
        ServiceContainer.pivotService.listData(dataApi.defnId, AppType.Assortment, dataApi.params).then((resp) => {
          const data = resp.flat;

          this.setState({
            isLoading: false,
            data,
            selectedData: !isEmpty(data) ? data[0] : ({} as BasicItem),
            selectedIndex: !isEmpty(data) ? 0 : -1,
          });
        });
      } else {
        const resp = await Axios.get(dataApi.url, {
          params: dataApi.params,
        });
        let { data } = resp.data;
        if (data.data) {
          data = data.data;
        }
        if (Array.isArray(data)) {
          data.forEach((i) => {
            i[STYLE_ID] = i.id.split('-');
          });
        }
        this.setState({
          isLoading: false,
          data,
          selectedData: isEmpty(data) ? data : data[0],
          selectedIndex: isEmpty(data) ? -1 : 0,
        });
      }
    } else if (styleColorData) {
      const noData = isEmpty(styleColorData);

      this.setState({
        data: styleColorData,
        selectedData: noData ? undefined : styleColorData[0],
        selectedIndex: noData ? -1 : 0,
      });
    }
  };

  componentDidMount() {
    const promise = Promise.all([this.getConfig(), this.getData()]);
    promise.catch((err) => {
      toast.error('An error occurred fetching the style details');
      ServiceContainer.loggingService.error(`An error occurred: ${err.message}`, err.stack);
    });
  }

  componentDidUpdate(oldProps: StyleDetailsEditorProps) {
    if (
      !isEqual(oldProps.dataApi, this.props.dataApi) ||
      !isEqual(oldProps.styleColorData, this.props.styleColorData)
    ) {
      this.getData();
    }
    if (!isEqual(oldProps.configApi, this.props.configApi)) {
      this.getConfig();
    }
  }

  getUrl(apiConfig: ClientDataApi) {
    const { selectedData } = this.state;

    return reduce(
      apiConfig.params,
      (url, value, key) => url.replace(`:${key}`, isNil(selectedData) ? value : selectedData[value]),
      apiConfig.url
    );
  }

  buildSwatches(items: BasicItem[] | undefined) {
    if (isNil(items)) {
      return;
    }

    const swatches = items.map((item) => {
      const id = item['member:stylecolor:id'] || item.id;
      const colorId = item['attribute:cccolor:id'];

      return {
        id,
        colorId,
        selected: false, // selection set on ColorSwatch below
        noImageUrl: '',
      };
    });

    const { selectedIndex } = this.state;

    if (swatches[0] && !swatches[0].id) {
      return;
    }
    return (
      <ColorSwatch
        noImageUrl={''}
        className={swatchStyles}
        onChangeColor={this.handleSwatchClick}
        colors={swatches}
        selected={selectedIndex}
        style={{ overflowX: 'auto', marginTop: 5, width: 200 }}
        getSwatchUrl={getSwatchUrl}
      />
    );
  }

  buildLeftPane() {
    const { config, data, selectedData: styleData } = this.state;

    if (isNil(config) || isNil(data) || isNil(styleData)) {
      return;
    }

    const imgPath = styleData[config.leftPane.image.dataIndex];
    const swatchJSX = this.buildSwatches(data);

    if (!swatchJSX) {
      return;
    }

    return (
      <Grid item={true} xs={6}>
        <img src={imgPath} width={150} />
        {swatchJSX}
      </Grid>
    );
  }

  buildRightPane() {
    const { config, selectedData: styleData } = this.state;

    if (isNil(config)) {
      return;
    }

    const sectionHeaders = config.rightPane.sections.map((item) => item.text);
    const sectionIndexes = config.rightPane.sections.map((item) => item.dataIndex);

    return (
      <React.Fragment>
        <Grid item={true} xs={12}>
          {config.rightPane.headerItems.map((item, index) => (
            <Typography key={index} gutterBottom={true} noWrap={true}>
              {styleData && styleData[item.dataIndex]}
            </Typography>
          ))}
          <Divider />
        </Grid>

        <Grid container={true}>
          <Grid item={true} xs={6}>
            {sectionHeaders.map((header, index) => (
              <Typography key={index} gutterBottom={true} noWrap={true}>
                {header}
              </Typography>
            ))}
          </Grid>

          <Grid item={true} xs={6}>
            {sectionIndexes.map((dataIndex, index) => (
              <Typography key={index} gutterBottom={true} noWrap={true}>
                {styleData && styleData[dataIndex]}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  buildActions() {
    const { config, data } = this.state;

    if (isNil(config)) {
      return;
    }

    const styleColor = data && data[0];
    const isRemovable =
      !isNil(styleColor) &&
      (isNil(styleColor[STYLE_COLOR_IS_REMOVABLE]) || styleColor[STYLE_COLOR_IS_REMOVABLE] === 'true');
    return (
      <React.Fragment>
        {config.actions.map((action, index) => (
          <ActionButton
            key={index}
            action={action}
            handleActionClick={this.handleButtonClick}
            isRemovable={isRemovable}
          />
        ))}
      </React.Fragment>
    );
  }

  handleSwatchClick = (_id: string, position: number) => {
    const { data } = this.state;
    const selectedData = !isNil(data) && !isEmpty(data) ? data[position] : undefined;

    this.setState({
      selectedIndex: position,
      selectedData,
    });
  };

  handleButtonClick = (action: PaneActionItem) => {
    const { type, dataApi } = action;

    switch (type) {
      case 'edit':
        window.location.hash = this.getUrl(dataApi);
        break;
      default:
        return noop();
    }
  };

  render() {
    return (
      <div>
        <Overlay type="loading" visible={!!this.props.isLoading || this.state.isLoading} />
        <Grid container={true}>
          {this.buildLeftPane()}

          <Grid item={true} xs={6}>
            {this.buildRightPane()}
          </Grid>

          <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
            {this.buildActions()}
          </Grid>
        </Grid>
      </div>
    );
  }
}
