import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import {
  fetchParetoSummaryData,
  fetchParetoAnalysisData,
  receiveParetoAnalysisTenantConfig,
} from './ParetoAnalysis.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  ParetoAnalysisComponent,
  ParetoDetailsComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { FitViewOptions } from 'src/worker/pivotWorker.types';
import { isEmpty } from 'lodash';
import {
  receiveScopeConfig,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const paretoAnalysisLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      updateFlowStatus.type,
      receiveParetoAnalysisTenantConfig.type,
      receiveScopeConfig.type
    ),
    map(
      () =>
        maybeGetComponentProps<ParetoAnalysisComponent>(state$.value, ConfDefnComponentType.paretoDetails) ||
        maybeGetComponentProps<ParetoDetailsComponent>(state$.value, ConfDefnComponentType.paretoSummary)
    ),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns, groupingInfo }) => {
      const summaryDefn = defns.view[0];
      const analysisDefn = defns.model;

      const flowStatus = state$.value.subheader.flowStatus.join(',');
      const options: ExtraPivotOptions = { flowStatus };
      const summaryOptions = organizeListDataOptions(options);
      const analysisOptions: FitViewOptions = !isEmpty(groupingInfo.dataIndex)
        ? {
            flowStatus,
            groupBy: groupingInfo.dataIndex,
          }
        : { flowStatus };

      return of(
        fetchParetoSummaryData(summaryDefn, summaryOptions),
        fetchParetoAnalysisData(analysisDefn, analysisOptions)
      );
    })
  );
};
