import React from 'react';
import MinimizableItem from 'src/components/DraggableContainer/MinimizableItem';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './MacroSummaries.container';
import './_MacroSummaries.scss';
import MacroSummary from 'src/components/Mfp/MacroSummary/MacroSummary';
import { defaultTo, map, pickBy, keys, capitalize, mapValues } from 'lodash';
import { isRequiredDimension } from 'src/space';
import { useCallback, useEffect, useState } from 'react';
import { SelectedSpace } from 'src/components/AnchorRadioMultiSelect/AnchorCheckboxSelect';

export type MacroSummariesProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const MacroSummaries = (props: MacroSummariesProps): JSX.Element => {
  const {
    metrics,
    showMinimizableItem,
    currentAnchors,
    setSelcetedMacroAnchors,
    selectedMacroAnchors,
    anchorMembers,
  } = props;
  const reallyShowMinimizableItem = defaultTo(showMinimizableItem, true);
  // memoize dimensions/dimensionKeys?
  const dimensions = pickBy(currentAnchors, (_value, key) => isRequiredDimension(key));
  const dimensionKeys = keys(dimensions);
  const [dimensionState, setDimensionState] = useState<SelectedSpace | null>(() => {
    if (!selectedMacroAnchors || !anchorMembers || !currentAnchors) return null;
    return mapValues(anchorMembers, (v, dim) => {
      return v.map((m) => {
        // @ts-ignore
        const inc = selectedMacroAnchors[dim].some((id) => m.id === id);
        return { id: m.id, name: m.name, selected: inc };
      });
    });
  });
  useEffect(() => {
    if (!selectedMacroAnchors || !anchorMembers || !selectedMacroAnchors) return;
    const newState = mapValues(anchorMembers, (v, dim) => {
      return v.map((m) => {
        // @ts-ignore
        const inc = selectedMacroAnchors[dim].some((id) => m.id === id);
        return { id: m.id, name: m.name, selected: inc };
      });
    });
    setDimensionState(newState);
  }, [anchorMembers, currentAnchors, selectedMacroAnchors]);

  // split the metrics in half and render in rows
  const top = metrics.slice(0, metrics.length / 2);
  const bottom = metrics.slice(metrics.length / 2);

  // Check if metrics have null values, This is to show loading for macros within review-plans views
  // to prevent the macros from rendering & re-rendering when the data comes through
  const metricsHaveNullValues = top.some(obj => Object.values(obj).some(value => value === null));

  const handleDimensionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dimension = event.target.title;
      const value = event.target.value;
      if (!selectedMacroAnchors) {
        return;
      }
      // @ts-ignore
      setSelcetedMacroAnchors({ ...selectedMacroAnchors, [dimension]: [value] });
      // setDimensionState()
    },
    [selectedMacroAnchors, setSelcetedMacroAnchors]
  );

  return reallyShowMinimizableItem ? (
    <MinimizableItem
      className="macro-summaries"
      title="Plan Summary"
      style={{
        flex: 'none',
      }}
    >
      <div className="summary-checkbox">
        {map(dimensionKeys, (dimension) =>
          dimensions[dimension].length > 1 && dimensionState ? (
            <section key={dimension} className="summary-dimensions">
              <div style={{ marginRight: '1rem', fontWeight: 'bold' }}>{capitalize(dimension)}</div>
              {map(dimensionState[dimension], (member) => (
                <span key={member.id} className="summary-dimension-item">
                  <input
                    className="summary-dimension-selector"
                    type="radio"
                    id={member.id}
                    name={member.name}
                    title={dimension}
                    value={member.id}
                    onChange={handleDimensionChange}
                    checked={member.selected}
                  />
                  <label htmlFor={member.id}>{member.name}</label>
                </span>
              ))}
            </section>
          ) : null
        )}
      </div>

      <Grid columns={'equal'} stackable={true} celled="internally">
        <Grid.Row>
          {top.map((mv, index) => (
            <Grid.Column key={index} className="summary-column">
              <MacroSummary {...mv} />
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row>
          {bottom.map((mv, index) => (
            <Grid.Column key={index} className="summary-column">
              <MacroSummary {...mv} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </MinimizableItem>
  ) : (
    <div
      className="macro-summaries"
      style={{
        flex: 'none',
      }}
    >
      {!metricsHaveNullValues &&
          <Grid columns={'equal'} stackable={true} celled="internally">
            <Grid.Row>
              {top.map((mv, index) => (
                <Grid.Column key={index} className="summary-column">
                  <MacroSummary {...mv} />
                </Grid.Column>
              ))}
            </Grid.Row>
            <Grid.Row>
              {bottom.map((mv, index) => (
                <Grid.Column key={index} className="summary-column">
                  <MacroSummary {...mv} />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        }
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MacroSummaries);
