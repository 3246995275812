import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import './_Review.scss';
import {
  mapStateToProps,
  mapDispatchToProps,
  ReviewCorporatePlansDispatchProps,
} from './review-corporate-plans.container';
import { AppState } from 'src/store';
import PlanMetadataGrid, { PlanMetadataRef } from 'src/components/Mfp/PlanMetadataGrid/PlanMetadataGrid';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { gridUserModifiedByToString } from 'src/state/scope/codecs/UserDetails';
import { formatDate } from 'src/utils/Domain/time.utils';
import {
  dayJsGridFilterComparator,
  dayJsGridSortComparator,
} from 'src/components/Mfp/PlanMetadataGrid/PlanMetadataGrid.utils';
import { useGetPublishVersionsOnMount } from 'src/utils/Component/hooks/hooks';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import type { ServiceContainer } from 'src/ServiceContainer'
import { ContextMfpModule } from 'src/services/configuration/codecs/confdefn';

export interface ReviewCorporatePlansValueProps {
  currentPlanId: PlanId | null;
  currentModule: ContextMfpModule | undefined;
  publishVersions: string[] | null;
}

interface ReviewCorporatePlansOwnProps {
  serviceEnv: ServiceContainer;
}

type ReviewCorporatePlansProps = ReviewCorporatePlansValueProps &
  ReviewCorporatePlansDispatchProps &
  ReviewCorporatePlansOwnProps;

const ReviewCorporatePlans = (props: ReviewCorporatePlansProps) => {
  const { getPublishVersions, currentPlanId, publishVersions } = props;
  // TODO: fix this type as I believe this will break with react-redux > 5.x
  const gridRef = useRef<{ getWrappedInstance: () => PlanMetadataRef }>();

  // SPIKE: do we need publish versions per plan? aren't they global?
  useGetPublishVersionsOnMount(getPublishVersions, publishVersions, currentPlanId);

  const handleCopyPlanCallback = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.getWrappedInstance().refreshData();
    }
  }, []);
  return (
    <div className={'review-container'} data-qa={'review-plans'}>
      <h1 className="title">Review Corporate Plans</h1>
      <PlanMetadataGrid
        // @ts-ignore
        // ref={gridRef}
        extraColDefs={[
          {
            field: 'modifiedBy.uid',
            colId: 'modifiedBy',
            headerName: 'Approved By',
            valueGetter: gridUserModifiedByToString,
          },
          {
            field: 'createdAt',
            colId: 'createdAt',
            headerName: 'Approved At',
            valueFormatter: formatDate,
            comparator: dayJsGridSortComparator,
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: dayJsGridFilterComparator,
              newRowsAction: 'keep',
            },
          },
          {
            field: 'version',
            colId: 'version',
            headerName: 'Version Type',
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value && typeof params.value === 'string' ? params.value.toLocaleUpperCase() : ' ';
            },
          },
          {
            colId: 'copyPlan',
            headerName: 'Copy Plan',
            cellRenderer: 'clonePlanRenderer',
            cellRendererParams: {
              onClonePlan: handleCopyPlanCallback,
            },
          },
        ]}
        // we shouldn't mount this until publishVersions is ready
        extraFilters={{ version: publishVersions ? publishVersions : [] }}
      />
    </div>
  );
};

export default connect<
  ReviewCorporatePlansValueProps,
  ReviewCorporatePlansDispatchProps,
  ReviewCorporatePlansOwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {}
)(ReviewCorporatePlans);
