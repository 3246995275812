import React from 'react';
import { colorSwatchItem } from './ColorSwatch.styles';
import { Tooltip } from '@material-ui/core';

export interface ColorSwatchItemProps extends React.HTMLAttributes<{}> {
  name?: string;
  swatchPath?: string;
  id: string;
  colorId: string;
  selected?: boolean;
  noImageUrl: string;
  renderTooltip?: boolean;
}

const ColorSwatchItem = (props: ColorSwatchItemProps) => {
  const { swatchPath = props.noImageUrl, id, colorId, selected, onClick, style, renderTooltip } = props;
  const swatch = (
    <div defaultValue={id} onClick={onClick} className={colorSwatchItem(swatchPath, selected)} style={{ ...style }} />
  );

  if (!renderTooltip) {
    return swatch;
  }

  return (
    <Tooltip data-qa="swatch-tooltip" title={colorId} placement="top" enterDelay={500}>
      {swatch}
    </Tooltip>
  );
};

export default ColorSwatchItem;
