import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { Lens } from 'monocle-ts';
import { ReduxSlice as FilterSlice } from 'src/components/FilterPanel/FilterPanel.container';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { RightContainerSlice } from '../RightContainer/RightContainer.slice';
import { PrintSize } from '../higherOrder/Print/Print';
import { PlanItem } from 'src/pages/AssortmentBuild/Planning.slice';
import { CartButton } from 'src/services/configuration/codecs/confdefn';
import { QuickReconButton } from 'src/services/configuration/codecs/confdefn';
import {
  getActiveContextString,
  getActivePerspectiveCartButtonConfig,
  getActivePerspectiveQuickReconButtonConfig,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection.selectors';
import { HINDSIGHTING_CONTEXT } from 'src/utils/Domain/Constants';
import { getAssortmentCartLink } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAddBySearch/AssortmentAddBySearch.selectors';

interface ScopeKeys {
  scopeStartKey: string;
  scopeEndKey: string;
  scopeRangesKey: string;
}

export interface StateSelection extends AssortmentScopeSelectorSlice {
  filters: FilterSlice;
  cartCount: number;
  rightContainer: RightContainerSlice;
  isViewPrintable: boolean;
  cartButtonConfig: CartButton | undefined;
  quickReconButtonConfig: QuickReconButton | undefined;
  printSizes: PrintSize[];
  planTracker: PlanItem[];
  assortmentCartLink: string | undefined;
  showQuickReconcile?: boolean;
}

export interface StateProjection extends StateSelection, ScopeKeys {}

interface ScopeKeysFromContextStringSelector
  extends OutputSelector<AppState, ScopeKeys, (activeContextString: string) => ScopeKeys> {}

export const getScopeKeysFromContextString: ScopeKeysFromContextStringSelector = createSelector(
  [getActiveContextString],
  (activeContextString) => {
    let start = 'historyStart';
    let end = 'historyEnd';
    let scopeRangesKey = 'pastRangeList';

    if (activeContextString !== HINDSIGHTING_CONTEXT) {
      start = 'start';
      end = 'end';
      scopeRangesKey = 'rangeList';
    }

    return {
      scopeStartKey: start,
      scopeEndKey: end,
      scopeRangesKey,
    };
  }
);

function getCartCount(state: AppState) {
  return Lens.fromPath<AppState>()(['cart', 'cartItems']).get(state) || [];
}

function selectState(state: AppState): StateSelection {
  const { scope, print, filters, rightContainer } = state;
  const planTracker = state.planTracker.planItems || [];
  const quickReconButtonConfig = getActivePerspectiveQuickReconButtonConfig(state);
  const cartButtonConfig = getActivePerspectiveCartButtonConfig(state);
  const scopeKeys = getScopeKeysFromContextString(state);
  const assortmentCartLink = getAssortmentCartLink(state);

  return {
    ...scope,
    isViewPrintable: print.isViewPrintable,
    cartCount: getCartCount(state).length,
    filters,
    quickReconButtonConfig,
    rightContainer,
    cartButtonConfig,
    printSizes: state.appConfig.tenantConfig.printSizes,
    planTracker,
    assortmentCartLink,
    showQuickReconcile: state.quickReconcile.showQuickReconcile,
    ...scopeKeys,
  };
}

export function projectState(stateSelection: StateSelection) {
  return {
    ...stateSelection,
  };
}

export default createSelector(selectState, projectState);
