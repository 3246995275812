import React from 'react';
import { createId } from '@paralleldrive/cuid2';
import { isEmpty, isNil, noop } from 'lodash/fp';
import StarRatings from 'react-star-ratings';
import { default as styles, starDimension, starSpacing } from './StandardCard.styles';
import {
  IMGWIDTH,
  TOPHEIGHT,
  COLHEIGHT,
  COLWIDTH,
  HORPADDING,
  BORDERCLASS,
  BOTTOMHEIGHT,
  BORDERWIDTH,
} from './StandardCard.styles';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { WorklistType } from 'src/components/WorklistIcon/WorklistIcon';
import { starGoldColor } from 'src/utils/Style/Theme';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import Renderer from 'src/utils/Domain/Renderer';
import { CardViewCardColumn } from '../UIData.types';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';
import { classes } from 'typestyle';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import Adornments, { AdornmentContextOptions } from 'src/components/Adornments/Adornments';
import { ContextMenuType } from 'src/components/WorklistContextMenu/WorklistContextMenu';

export interface StandardCardProps {
  popoverTitle?: string;
  imgSrc: string;
  stars: number;
  id: string;
  name: string;
  styleName: string;
  styleId: string;
  styleColorName?: string;
  leafId?: string;
  description: string;
  styleDescription: string;
  value: number | string;
  valueRenderer: string;
  columns?: CardViewCardColumn[];
  departmentId?: string;
  swatchIds?: string[];
  onItemClick?: (item: ContainerPayload, eventTarget?: HTMLElement) => void;
  onMoreInfoClick?: (item: ContainerPayload) => void;
  isSelected?: boolean;
  stylePaneKey?: string;
  adornments: AdornmentType[];
  allowWorklistFunctionality?: boolean;
  // FIXME: only here for planogram use
  // eslint-disable-next-line @typescript-eslint/naming-convention
  worklist_type?: WorklistType;
  /** Setting to true renders a card (with no rendered image/hover styles) to fill in blank space in the cards Grid.
   *  This prevents a potential runtime error during card props lookup in ColumnGroupedView component */
  isBlankCard?: boolean;
  isPrintMode?: boolean;
}

interface State {
  popoverOpen: boolean;
  loadingImages: boolean;
  targetId: string;
}

export class StandardCard extends React.Component<StandardCardProps, State> {
  static calcCardWidth = (colItemCount: number) => {
    // Add .01 so if the number of items is 1 over the total items per col, it will create the extra column
    return (
      IMGWIDTH + 10 + (Math.ceil(colItemCount / Math.ceil(TOPHEIGHT / COLHEIGHT) + 0.01) || 1) * COLWIDTH + HORPADDING
    );
  };

  static calcCardHeight = () => {
    return TOPHEIGHT + BOTTOMHEIGHT + BORDERWIDTH * 2;
  };

  constructor(props: StandardCardProps) {
    super(props);
    this.state = {
      popoverOpen: false,
      loadingImages: false,
      targetId: createId(),
    };
  }

  getContainerPayload = (): ContainerPayload => {
    const { stylePaneKey, ...cardProps } = this.props;
    const isHindsighting = IS_HINDSIGHTING(stylePaneKey);
    const containerPayload: ContainerPayload = {
      type: isHindsighting ? RightContainerPayloadType.History : RightContainerPayloadType.Assortment,
      id: cardProps.leafId || cardProps.id,
      parentId: cardProps.styleId,
      isAssortmentBuild: !isHindsighting,
    };
    return containerPayload;
  };

  getContextOptions = (): AdornmentContextOptions => {
    const { onItemClick } = this.props;
    const containerPayload = this.getContainerPayload();
    const openStylePane = !isNil(onItemClick) ? onItemClick.bind(null, containerPayload) : noop;
    const contextOptions: AdornmentContextOptions = {
      type: ContextMenuType.card,
      parentIdentityField: 'styleId',
      onOpenStylePane: openStylePane,
      nodeData: this.props,
    };
    return contextOptions;
  };

  handleClick = () => {
    const { allowWorklistFunctionality = false, onItemClick } = this.props;
    if (onItemClick && !allowWorklistFunctionality) {
      const containerPayload = this.getContainerPayload();
      onItemClick(containerPayload);
    }
  };

  render() {
    const {
      imgSrc,
      description,
      columns,
      stars,
      value,
      valueRenderer,
      id,
      name,
      isSelected,
      adornments,
      isBlankCard,
    } = this.props;
    const renderAdornments = !isEmpty(adornments);
    const contextOptions = this.getContextOptions();
    const cardColumns = columns
      ? columns.map((column, index) => {
          const rendererFn = Renderer[column.renderer];
          const renderedValue = rendererFn ? rendererFn(column.value) : column.value;
          return (
            <div key={index} className={styles.colItem} data-qa={column.title.toLocaleLowerCase()}>
              <div className={BORDERCLASS} />
              {column.title}
              <br />
              {renderedValue}
            </div>
          );
        })
      : [];

    const rendererFn = Renderer[valueRenderer];
    const renderedValue = rendererFn ? rendererFn(value) : value;
    return (
      <div
        className={classes(styles.card, styles.getHoverStyle(isBlankCard || false))}
        style={{
          width: StandardCard.calcCardWidth(cardColumns.length),
        }}
        data-qa-component="StandardCard"
        data-qa-key={id}
        onClick={this.handleClick}
      >
        {renderAdornments && <Adornments adornments={adornments} productId={id} contextOptions={contextOptions} />}
        {isSelected && (
          <React.Fragment>
            <div className={styles.cardOverlay} />
            <i className={`fas fa-check-circle ${styles.iconOverlay}`} />
          </React.Fragment>
        )}
        <div id={this.state.targetId} data-qa-action="CardClick" className={styles.infoContainer}>
          <CenteredImage src={imgSrc} width={IMGWIDTH} height={TOPHEIGHT} extraImgProps={{ isBlankCard }} />
          {cardColumns.length > 0 && (
            <div data-qa-component="CardColumns" className={styles.column}>
              {cardColumns}
            </div>
          )}
        </div>
        <div className={styles.infoRow}>
          <div className={styles.stars}>
            {!isNil(stars) && (
              <StarRatings
                rating={stars}
                numberOfStars={5}
                starDimension={starDimension}
                starSpacing={starSpacing}
                starRatedColor={starGoldColor}
              />
            )}
          </div>
          <div className={styles.value}>{renderedValue}</div>
        </div>
        <div>
          <div className={styles.description}>{name}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    );
  }
}
