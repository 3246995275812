import { AppState } from 'src/store';
import { createSelector, OutputSelector, OutputParametricSelector } from 'reselect';
import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { parseActions, parseFloorsetDropdownData } from './utils/ConfigurableGrid.utils';
import {
  ClientActionHandler,
  ClientActionHandlersConfig,
  ConfigurableGridColumnDef,
  ConfigurableGridOwnProps,
} from './ConfigurableGrid.types';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import { cloneDeep, findIndex, get, isEmpty, isNil } from 'lodash';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { filterData } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';
import { flattenToLeaves } from 'src/utils/Pivot/Flatten';
import { AgFlatResult } from 'src/utils/Component/AgGrid/AgDataFormat';
import { generateOrderedGroups } from 'src/utils/Component/AgGrid/AgDataFormat';
import { groupedToAgFlatTree } from 'src/utils/Component/AgGrid/AgDataFormat';
import { GroupBySlice, initialSortBy } from '../Subheader/Subheader.slice';
import { ConfigurableGridViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';

export interface SelectorSubheaderDropdownProps extends Omit<SubheaderDropdownProps, 'handleChangeOnDropdown'> {}

interface ViewDefnData {
  dependentCalcs: Record<string, any>;
  companionSortOptions: SortOption[];
  defaultCompanionSortField?: string;
  clientActionHandlers: ClientActionHandler;
}

export interface ConfigurableGridDataSelectorProps {
  showFlowStatus: boolean;
  leafId: string;
}

interface ViewDefnDataSelector
  extends OutputSelector<
    AppState,
    ViewDefnData,
    (columnDefs: ConfigurableGridColumnDef[], sortConfig: any, actions: ClientActionHandlersConfig) => ViewDefnData
  > {}

interface GroupByDropdownPropsSelector
  extends OutputSelector<
    AppState,
    SelectorSubheaderDropdownProps | undefined,
    (
      groupBySelection: ConfigurableGridGroupBySelection | undefined,
      dropdownConfig: SelectorSubheaderDropdownProps | undefined
    ) => SelectorSubheaderDropdownProps | undefined
  > {}

interface FloorsetDropdownPropsSelector
  extends OutputSelector<
    AppState,
    SelectorSubheaderDropdownProps | undefined,
    (
      floorsets: BasicItem[],
      selectedFloorset: string | undefined,
      viewDefn: ConfigurableGridViewDefn
    ) => SelectorSubheaderDropdownProps | undefined
  > {}

interface GridDataSelector
  extends OutputParametricSelector<
    AppState,
    ConfigurableGridDataSelectorProps,
    BasicPivotItem[],
    (
      gridData: BasicPivotItem[],
      showFlowStatus: boolean,
      flowStatus: number[],
      search: string,
      viewDefn: any
    ) => BasicPivotItem[]
  > {}

interface GridGroupedDataSelector
  extends OutputParametricSelector<
    AppState,
    ConfigurableGridDataSelectorProps,
    AgFlatResult | undefined,
    (
      gridData: BasicPivotItem[],
      showFlowStatus: boolean,
      flowStatus: number[],
      leafId: string,
      search: string,
      viewDefn: any,
      groupByDropdownProps: SelectorSubheaderDropdownProps
    ) => AgFlatResult | undefined
  > {}

const getColumnDefs = (state: AppState): ConfigurableGridColumnDef[] | undefined => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.columnDefs;
};

const getCompanionSortConfig = (state: AppState) => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.companionGrid.sortConfig;
};

const getActions = (state: AppState): ClientActionHandlersConfig | undefined => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.actions;
};

const getGroupBySelection = (state: AppState) => {
  return state.pages.assortmentBuild.configurableGrid.groupBySelection;
};

const getGroupByDropdownConfig = (state: AppState): SelectorSubheaderDropdownProps | undefined => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.subheaderDropdowns.groupBy;
};

export const getFloorsetData = (state: AppState): BasicItem[] => {
  return state.pages.assortmentBuild.configurableGrid.floorsetData || [];
};

const getSelectedFloorset = (state: AppState): string | undefined => {
  return state.pages.assortmentBuild.configurableGrid.floorsetSelection;
};

const getGridData = (state: AppState): BasicPivotItem[] => {
  return state.pages.assortmentBuild.configurableGrid.gridData;
};

const getShowFlowStatus = (_state: AppState, { showFlowStatus }: ConfigurableGridDataSelectorProps): boolean => {
  return showFlowStatus;
};

const getFlowStatus = (state: AppState): number[] => {
  return state.subheader.flowStatus;
};

const getSearch = (state: AppState): string => {
  return state.subheader.search;
};

export const getViewDefn = (state: AppState): any => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn;
};

const getLeafId = (state: AppState, { leafId }: ConfigurableGridDataSelectorProps) => {
  return leafId;
};

export const getLeafIdentifier = (ownProps: ConfigurableGridOwnProps) => {
  return ownProps.keys.leafIdProp ? ownProps.keys.leafIdProp : ownProps.keys.idProp;
};

export const getViewDefnData: ViewDefnDataSelector = createSelector(
  getColumnDefs,
  getCompanionSortConfig,
  getActions,
  (columnDefs, sortConfig, actions) => {
    // generate dependent calc object
    const dependentCalcs = {};
    if (!isNil(columnDefs)) {
      columnDefs.forEach((colDef) => {
        if (colDef.dependentCalc) {
          dependentCalcs[colDef.dataIndex] = colDef.dependentCalc;
        }
      });
    }

    // generate companion view props
    const companionSortOptions = (sortConfig?.view || []) as SortOption[];
    const companionSortSelected: SortOption = companionSortOptions.find(
      (opt) => opt.dataIndex === sortConfig?.default
    ) ||
      companionSortOptions[0] || { dataIndex: 'id', text: 'ID' };
    const defaultCompanionSortField = companionSortSelected.dataIndex;

    // generate actionHandlers
    const clientActionHandlers = !isNil(actions) ? parseActions(actions) : {};
    return {
      dependentCalcs,
      companionSortOptions,
      defaultCompanionSortField,
      clientActionHandlers,
    };
  }
);

export const getGroupByDropdownProps: GroupByDropdownPropsSelector = createSelector(
  getGroupBySelection,
  getGroupByDropdownConfig,
  (groupBySelection, dropdownConfig) => {
    if (isNil(dropdownConfig)) {
      return;
    }

    const configCopy = cloneDeep(dropdownConfig);
    configCopy.selection = groupBySelection?.selectedIndex || dropdownConfig.defaultSelection;
    return configCopy;
  }
);

export const getFloorsetDropdownProps: FloorsetDropdownPropsSelector = createSelector(
  getFloorsetData,
  getSelectedFloorset,
  getViewDefn,
  (floorsets, selectedFloorset, viewDefn: ConfigurableGridViewDefn | undefined) => {
    if (isEmpty(floorsets)) {
      return;
    }

    const options = parseFloorsetDropdownData(floorsets);
    const selection = isNil(selectedFloorset) ? 0 : findIndex(options, (option) => option.text === selectedFloorset);
    const label = viewDefn?.subheaderDropdowns.topMember?.label || 'Floorset';
    const floorsetDropdownProps: SelectorSubheaderDropdownProps = {
      label,
      defaultSelection: 0,
      selection,
      options,
    };

    return floorsetDropdownProps;
  }
);

export const getConfigurableGridData: GridDataSelector = createSelector(
  getGridData,
  getShowFlowStatus,
  getFlowStatus,
  getSearch,
  getViewDefn,
  (gridData, showFlowStatus, flowStatus, search, viewDefn) => {
    const finalFlowStatus = showFlowStatus ? flowStatus : [];
    const configSearchOrDefafult = get(viewDefn, 'searchIndexes', externalGridSearchFields) as string[];
    return filterData(gridData, search, configSearchOrDefafult, finalFlowStatus);
  }
);

export const getGroupedConfigurableGridData: GridGroupedDataSelector = createSelector(
  getGridData,
  getShowFlowStatus,
  getFlowStatus,
  getLeafId,
  getSearch,
  getViewDefn,
  getGroupByDropdownProps,
  (gridData, showFlowStatus, flowStatus, leafId, search, viewDefn, groupByDropdownProps) => {
    if (isNil(groupByDropdownProps) || isNil(groupByDropdownProps.selection)) {
      return undefined;
    }

    const configSearchOrDefafult = get(viewDefn, 'searchIndexes', externalGridSearchFields) as string[];
    const selectedGroupBy = groupByDropdownProps.options[groupByDropdownProps.selection];
    const groupByKey = selectedGroupBy.dataIndex;
    const finalFlowStatus = showFlowStatus ? flowStatus : [];
    const filteredGridData = filterData(gridData, search, configSearchOrDefafult, finalFlowStatus);
    const flatData = flattenToLeaves(filteredGridData);
    const groupedItems = generateOrderedGroups(flatData, groupByDropdownProps as GroupBySlice);
    const result = groupedToAgFlatTree(
      groupedItems,
      groupByKey,
      search,
      initialSortBy,
      flowStatus,
      groupByDropdownProps.options,
      leafId === 'id' ? 'name' : `${leafId}`,
      true
    );

    // add in asyncstate to grouped rows for correct rendering
    const agFlatTree = result.agFlatTree.map((item) => {
      return !item.hasOwnProperty('asyncstate') ? { ...item, asyncstate: {} } : item;
    });

    return { ...result, agFlatTree };
  }
);
