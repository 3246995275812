import { AppState, AppThunkDispatch } from 'src/store';
import {
  forceRefreshGrid,
  getScope,
  newScope,
  resetScope,
  deleteScope
} from '../../../state/scope/Scope.actions';
import './_Scopebar.scss';
import { ScopebarValueProps } from './MfpScopebar.types';
import { DropdownItemProps } from 'semantic-ui-react';
import { Workflow, ScopeMemberInfo, SCOPECREATE_WITH_WP, TopMembers } from '../../../services/Scope.client';
import type { ScopeCreateRequest } from 'src/services/Scope.client';
import {
  TIME,
} from '../../../utils/Domain/Constants';
import {
  getScopeReadyData,
  getScopeId,
  getTopMembers
} from '../../../state/scope/Scope.types';
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import { setRightContainerPayload, toggleRightContainer } from 'src/components/RightContainer/RightContainer.slice';
import { isEmpty } from 'lodash';
import { RightContainerPayloadType } from '../../RightContainer/RightContainer';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { toggleMfpScopeSelector } from 'src/state/ViewConfig/ViewConfig.slice';
import ServiceContainer from 'src/ServiceContainer';


export function mapStateToProps(state: AppState): ScopebarValueProps {
  const readyScope = getScopeReadyData(state.mfpScope);
  const id = getScopeId(state.mfpScope);
  const labelDimensions = state.settings.dimensionLabelProperty;
  const currentModule = getMfpModule(state)?.id;

  // TODO: Revisit making ScopebarValueProps a single property contining an optional fusion
  if (!readyScope || !id) {
    // Instead, we will simply attempt to produce sane defaults as an intermediate
    return {
      selectedMembers: undefined,
      // ScopePending can return a scope id with no data while the rest of the scope loads
      // we need the id to remain stable in these cases to maintain various loading spinner states
      scopeId: id,
      availableMembers: state.viewConfigSlice.availableMembers,
      inSeason: state.viewConfigSlice.inSeason,
      scopeConfig: undefined,
      balanceOptions: [],
      initialized: false,
      module: currentModule,
      settingsByKey: state.settings.entriesByKey,
      isFetchingScope: true,
      hasEditableRevision: false,
      scopeReady: false,
      scopeContexts: state.workingSets.contexts.filter((ws) => ws.initParams.type === SCOPECREATE_WITH_WP),
      commentsOpen: state.rightContainer.payload?.type === RightContainerPayloadType.Comments && state.rightContainer.open,
      anyPlansUnititialized: false,
      labelDimensions,
      pendingWrites: -1,
    };
  }

  const { balanceOptions } = readyScope;
  const hasEditableRevision = readyScope.hasEditableRevision;

  const availableMembers = state.viewConfigSlice.availableMembers;
  return {
    selectedMembers: getTopMembers(readyScope),
    scopeId: id,
    availableMembers: availableMembers,
    inSeason: state.viewConfigSlice.inSeason,
    module: currentModule,
    scopeConfig: readyScope.mainConfig,
    balanceOptions: balanceOptions,
    timeMembers: availableMembers ? availableMembers.space[TIME].map(memberToDropdown) : [],
    initialized: readyScope.initialized,
    settingsByKey: state.settings.entriesByKey,
    isFetchingScope: readyScope.isFetching,
    hasEditableRevision: hasEditableRevision,
    // TODO: Eliminate this at some point
    scopeReady: true, // We are ready enough if we got have ScopeReadyData
    scopeContexts: state.workingSets.contexts.filter((ws) => ws.initParams.type === SCOPECREATE_WITH_WP),
    commentsOpen: state.rightContainer.payload?.type === RightContainerPayloadType.Comments && state.rightContainer.open,
    anyPlansUnititialized: !isEmpty(readyScope.mainConfig.uninitializedPlans!),
    labelDimensions,
    pendingWrites: readyScope.pendingWrites,

  };
}

// Scope member's have id strings, seeds have id numbers
type memberSlice = Pick<ScopeMemberInfo, 'id' | 'name'> | Pick<PlanMetadata, 'id' | 'name'>;

export const memberToDropdown = (member: memberSlice): DropdownItemProps => {
  return { text: member.name, value: member.id };
};

export const sortByCreatedAt = (lMem: PlanMetadata, rMem: PlanMetadata) => {
  return rMem.createdAt.unix() - lMem.createdAt.unix();
};

export function narrowWorkflow(workflow: string): Workflow {
  if (workflow === 'in-season' || workflow === 'pre-season') {
    return workflow;
  }
  return 'pre-season';
}

export const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
) => {
  return {
    onAcceptScope: (topMembers: TopMembers, workflow: string) => {
      const request: ScopeCreateRequest = {
        initParams: { type: 'with-wp' },
        workflow: narrowWorkflow(workflow),
        anchor: topMembers
      };
      return dispatch(() => {
        return dispatch<Promise<unknown>>(newScope(ServiceContainer.axios, request));
      });
    },
    updateScopeInfo: (scopeId: string, module?: string) => {
      return dispatch(getScope({ scopeId, module }));
    },
    onforceRefreshGrid: () => dispatch(forceRefreshGrid()),
    clearScope: () => dispatch(resetScope()),
    toggleRightContainer: () => {
      dispatch(setRightContainerPayload({ type: RightContainerPayloadType.Comments }));
      dispatch(toggleRightContainer());
    },
    deleteScope: (scopeId: string) => {
      dispatch(deleteScope(scopeId));
    },
    handleToggleMfpScopeSelector: () => dispatch(toggleMfpScopeSelector())
  };
};
