import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { isNil, orderBy } from 'lodash';
import { Tooltip, Popper, ClickAwayListener, makeStyles, createStyles } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { AppState } from 'src/store';
import { AdornmentType, AdornmentsEnum } from 'src/services/configuration/codecs/viewdefns/literals';
import { WorklistIcon, WorklistType, worklistStatusMap } from 'src/components/WorklistIcon/WorklistIcon';
import { WorklistInfo } from 'src/worker/pivotWorker.types';
import { PlanItem, PlanItemStatus } from 'src/pages/AssortmentBuild/Planning.slice';
import { getWorklistContextMenuItems, ContextMenuType } from 'src/components/WorklistContextMenu/WorklistContextMenu';
import { WorklistContextMenu } from 'src/components/WorklistContextMenu/WorklistContextMenuReact';
import { ID } from 'src/utils/Domain/Constants';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const useStyles = makeStyles((_theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 'fit-content',
      textAlign: 'center',
    },
    exceptionRoot: {
      fontSize: '1.35rem',
      marginRight: '0.1rem',
    },
    worklistRoot: {
      fontSize: '1.2rem',
      paddingRight: '0.15rem',
    },
    popper: {
      border: '1px solid rgba(0,0,0,.2)',
      borderRadius: '10px',
      maxWidth: '1000px',
      backgroundColor: 'white',
    },
  })
);

interface LegacyAdornments {
  allowWorklistFunctionality?: boolean;
}

export interface AdornmentContextOptions {
  type: ContextMenuType;
  parentIdentityField: 'style' | 'styleId';
  nodeData: unknown;
  onOpenStylePane: () => void;
}

export interface AdornmentsOwnProps {
  productId: string;
  adornments: AdornmentType[];
  contextOptions?: AdornmentContextOptions;
  /**
   * removes the pointer style from the worklist adornment
   * since grids interact with worklist items differently */
  interactive?: boolean;
}

interface AdornmentsProps extends AdornmentsOwnProps {
  worklist: WorklistInfo[];
  planItems: PlanItem[];
  exceptions: never[];
}

function mapStateToProps(state: AppState, ownProps: AdornmentsOwnProps): AdornmentsProps {
  const worklist = state.pages.hindsighting.styleColorReview.sharedData.worklist;
  const filterPlanItems = state.planTracker.planItems.filter((item) => {
    return item.status !== PlanItemStatus.Completed && item.status !== PlanItemStatus.Failed;
  });
  return {
    ...ownProps,
    worklist,
    planItems: filterPlanItems,
    exceptions: [],
  };
}

// in case multiple adornments are present, worklist first since it is interactive
const ADORNMENT_WEIGHTS = {
  [AdornmentsEnum.worklist]: 0,
  [AdornmentsEnum.exception]: 1,
  [AdornmentsEnum.planning]: 2,
};

const Adornments = (props: AdornmentsProps) => {
  const { adornments, productId, contextOptions, interactive, worklist, planItems, exceptions } = props;
  const styles = useStyles();
  const anchorRef = useRef(null);
  const [isPopperOpen, setPopperOpen] = useState(false);
  const openPopper = () => setPopperOpen(true);
  const closePopper = () => setPopperOpen(false);
  const orderedAdornments = orderBy(adornments, (a) => ADORNMENT_WEIGHTS[a]);
  return (
    <React.Fragment>
      <div data-qa="adornments-container" className={styles.container}>
        {orderedAdornments.map((adornment) => {
          switch (adornment) {
            case AdornmentsEnum.worklist: {
              const item = worklist.find((item) => item.product === productId);
              if (isNil(item)) {
                return null;
              }
              const type = item.type;
              const status = `Worklist status - ${worklistStatusMap[type]}`;
              return (
                <Tooltip key={`worklist-${productId}`} title={status}>
                  <span
                    className={`${styles.worklistRoot} worklistAdornment`}
                    style={{
                      marginLeft: contextOptions && contextOptions.type === ContextMenuType.stylePreview ? '0.3rem' : 0,
                    }}
                    ref={anchorRef}
                    onClick={openPopper}
                  >
                    <WorklistIcon icon={type as WorklistType} interactive={interactive} />
                  </span>
                </Tooltip>
              );
            }
            case AdornmentsEnum.exception: {
              const item = exceptions.find((item: unknown) => item === productId);
              if (isNil(item)) {
                return null;
              }
              return (
                <Tooltip key={`exception-${productId}`} title={`This item has exceptions`}>
                  <WarningRoundedIcon color="error" className={styles.exceptionRoot} />
                </Tooltip>
              );
            }
            case AdornmentsEnum.planning:
              const item = planItems.find((item) => item.id === productId);
              if (isNil(item)) {
                return null;
              }
              return (
                <Tooltip key={`planning-${productId}`} title={`This item has pending unplanned changes`}>
                  <FiberManualRecordIcon color="secondary" fontSize="medium" style={{ color: TEAL_PRIMARY }} />
                </Tooltip>
              );
            default:
              return <div>Unknown adornment configured</div>;
          }
        })}
      </div>
      {contextOptions && (
        <Popper open={isPopperOpen} anchorEl={anchorRef.current} placement="bottom-start" className={styles.popper}>
          <ClickAwayListener onClickAway={closePopper}>
            <div>
              <WorklistContextMenu
                worklistContextMenuItems={getWorklistContextMenuItems(
                  {
                    identityField: ID,
                    parentIdentityField: contextOptions.parentIdentityField,
                    type: contextOptions.type,
                    openStylePane: contextOptions.onOpenStylePane,
                    onPostAction: closePopper,
                  },
                  {
                    // @ts-ignore - check this? wouldn't allow this assignment because it was missing all the IRowNodeProperties
                    node: { data: contextOptions.nodeData },
                  }
                )}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(Adornments);
