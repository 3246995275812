import {
  SCOPETYPE_ACTUALS,
  SCOPETYPE_PLAN,
  SCOPETYPE_SUBMITTED,
  SCOPETYPE_APPROVED,
  SCOPETYPE_WORKING,
  TIME,
  LOCATION,
  PRODUCT,
} from '../../utils/Domain/Constants';
import { TopMembers } from '../../services/Scope.client';
import { PRODLIFE } from 'src/utils/Domain/Constants';
import { getPrimary } from 'src/components/Mfp/PivotConfigurator/utils';
import { zSpace } from 'src/space';
import { Dictionary, findIndex, map } from 'lodash';
import { PlanMetadata } from './codecs/PlanMetadata';
import { CONTEXT_READY, CONTEXT_PENDING, CONTEXT_FAILED, CONTEXT_BUSY } from 'src/state/workingSets/workingSets.types';
import { z } from 'zod';
import { CommandDetail } from './codecs/Commands';

export type DimString = typeof TIME | typeof PRODUCT | typeof LOCATION | typeof PRODLIFE;
export type ScopeRoot = {
  // there are 4 dimensions, add them if you need them
  [key in DimString]: string;
};

// eslint-disable-next-line max-len
export type ScopeType =
  | typeof SCOPETYPE_ACTUALS
  | typeof SCOPETYPE_PLAN
  | typeof SCOPETYPE_SUBMITTED
  | typeof SCOPETYPE_APPROVED
  | typeof SCOPETYPE_WORKING;

const zFormats = z
  .object({
    standard: z.string(),
    summary: z.string(),
    excelDataCell: z.string(),
    variancePercentGrid: z.string(),
    percentTotalGrid: z.string(),
    percentGrandTotalGrid: z.string(),
  })
  .partial();

export interface Formats extends z.infer<typeof zFormats> { }

const zServerMetric = z.object({
  id: z.string(),
  name: z.string(),
  units: z.union([z.literal('monetary'), z.literal('bare'), z.literal('percent')]),
  hidden: z.boolean(), // this becomes ConfigItem.always hidden, because they should never show up
  formats: z.union([zFormats, z.literal(undefined)]),
  editable: z.boolean(),
  group: z.string(),
});

export interface ServerMetric extends z.infer<typeof zServerMetric> { }

export const zServerScopeMember = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  level: z.string(),
});

export interface ServerScopeMember extends z.infer<typeof zServerScopeMember> { }

export interface ServerSeedOptions {
  seedPeriod: string;
  seedVersion: string;
  seedSource: string;
  seedVersionLabel: string;
}
export interface ServerSeed {
  module: string;
  period: string;
  seedOptions: ServerSeedOptions[];
}

export interface PersistMessage {
  [scopeId: string]: PersistStatus;
}

export interface PersistStatus {
  inflight: number;
  previousError: string | undefined;
}
export function makeTree<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    v: schema,
    children: z.lazy(() => {
      return schema.array();
    }),
  });
}

export const SCOPE_READY = 'ScopeReady';
export const SCOPE_NOT_READY = 'ScopeNotReady';

export const zScopeStatus = z.union([
  z.literal(CONTEXT_READY),
  z.literal(CONTEXT_PENDING),
  z.literal(CONTEXT_FAILED),
  z.literal(CONTEXT_BUSY),
]);

const zServerScopeResponseBase = z.object({
  id: z.string(),
  status: zScopeStatus,
});

export function zHierDataOf<T>(of: z.ZodType<T>): Dictionary<HierData<T>> {
  // @ts-ignore
  return z.object({
    id: z.string(),
    primary: z.boolean(),
    data: of,
  });
}

export interface HierData<T> {
  id: string;
  primary: boolean;
  data: T;
}

export const zLevel = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
});

const hasHidden = z.object({ hidden: z.boolean() }).partial();
const versionName = z.object({ version: z.string() });
const zVersionSingleType = z
  .object({
    type: z.union([z.literal('SingleVersion'), z.literal('PercentToTotal'), z.literal('PercentToGrandTotal')]),
  })
  .merge(hasHidden)
  .merge(versionName);
const zVersionCompositeType = z
  .object({
    type: z.literal('VarianceVersion'),
    version: z.string(),
    against: z.string(),
    varType: z.union([z.literal('delta'), z.literal('percentage')]),
  })
  .merge(hasHidden);

export const zRevision = z.union([zVersionSingleType, zVersionCompositeType]);

interface ServerScopeMemberTree {
  v: ServerScopeMember;
  children: ServerScopeMemberTree[];
}
const zServerScopeMemberTree: z.ZodType<ServerScopeMemberTree> = z.object({
  v: zServerScopeMember,
  children: z.lazy(() => zServerScopeMemberTree.array()),
});

const zServerReady = z.object({
  type: z.literal(SCOPE_READY),
  scopeReady: z.literal(true),
  inSeason: z.string(),
  initialized: z.boolean(),
  // These 3 are spaces each containing hierarchy id maps to the data on that hierarchy
  // i.e. levels: each dimension has some hierarchies each of which contains levels
  // todo... implement something like 'non-empty record'
  // @ts-ignore
  levels: zSpace(z.array(zHierDataOf(z.array(zLevel)))),
  memberTrees: zSpace(
    z.array(
      z.object({
        id: z.string(),
        primary: z.boolean(),
        data: z.array(zServerScopeMemberTree),
      })
    )
  ),
  members: zSpace(z.array(zServerScopeMember)),
  metrics: z.array(zServerMetric),
  revisions: z.array(zRevision),
  workflow: z.union([z.literal('in-season'), z.literal('pre-season'), z.null()]),
  initializedPlans: z.array(PlanMetadata),
  uninitializedPlans: z.array(PlanMetadata),
});

const zServerScope = zServerScopeResponseBase.merge(zServerReady);

export interface ServerScope extends z.infer<typeof zServerScope> { }

const zServerNotReady = z.object({
  type: z.literal(SCOPE_NOT_READY),
  scopeReady: z.literal(false),
});

const zServerScopeNotReady = zServerScopeResponseBase.merge(zServerNotReady);

export interface ServerScopeNotReady extends z.infer<typeof zServerScopeNotReady> { }

export const zServerScopeResponse = z.discriminatedUnion('type', [zServerScope, zServerScopeNotReady]);

export type ServerScopeResponse = z.infer<typeof zServerScopeResponse>;

// TODO: Remove the boolean
export const isScopeReady = (scope: ServerScopeResponse | undefined | null): scope is ServerScope => {
  return scope ? scope.scopeReady === true : false;
};
export const isScopeNotReady = (scope: ServerScopeResponse | undefined | null): scope is ServerScopeNotReady => {
  return scope ? scope.scopeReady === false : false;
};

export const GRID_SAVING = 'Saving...' as const;
export const GRID_SAVED = 'Saved' as const;
export const GRID_REFRESHING = 'Refreshing...' as const;
export const GRID_REFRESHED = 'Refreshed' as const;
export const GRID_ERROR = 'An error has occured' as const;
export const GRID_DEFAULT = ' ' as const;

export type GridAsyncState =
  | typeof GRID_SAVING
  | typeof GRID_SAVED
  | typeof GRID_REFRESHING
  | typeof GRID_REFRESHED
  | typeof GRID_ERROR
  | typeof GRID_DEFAULT; // length one string is the initial state

/**
 * The type o possible scope states
 */
export enum ScopeStateTag {
  // The context has been requested but we do not yet have an id
  // No data is yet available
  Empty,
  // The context has been requested and the server is initializing
  // Minimal data is available beyond an id
  Pending,
  // The context has entered a failed state
  // Some date may be available and this may be recoverable
  Failed,
  // The context is currently processing a request
  // Data is available
  Busy,
  // The context is ready for all operations
  // Data is available
  Ready,
}

export interface ScopeEmpty {
  _tag: ScopeStateTag.Empty;
}

export const scopeEmpty: ScopeEmpty = {
  _tag: ScopeStateTag.Empty,
} as const;

export interface ScopePending {
  _tag: ScopeStateTag.Pending;
  id: string;
  isFetching: true;
}

export function scopePending(id: string): ScopePending {
  return {
    _tag: ScopeStateTag.Pending,
    isFetching: true,
    id,
  } as const;
}

export interface ScopeFailed {
  _tag: ScopeStateTag.Failed;
  id: string;
  // We possibly have stale data
  previous: ScopeReadyData | undefined;
}

export function scopeFailed(id: string, previous: ScopeReadyData | undefined = undefined): ScopeFailed {
  return {
    _tag: ScopeStateTag.Failed,
    id,
    previous,
  } as const;
}

export interface ScopeReady {
  _tag: ScopeStateTag.Ready;
  id: string;
  isFetching: boolean;
  isMultiScope: boolean;
  inSeason: string;
  mainConfig: ServerScope;
  currentAnchors: TopMembers | undefined;
  initialized: boolean;
  forceRefreshGrid: boolean;
  hasEditableRevision: boolean;
  gridAsyncState: GridAsyncState;
  pendingWrites: number;
  hasLocks: boolean;
  commands: CommandDetail[];
  balanceOptions: Record<number, CommandDetail[]>; // TODO: just make this default to empty set
  importOptions: Record<number, CommandDetail[]>;
  overlayOptions: Record<number, string[]>;
  seedOptions: Record<number, CommandDetail[]>; // TODO: just make this default to empty set
  persistErrorStatus: string | undefined;
  message: string | undefined; // not always a message present
  lastDynamicId?: string;
}

// For cases when we are in busy or failed and may have some historical data
export type ScopeReadyData = Omit<ScopeReady, 'id' | '_tag'>;

export function scopeReady(id: string, body: ScopeReadyData): ScopeReady {
  return {
    ...body,
    _tag: ScopeStateTag.Ready,
    id,
  } as const;
}

export interface ScopeBusy {
  readonly _tag: ScopeStateTag.Busy;
  readonly id: string;
  readonly previous: ScopeReadyData;
}

export function scopeBusy(id: string, previous: ScopeReadyData): ScopeBusy {
  return {
    _tag: ScopeStateTag.Busy,
    id,
    previous,
  } as const;
}

export type ScopeStateUnion = ScopeEmpty | ScopePending | ScopeFailed | ScopeReady | ScopeBusy;

export function isEmpty(s: ScopeStateUnion): s is ScopeEmpty {
  return s._tag === ScopeStateTag.Empty;
}

export function isPending(s: ScopeStateUnion): s is ScopePending {
  return s._tag === ScopeStateTag.Pending;
}

export function isFailed(s: ScopeStateUnion): s is ScopeFailed {
  return s._tag === ScopeStateTag.Failed;
}

export function isReady(s: ScopeStateUnion): s is ScopeReady {
  return s._tag === ScopeStateTag.Ready;
}

export function isBusy(s: ScopeStateUnion): s is ScopeBusy {
  return s._tag === ScopeStateTag.Busy;
}

export function hasScopeId(s: ScopeStateUnion): s is ScopeReady | ScopeBusy | ScopePending {
  return isReady(s) || isBusy(s) || isPending(s);
}

/**
 * Attempt to manipulate the scope ready data.
 *
 * This is a no-op if there is no ScopeReadyData extractable from the current input
 * @param f
 */
export function modifyScopeData(u: ScopeStateUnion, mapper: (srd: ScopeReadyData) => ScopeReadyData): ScopeStateUnion {
  if (isReady(u)) {
    return { ...u, ...mapper(u) };
  } else if (isBusy(u)) {
    return { ...u, previous: mapper(u.previous) };
  } else if (isFailed(u) && u.previous) {
    return { ...u, previous: mapper(u.previous) };
  }
  return u;
}

export function modifyScopeDataWithId(
  u: ScopeStateUnion,
  mapper: (id: string, srd: ScopeReadyData) => ScopeReadyData
): ScopeStateUnion {
  if (isReady(u)) {
    return { ...u, ...mapper(u.id, u) };
  } else if (isBusy(u)) {
    return { ...u, previous: mapper(u.id, u.previous) };
  } else if (isFailed(u) && u.previous) {
    return { ...u, previous: mapper(u.id, u.previous) };
  }
  return u;
}

export function getScopeReadyData(u: ScopeStateUnion): ScopeReadyData | undefined {
  if (isReady(u)) {
    return u;
  } else if (isFailed(u) || isBusy(u)) {
    // should re return on failed here, or kill the app elsewhere?
    return u.previous;
  }
  return undefined;
}

export function getScopeId(u: ScopeStateUnion): string | undefined {
  if (isEmpty(u)) {
    return;
  }
  return u.id;
}

/**
 * Extract the top member id from a server scope
 * @param scope
 */
export function getTopMembers(scope: ScopeReadyData): TopMembers {
  return (map(scope.mainConfig.memberTrees, (v, k) => {
    // @ts-ignore
    return getPrimary(v).map((hier) => hier.v.id);
  }) as unknown) as TopMembers;
}

export const isValidPerspectivePath = (path: string): path is string => {
  // TODO: make this read from the modules file and check
  return typeof path === 'string';
};

export const maybeGetCurrentScopeTimeId = (scope: ScopeReady | ScopeBusy | ServerScope): string => {
  if ('_tag' in scope) {
    return isReady(scope)
      ? scope.mainConfig.memberTrees.time[0].data[0].v.id
      : scope.previous.mainConfig.memberTrees.time[0].data[0].v.id;
  }
  return scope.memberTrees.time[0].data[0].v.id;
};
