import { DropdownItemProps, StrictDropdownItemProps } from 'semantic-ui-react';
import { Command, CommandAnchor, CommandImport } from '../Commands';
import { isCommandAnchor } from '../Commands.utils';
import { get, startCase } from 'lodash';
import { SettingsByKey } from 'src/services/Settings';

export interface StricterDropdownItemProps extends StrictDropdownItemProps {
  value: string;
  command: Command;
}

export const commandToKey = (c: CommandAnchor | CommandImport): string => {
  return isCommandAnchor(c) ? `${c.type}${c.displaySource}${c.displayTime}` : `${c.type}${c.displaySource}`;
};

export const keyToCommand = (key: string, commands: Command[]): Command | undefined => {
  return commands.find((c) => {
    return isCommandAnchor(c)
      ? `${c.type}${c.displaySource}${c.displayTime}` === key
      : `${c.type}${c.displaySource}` === key;
  });
};

export const commandToTimeDropdown = (c: Command): StricterDropdownItemProps => {
  return {
    text: 'displayTime' in c ? c.displayTime : c.displaySource,
    value: commandToKey(c),
    command: c,
  };
};

export const commandToDisplayDropdown = (c: Command, entries?: SettingsByKey): DropdownItemProps => {
  return {
    // look up a match in settings, or default to the displaySource
    text: get(entries, `revision.${c.displaySource}.display`)?.value || startCase(c.displaySource),
    value: commandToKey(c),
    command: c
  };
};
