import * as React from 'react';
import * as _ from 'lodash';
import Axios from 'src/services/axios';
import { reduce, find, sortBy, isNil, findIndex, defaultTo, get, isNaN } from 'lodash';
import { isEmpty, sum } from 'lodash/fp';
import ServiceContainer from 'src/ServiceContainer';

import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { BasicItem } from 'src/types/Scope';
import { InputInteger } from 'src/common-ui/index';
import {
  InputIntegerClass,
  ReceiptStyles as styles,
  MUIStyles,
  REC_ADJ_COLUMN_WIDTH,
} from './ReceiptsAdjCalculator.styles';
import { addEventListenerForEditors } from './Editors.utils';
import CardActions from '@material-ui/core/CardActions';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';
import Renderer from 'src/utils/Domain/Renderer';

import { ICellEditorParams } from '@ag-grid-community/core';

import { Overlay } from 'src/common-ui/index';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import { MuiThemeProvider, Tabs } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';

export interface ReceiptsAdjCalculatorProps extends ICellEditorParams {
  dataApi: ClientDataApi;
  configApi: ClientDataApi;
  floorset?: string;
  isEditable: boolean;
}

export interface PercentageRuleHigherLower {
  percentageRule: 'HigherLower';
  referenceColumns: {
    [col: string]: {
      higher: string;
      lower: string;
    };
  };
}

export interface PercentageRules extends PercentageRuleHigherLower {}

interface SimpleReceiptConfig {
  columns: {
    text: string;
    dataIndex: string;
    renderer?: string;
  }[];
  dataRules?: {
    percentage: PercentageRules;
  };
}

export interface ReceiptsAdjCalculatorState {
  sizes: WeekSizes[];
  invalidSizes: WeekSizes[];
  activeWeekIndex: number;
  saved: boolean;
  overlayWidth: number;
  overlayHeight: number;
  shouldSave: boolean;
  staticTotals: StaticTotals[];
  config: SimpleReceiptConfig;
}

export interface StaticTotals {
  final: number;
  system: number;
  weekNo: string;
}

export interface SizeDetail extends BasicItem {
  system: number | undefined;
  userAdj: AggregateValue;
  onOrder: number | undefined;
  onOrderRevision: AggregateValue;
  final: number | undefined;
  lastPublished: number | undefined;
  sizeattribute?: string;
  default_size_profile: number;
  isValidSize: boolean;
}

export interface WeekSizes {
  weekNo: string;
  receipts: SizeDetail[];
  total?: SizeDetail;
}

export type ConfigSizeDetail = SizeDetail & {
  userAdj: number;
  onOrderRevision: number;
};

export interface ClientSizeDetail extends SizeDetail {
  userAdj: AggregateValue;
  onOrderRevision: AggregateValue;
}

export interface AggregateValue {
  value: number | undefined;
  percentage: number;
}

export interface NumberFormatValues {
  floatValue: number;
  formattedValue: string;
  value: string;
}

interface ReceiptAdjData {
  channel: string;
  children: any[];
  dc_finalqty: number;
  dc_finrev: any;
  dc_last_pub: number;
  dc_onorder: number;
  dc_planqty: number;
  dc_publish: number;
  dc_useradj: any;
  default_size_profile: number;
  description: string;
  expanded: boolean;
  id: string;
  index: number;
  is_locked: number;
  leaf: boolean;
  'member:week:description': string;
  'member:week:id': string;
  'member:week:name': string;
  name: string;
  product: string;
  sizeattribute: string;
  stylecolor: string;
  week: string;
  isvalid: number;
}

export default class TabbedReceiptsAdjCalculator extends React.Component<ReceiptsAdjCalculatorProps> {
  state: ReceiptsAdjCalculatorState;
  private readonly emptyTotalRow: SizeDetail = {
    id: 'Total',
    name: 'Total',
    system: undefined,
    userAdj: {
      value: undefined,
      percentage: 0.0,
    },
    onOrder: undefined,
    onOrderRevision: {
      value: undefined,
      percentage: 0.0,
    },
    final: undefined,
    lastPublished: 0,
    default_size_profile: 0,
    isValidSize: true,
  };

  constructor(props: ReceiptsAdjCalculatorProps) {
    super(props);
    this.state = {
      sizes: [],
      invalidSizes: [],
      activeWeekIndex: 0,
      saved: false,
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
      shouldSave: true,
      staticTotals: [],
      config: {
        columns: [
          {
            text: 'System',
            dataIndex: 'system',
          },
          {
            text: 'Rec Override',
            dataIndex: 'userAdj',
          },
          {
            text: 'On Order',
            dataIndex: 'onOrder',
          },
          {
            text: 'OO Override',
            dataIndex: 'onOrderRevision',
          },
          {
            text: 'Final',
            dataIndex: 'final',
          },
          {
            text: 'Last Pub',
            dataIndex: 'lastPublished',
          },
        ],
      },
    };

    addEventListenerForEditors(this.props.eGridCell);
    window.addEventListener('resize', this.resizeOverlay.bind(this));
  }

  resizeOverlay() {
    this.setState({
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    });
  }

  isCancelAfterEnd() {
    return !this.state.shouldSave;
  }

  convertSizeDetailToServerData = (size: SizeDetail) => {
    return {
      dc_planqty: size.system,
      dc_useradj: size.userAdj.value,
      dc_onorder: size.onOrder,
      dc_finrev: size.onOrderRevision.value,
      dc_finalqty: size.final,
      dc_last_pub: size.lastPublished,
    };
  };

  fetchConfig = async (configApi: ClientDataApi) => {
    const configResponse = await Axios.get(configApi.url);
    try {
      const config = maybeReturnNestData(configResponse.data) as SimpleReceiptConfig;
      this.setState({
        config,
      });
    } catch (e) {
      ServiceContainer.loggingService.error(`Could not get config ${configApi.url} for TabbedReceiptsAdjCalculator.`);
    }
  };

  componentDidMount() {
    (async () => {
      if (this.props.configApi) {
        await this.fetchConfig(this.props.configApi);
      }
      const floorset = this.props.floorset;
      const dataParams = this.props.dataApi.params
        ? this.props.dataApi.params
        : {
            appName: 'assortment',
            productId: this.props.node.data['stylecolor'],
            timeId: floorset,
            defnId: 'ReceiptAdjustments',
          };
      const resp: { data: any } = await Axios.get(this.props.dataApi.url, {
        params: dataParams,
      });
      const sizeData: any = await ServiceContainer.pivotService.deserialize(resp.data);
      const tempData: any[] = [];

      sortBy(sizeData, 'size_id').forEach((data: ReceiptAdjData) => {
        const keys = tempData.length > 0 ? tempData.map((item) => item.weekNo) : [];
        if (keys.indexOf(data.week) === -1) {
          tempData.push({
            weekNo: data.week,
            receipts: [data],
          });
        } else {
          find(tempData, ['weekNo', data.week]).receipts.push(data);
        }
      });

      // Convert server data to SizeDetail
      // default_size / total
      const finalData: WeekSizes[] = tempData.map((weekSize) => {
        return {
          weekNo: weekSize.weekNo,
          receipts: weekSize.receipts.map((receipt: ReceiptAdjData) => {
            return {
              system: receipt.dc_planqty,
              userAdj: {
                value: receipt.dc_useradj,
                percentage: undefined,
              },
              onOrder: receipt.dc_onorder,
              onOrderRevision: {
                value: receipt.dc_finrev, // TODO
                percentage: undefined,
              },
              final: receipt.dc_finalqty,
              lastPublished: receipt.dc_last_pub,
              isValidSize: receipt.isvalid === 0 ? false : true,
              ...receipt,
            };
          }),
        };
      });

      const invalidSizes = finalData.map((weekSize: WeekSizes) => {
        return {
          weekNo: weekSize.weekNo,
          receipts: weekSize.receipts.filter((item) => !item.isValidSize),
        };
      });
      const validSizes = finalData.map((weekSize: WeekSizes) => {
        return {
          weekNo: weekSize.weekNo,
          receipts: weekSize.receipts.filter((item) => item.isValidSize),
        };
      });

      const finalInvalidSizes = this.calculateInitialPercentages(invalidSizes);
      const finalFinalData = this.calculateInitialPercentages(validSizes);

      const staticTotals = finalFinalData.map((weekSize: WeekSizes) => {
        const totalSystem = sum(weekSize.receipts.map((r) => r.system));
        const totalFinal = sum(weekSize.receipts.map((r) => r.final));

        return {
          final: totalFinal,
          system: totalSystem,
          weekNo: weekSize.weekNo,
        };
      });

      this.setState({
        invalidSizes: finalInvalidSizes,
        sizes: finalFinalData,
        activeWeekIndex: 0,
        staticTotals,
      });
    })();
  }

  calculatePercentageHigherLower = (
    percentageRules: PercentageRuleHigherLower,
    receipts: SizeDetail[],
    colIndex: string,
    newTotal: number
  ) => {
    const reference = percentageRules.referenceColumns[colIndex];
    const lowTotal = sum(receipts.map((r) => r[reference.lower]));
    const lowTotalPerc = receipts.map((r, ind) => {
      if (lowTotal === 0 || isNaN(lowTotal)) {
        return {
          perc: 1 / receipts.length,
          ind,
        };
      }
      const val = r[reference.lower];
      return {
        perc: val / lowTotal,
        ind,
      };
    });
    const highTotal = sum(receipts.map((r) => r[reference.higher]));
    const highTotalPerc = receipts.map((r, ind) => {
      if (highTotal === 0 || isNaN(highTotal)) {
        return {
          perc: lowTotalPerc[ind].perc,
          ind,
        };
      }
      const val = r[reference.higher];
      return {
        perc: val / highTotal,
        ind,
      };
    });
    const highRemainder = newTotal - lowTotal;
    let newLowTotal = newTotal;
    if (highRemainder > 0) {
      newLowTotal = lowTotal;
    }
    let tmpRemain = newLowTotal;
    const finalDistribution = receipts.map((size, ind) => {
      const newValue = Math.floor(lowTotalPerc[ind].perc * newLowTotal);
      tmpRemain -= newValue;
      return newValue;
    });
    sortBy(lowTotalPerc, 'perc').forEach((percInfo) => {
      if (tmpRemain > 0) {
        finalDistribution[percInfo.ind] += 1;
        tmpRemain -= 1;
      } else if (tmpRemain < 0) {
        finalDistribution[percInfo.ind] -= 1;
        tmpRemain += 1;
      }
    });
    if (highRemainder > 0) {
      // distribute remainder by highTotalPerc
      let tmpRemain = highRemainder;
      receipts.forEach((size, ind) => {
        const newValue = Math.floor(highTotalPerc[ind].perc * highRemainder);
        tmpRemain -= newValue;
        finalDistribution[ind] += newValue;
      });
      sortBy(highTotalPerc, 'perc').forEach((percInfo) => {
        if (tmpRemain > 0) {
          finalDistribution[percInfo.ind] += 1;
          tmpRemain -= 1;
        } else if (tmpRemain < 0) {
          finalDistribution[percInfo.ind] -= 1;
          tmpRemain += 1;
        }
      });
    }
    return finalDistribution;
  };

  calculateInitialPercentages = (sizes: WeekSizes[]) => {
    return sizes.map((weekSize: WeekSizes) => {
      const totalSystem = sum(weekSize.receipts.map((r) => r.system));
      const totalOnOrder = sum(weekSize.receipts.map((r) => r.onOrder));
      const totalProfiles = sum(weekSize.receipts.map((r) => r.default_size_profile));

      const newReceipts = weekSize.receipts.map((size) => {
        const systemVal = size.system || 0;
        const onOrderVal = size.onOrder || 0;
        // let percentageSystem: number = totalSystem !== 0 ? systemVal / totalSystem : size.default_size_profile;
        let percentageSystem;
        if (size.userAdj.percentage) {
          percentageSystem = size.userAdj.percentage;
        } else if (totalSystem !== 0) {
          percentageSystem = systemVal / totalSystem;
        } else if (totalProfiles > 0) {
          percentageSystem = size.default_size_profile / totalProfiles;
        } else {
          percentageSystem = 1 / weekSize.receipts.length;
        }

        let percentageOnOrder: number;
        if (size.onOrderRevision.percentage) {
          percentageOnOrder = size.onOrderRevision.percentage;
        } else if (totalOnOrder !== 0) {
          percentageOnOrder = onOrderVal / totalOnOrder;
        } else {
          percentageOnOrder = percentageSystem;
        }

        return {
          ...size,
          userAdj: {
            value: size.userAdj.value,
            percentage: percentageSystem,
          },
          onOrderRevision: {
            value: size.onOrderRevision.value,
            percentage: percentageOnOrder,
          },
        };
      });

      const initialTotal = this.calculateTotalFromSizes(newReceipts);
      return {
        weekNo: weekSize.weekNo,
        receipts: newReceipts,
        total: initialTotal,
      };
    });
  };

  getUrl = () => {
    return reduce(
      this.props.dataApi.params,
      (url, value, key) => url.replace(`:${key}`, value),
      this.props.dataApi.url
    );
  };

  getValue = () => {
    window.removeEventListener('resize', this.resizeOverlay);
    if (this.state.saved) {
      let onOrderAdjTtl = 0;
      let onOrderFinalTtl = 0;
      let shouldBeNullOnOrder = true;
      let rcptAdjTtl = 0;
      let rcptFinalTtl = 0;
      let shouldBeNullUserAdj = true;

      // Calculates all of the useradj and onorders to get the value for grid
      this.state.sizes.forEach((size) => {
        const total = size.total;
        if (total) {
          // Test to see if it should return 0 or null
          if (!isNil(total.userAdj.value) && !isNaN(total.userAdj.value)) {
            shouldBeNullUserAdj = false;
          }
          // Actually adds everything
          if (total.userAdj.value && total.userAdj.value > 0) {
            rcptAdjTtl += total.userAdj.value;
            rcptFinalTtl += total.userAdj.value;
          } else {
            if (total.system && total.system > 0) {
              rcptFinalTtl += total.system;
            }
          }

          // Onorder version of above code
          if (!isNil(total.onOrderRevision.value)) {
            shouldBeNullOnOrder = false;
          }
          if (total.onOrderRevision.value && total.onOrderRevision.value > 0) {
            onOrderAdjTtl += total.onOrderRevision.value;
            onOrderFinalTtl += total.onOrderRevision.value;
          } else {
            if (total.onOrder && total.onOrder > 0) {
              onOrderFinalTtl += total.onOrder;
            }
          }
        }
      });
      return {
        onOrderRevision: onOrderAdjTtl === 0 && shouldBeNullOnOrder ? null : onOrderAdjTtl,
        onOrderFinal: onOrderFinalTtl,
        userAdjRevision: rcptAdjTtl === 0 && shouldBeNullUserAdj ? null : rcptAdjTtl,
        rcptFinal: rcptFinalTtl,
      };
    } else {
      let value = undefined;
      if (
        this.props.floorset &&
        this.props.node.data.extraData &&
        this.props.node.data.extraData[this.props.floorset]
      ) {
        value = this.props.node.data.extraData[this.props.floorset];
      }
      return value;
    }
  };

  isPopup = () => {
    return true;
  };

  calculateTotalFromSizes(sizes: SizeDetail[]): SizeDetail {
    const total = _.cloneDeep(this.emptyTotalRow);

    const columns = this.state.config.columns.map((k) => k.dataIndex);
    sizes.forEach((size) => {
      Object.keys(size).forEach((key: string) => {
        if (key === 'userAdj' || key === 'onOrderRevision') {
          const valueToAdd = Number.isFinite(size[key].value) ? size[key].value : undefined;
          const value = total[key].value;
          const newTotal = !isNil(valueToAdd)
            ? Number.isFinite(value) && !isNil(value)
              ? value + valueToAdd
              : valueToAdd
            : total[key].value;

          total[key].value = newTotal;
          total[key].percentage = 1;
        } else if (columns.indexOf(key) >= 0) {
          const valueToAdd2 = size[key] ? size[key] : 0;
          const newTotal2 = total[key] ? total[key] + valueToAdd2 : valueToAdd2;
          total[key] = newTotal2;
        }
      });
    });

    return total;
  }

  handleChangeReceipts = (sizeattribute: string, column: string, value: number | null) => {
    const weekSizes = _.cloneDeep(this.state.sizes);
    const receipts = weekSizes[this.state.activeWeekIndex].receipts;
    const sizeToChange: number = receipts.findIndex((size: SizeDetail) => {
      return size.sizeattribute === sizeattribute;
    });

    receipts[sizeToChange][column].value = value;
    const newTotalRow = this.calculateTotalFromSizes(receipts);

    receipts.forEach((size) => {
      const oldValue = size[column].value;
      const newValue = oldValue / newTotalRow[column].value;
      if (!Number.isFinite(newValue) && Number.isFinite(newTotalRow[column].value)) {
        size[column].percentage = 0;
        size[column].value = 0;
      }
      size[column].percentage = newValue;
    });

    weekSizes[this.state.activeWeekIndex].receipts = receipts;
    weekSizes[this.state.activeWeekIndex].total = newTotalRow;
    this.setState({
      sizes: weekSizes,
    });
  };

  handleChangeTotalRow = (column: string, newTotalValue: number | null) => {
    // clone the sizes up front so we can mutate them safely
    const weekSizes = _.cloneDeep(this.state.sizes);
    const activeWeek = weekSizes[this.state.activeWeekIndex];
    const receipts = activeWeek.receipts;

    if (newTotalValue === 0 || !Number.isFinite(newTotalValue) || newTotalValue == null) {
      const init = this.calculateInitialPercentages(weekSizes);

      init[this.state.activeWeekIndex].receipts.map((x) => {
        x[column].value = newTotalValue;
        return x;
      });
      init[this.state.activeWeekIndex].total[column].value = newTotalValue;
      this.setState({
        sizes: init,
      });
      return;
    }

    // ensure this column has an overriding percentage calculation rule
    if (this.state.config.dataRules && this.state.config.dataRules.percentage.referenceColumns[column]) {
      const newValues = this.calculatePercentageHigherLower(
        this.state.config.dataRules.percentage,
        receipts,
        column,
        newTotalValue
      );

      receipts.forEach((rec, ind) => {
        rec[column].value = newValues[ind];
      });

      if (!isNil(activeWeek.total)) {
        activeWeek.total[column].value = sum(newValues);
      }
      this.setState({
        sizes: weekSizes,
      });
      return;
    }

    interface Percents {
      percent: number; // warning: this can NaN
      index: number;
    }

    let currentTotal = 0;

    const percentageArray: Percents[] = [];
    receipts.forEach((size, index) => {
      // When a new total comes in, we need to re-calculate the size value of every size,
      // so we use the previous % contribution per size multiplied by the newTotal value to get the updated value.
      // Because this process runs for essentially all changes, float errors can be introduced, so we Math.Round()
      // and because units should be integers here, we then floor() them
      const newSizeValue = Math.floor(Math.round(size[column].percentage * newTotalValue));
      currentTotal += newSizeValue || 0; // newSizeValue shouldn't be nullable here, this is for safety
      percentageArray.push({
        percent: size[column].percentage,
        index: index,
      });

      size[column].value = newSizeValue;
    });

    // To distribute the last couple items that might get lost, it takes the highest percentages and adds 1 to them
    percentageArray.sort((a, b) => b.percent - a.percent);
    percentageArray.forEach((percentObject) => {
      // Distribute to the highest percentages in cases where final total is less than intended total.
      if (newTotalValue && currentTotal < newTotalValue) {
        receipts[percentObject.index][column].value = receipts[percentObject.index][column].value + 1;
        currentTotal += 1;
        // Undistribute from the highest percentages in cases where final total is greater than intended total.
      } else if (newTotalValue && currentTotal > newTotalValue) {
        receipts[percentObject.index][column].value = receipts[percentObject.index][column].value - 1;
        currentTotal -= 1;
      }
    });

    // It shouldn't ever be possible to have a populated week *with* no total row. But I don't want to fight types.
    if (!isNil(activeWeek.total)) {
      activeWeek.total[column].value = newTotalValue;
    }
    this.setState({
      sizes: weekSizes,
    });
  };

  getFieldTotal = (field: keyof SizeDetail) => {
    const { sizes, activeWeekIndex } = this.state;
    const sizesOnOrderV = sizes[activeWeekIndex].receipts.map((r) => get(r, field));
    if (findIndex(sizesOnOrderV, (oo) => !isNil(oo)) < 0) {
      return null;
    }
    return sum(sizesOnOrderV);
  };

  getOnOrderTotal = () => {
    return this.getFieldTotal('onOrder');
  };

  getFieldValue = (field: string, receiptIndex: number) => {
    const { sizes, activeWeekIndex } = this.state;
    const value = sizes[activeWeekIndex].receipts[receiptIndex][field];
    return value;
  };

  getTotalValue = (field: string) => {
    const { staticTotals, activeWeekIndex } = this.state;
    return staticTotals[activeWeekIndex][field];
  };

  buildRow = (size: SizeDetail, index: number): JSX.Element => {
    const { isEditable } = this.props;
    const { sizeattribute } = size;
    const { system, userAdj, onOrder, onOrderRevision, isValidSize } = size;
    const onOrderTotal = this.getOnOrderTotal();
    const rowEditable: boolean = isEditable && isValidSize;
    const onOrderOverrideEditable = rowEditable && !isNil(onOrderTotal);
    const userAdjEditable = rowEditable && !onOrderOverrideEditable;

    let calculatedTotal = system;
    // We check against totals, because that's...how this all works
    if (!isNil(this.getFieldTotal('onOrderRevision'))) {
      calculatedTotal = defaultTo(onOrderRevision.value, 0);
    } else if (!isNil(this.getFieldTotal('onOrder'))) {
      calculatedTotal = defaultTo(onOrder, 0);
    } else if (!isNil(this.getFieldTotal('userAdj'))) {
      calculatedTotal = defaultTo(userAdj.value, 0);
    } else {
      calculatedTotal = defaultTo(system, 0);
    }
    const highlightTotal = calculatedTotal === defaultTo(system, 0) ? 'inherit' : '#ff0000';
    return (
      <div key={sizeattribute} className={styles.tableRow}>
        <div className={styles.tableCellHeader}>{sizeattribute}</div>
        {this.state.config.columns.map((col, ind) => {
          switch (col.dataIndex) {
            case 'userAdj': {
              const inputIntegerValue = size.userAdj.value === undefined ? null : size.userAdj.value;
              return (
                <div key={ind} className={userAdjEditable ? styles.tableCellInputEditable : styles.tableCellInput}>
                  <InputInteger
                    // @ts-ignore
                    onChange={(value: number | null) => this.handleChangeReceipts(sizeattribute, 'userAdj', value)}
                    bypassDefaultHandler={true}
                    nullable={true} // only the total row can be nulled directly
                    editable={userAdjEditable}
                    valid={true}
                    value={inputIntegerValue}
                    className={InputIntegerClass}
                    key={'userAdj' + index}
                  />
                </div>
              );
            }
            case 'onOrderRevision': {
              return (
                <div
                  key={ind}
                  className={onOrderOverrideEditable ? styles.tableCellInputEditable : styles.tableCellInput}
                >
                  <InputInteger
                    // @ts-ignore
                    onChange={(value: number | null) => {
                      if (sizeattribute) {
                        this.handleChangeReceipts(sizeattribute, 'onOrderRevision', value);
                      }
                    }}
                    bypassDefaultHandler={true}
                    nullable={true} // only the total row can be nulled directly
                    editable={onOrderOverrideEditable}
                    valid={true}
                    value={size.onOrderRevision.value === undefined ? null : size.onOrderRevision.value}
                    className={InputIntegerClass}
                    key={'onOrder' + index}
                  />
                </div>
              );
            }
            case 'final': {
              return (
                <div key={ind} className={styles.tableCellBody} style={{ color: highlightTotal }}>
                  {size[col.dataIndex]}
                </div>
              );
            }
            default: {
              let value = size[col.dataIndex];
              if (!isNil(col.renderer) && !isNil(Renderer[col.renderer])) {
                value = Renderer[col.renderer](value);
              }
              return (
                <div key={ind} className={styles.tableCellBody}>
                  {value}
                </div>
              );
            }
          }
        })}
      </div>
    );
  };

  buildTotalRow = (total: SizeDetail, index: number): JSX.Element => {
    const { isEditable } = this.props;
    const { id, name } = total;
    const { userAdj, onOrder, onOrderRevision } = total;
    const system = this.getTotalValue('system');
    const onOrderTotal = this.getOnOrderTotal();
    const rowEditable: boolean = isEditable;

    const onOrderOverrideEditable: boolean = rowEditable && !isNil(onOrderTotal);

    const userAdjEditable: boolean = rowEditable && !onOrderOverrideEditable;

    let calculatedTotal = system;
    if (userAdj.value && userAdj.value >= 0) {
      calculatedTotal = userAdj.value;
    }
    if (onOrder && onOrder > 0) {
      calculatedTotal = onOrder;
    }
    if (onOrderRevision.value && onOrderRevision.value >= 0) {
      calculatedTotal = onOrderRevision.value;
    }
    const highlightTotal = calculatedTotal === system ? 'inherit' : '#ff0000';

    return (
      <div key={id} className={styles.tableRow}>
        <div className={styles.tableCellHeader}>{name}</div>
        {this.state.config.columns.map((col, ind) => {
          switch (col.dataIndex) {
            case 'userAdj': {
              return (
                <div key={ind} className={userAdjEditable ? styles.tableCellInputEditable : styles.tableCellInput}>
                  <InputInteger
                    // @ts-ignore
                    onChange={(value: number | null) => this.handleChangeTotalRow('userAdj', value)}
                    nullable={true}
                    editable={userAdjEditable}
                    valid={true}
                    value={total.userAdj.value === undefined ? null : total.userAdj.value}
                    className={InputIntegerClass}
                    key={'userAdj' + index}
                  />
                </div>
              );
            }
            case 'onOrderRevision': {
              return (
                <div
                  key={ind}
                  className={onOrderOverrideEditable ? styles.tableCellInputEditable : styles.tableCellInput}
                >
                  <InputInteger
                    // @ts-ignore
                    onChange={(value: number | null) => this.handleChangeTotalRow('onOrderRevision', value)}
                    nullable={true}
                    editable={onOrderOverrideEditable}
                    valid={true}
                    value={total.onOrderRevision.value === undefined ? null : total.onOrderRevision.value}
                    className={InputIntegerClass}
                    key={'onOrder' + index}
                  />
                </div>
              );
            }
            case 'final': {
              return (
                <div key={ind} className={styles.tableCellTotal} style={{ color: highlightTotal }}>
                  {total[col.dataIndex]}
                </div>
              );
            }
            default: {
              let value = total[col.dataIndex];
              if (!isNil(col.renderer) && !isNil(Renderer[col.renderer])) {
                value = Renderer[col.renderer](value);
              }
              return (
                <div key={ind} className={styles.tableCellTotal}>
                  {value}
                </div>
              );
            }
          }
        })}
      </div>
    );
  };

  onClickWeekTab = (weekIndex: number) => {
    this.setState({
      activeWeekIndex: weekIndex,
    });
  };

  bindEventListeners = (element: HTMLDivElement) => {
    if (element) {
      element.addEventListener('keydown', function(event: KeyboardEvent) {
        event.stopPropagation();
      });
    }
  };

  noDataJSX = () => {
    return (
      <div className={styles.overlay} style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}>
        <div data-qa="ag-popover" className={styles.modalContainerForNoData}>
          <div data-qa="ag-popover-arrow" className={styles.modalArrowStyle} />
          <div className={styles.cardHeader}>Receipts Override Calculator</div>
          <div className={styles.tabContainer} />
          <div className={styles.containerNoData} ref={this.bindEventListeners}>
            <Overlay type="loading" visible={true} fitParent={true} />
          </div>
          <div className={styles.actionsContainer}>
            <CardActions classes={MUIStyles.cardActions}>
              <RejectButton onClick={() => this.cancel()} />
            </CardActions>
          </div>
        </div>
      </div>
    );
  };

  save = () => {
    const serverData: any[] = [];
    this.state.sizes.forEach((size) => {
      size.receipts.forEach((receipt) => {
        serverData.push({
          ...receipt,
          ...this.convertSizeDetailToServerData(receipt),
        });
      });
    });

    const dataParams = this.props.dataApi.params
      ? this.props.dataApi.params
      : {
          appName: 'assortment',
          productId: this.props.node.data['stylecolor'],
          timeId: this.props.floorset,
          defnId: 'ReceiptAdjustments',
        };

    Axios.post(this.props.dataApi.url, serverData, {
      params: dataParams,
    });

    this.setState(
      {
        saved: true,
      },
      () => {
        this.props.stopEditing();
      }
    );
  };

  cancel = () => {
    this.setState(
      {
        shouldSave: false,
      },
      () => this.props.stopEditing()
    );
  };

  render() {
    if (!this.state.sizes || this.state.sizes.length === 0) {
      return this.noDataJSX();
    }

    const activeWeekIndex = this.state.activeWeekIndex;

    const receipts = this.state.sizes[activeWeekIndex].receipts;
    const invalidReceipts = this.state.invalidSizes[activeWeekIndex].receipts;
    // adding 1 to account for the 'sizes' column
    const columnCount = this.state.config.columns.length + 1;
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div
          className={`${styles.overlay} ag-custom-component-popup`}
          style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}
        >
          <div
            data-qa="ag-popover"
            className={styles.modalContainer}
            style={{ width: `calc(${columnCount} * ${REC_ADJ_COLUMN_WIDTH}px + 100px)` }}
          >
            <div className={styles.cardHeader}>Receipts Override Calculator</div>
            <div className={styles.tabContainer}>
              <Tabs
                value={activeWeekIndex}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                style={{ minHeight: 30 }}
              >
                {this.state.sizes.map((size, index) => {
                  const tabClass = activeWeekIndex === index ? styles.tabButtonActive : styles.tabButton;
                  return (
                    <button className={tabClass} onClick={() => this.onClickWeekTab(index)} key={size.weekNo}>
                      {size.weekNo}
                    </button>
                  );
                })}
              </Tabs>
            </div>

            <div className={styles.container} ref={this.bindEventListeners}>
              <div className={styles.receiptContainer}>
                <div className={styles.headerSection}>
                  <span className={styles.sectionText}>Color Details</span>
                </div>
                <div className={styles.tableSection}>
                  <div className={styles.tableRow}>
                    <div className={styles.tableCellHeader}>Sizes</div>
                    {this.state.config.columns.map((col, ind) => {
                      return (
                        <div key={ind} className={styles.tableCellHeader}>
                          {col.text}
                        </div>
                      );
                    })}
                  </div>
                  <div>{this.buildTotalRow(this.calculateTotalFromSizes(receipts), 0)}</div>
                </div>
              </div>

              <div className={styles.receiptContainer}>
                <div className={styles.headerSection}>
                  <span className={styles.sectionText}>Size Details</span>
                </div>
                <div className={styles.tableSection}>
                  <div className={styles.tableRow}>
                    <div className={styles.tableCellHeader}>Sizes</div>
                    {this.state.config.columns.map((col, ind) => {
                      return (
                        <div key={ind} className={styles.tableCellHeader}>
                          {col.text}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {isEmpty(receipts) && <div className={styles.tableRow} />}
                    {!isEmpty(receipts) && receipts.map(this.buildRow)}
                    {!isEmpty(receipts) && this.buildTotalRow(this.calculateTotalFromSizes(receipts), 0)}
                  </div>
                </div>
              </div>

              {/* new invalid sizes section */}
              {!isEmpty(invalidReceipts) && (
                <div className={styles.receiptContainer}>
                  <div className={styles.headerSection}>
                    <span className={styles.sectionText}>Invalid Sizes</span>
                  </div>
                  <div className={styles.tableSection}>
                    <div className={styles.tableRow}>
                      {this.state.config.columns.map((col, ind) => {
                        return (
                          <div key={ind} className={styles.tableCellHeader}>
                            {col.text}
                          </div>
                        );
                      })}
                    </div>
                    <div>{invalidReceipts.map(this.buildRow)}</div>
                  </div>
                </div>
              )}
            </div>
            {/* end invalid sizes */}

            <div className={styles.actionsContainer}>
              <CardActions classes={MUIStyles.cardActions}>
                {this.props.isEditable && <AcceptButton onClick={() => this.save()} />}
                <RejectButton onClick={() => this.cancel()} />
              </CardActions>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
