import { Action as ReduxAction } from 'redux';
import { AppDispatch, AppState, AppThunkDispatch, clearCachedData } from 'src/store';
import container from 'src/ServiceContainer';
import { QuickSelectItem, QuickSelectRegion } from 'src/dao/scopeClient';
import { Scope, PlanDatesResponse } from 'src/types/Scope';
import { flushSelectionOverridesStarted } from 'src/components/FilterPanel/FilterPanel.slice';
import { find, isEqual, assign, defaultTo, last, isNil } from 'lodash';
import { setSessionScope, removeSessionScope } from '../RestoreSession/RestoreSession.utils';
import { getFilterOptions } from 'src/components/Headerbar/Headerbar.container';
import { anyNull } from 'src/utils/Functions/AnyNull';
import {
  closeScope,
  requestScopeConfig,
  receiveScopeConfig,
  changeScopeSelection,
  requestFloorsetConfig,
  receiveScopeError,
  receiveFloorsetConfig,
  receiveQuickSelects,
  requestScope,
  receiveScopeRefreshTrigger,
  receiveScope,
  sendScope,
  scopeSent,
  validateAllSelections,
  getPlanDates,
  resetOnlyScope,
  initialScope,
} from './AssortmentScopeSelector.slice';
import { getDateFromWeek } from './AssortmentScopeSelector.utils';
import { ASSORTMENT_CONTEXT } from 'src/utils/Domain/Constants';
import { DependentScopeType } from 'src/services/configuration/codecs/confdefn';
import { newScope } from 'src/state/scope/Scope.actions';
import axios from 'src/services/axios';
import { SCOPECREATE_WITH_WP } from 'src/services/Scope.client';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

export function asyncGetPlanDates() {
  return (dispatch: AppThunkDispatch): Promise<ReduxAction> | void => {
    return container.scopeClient.getPlanDates().then((response: PlanDatesResponse) => {
      return dispatch(
        getPlanDates({
          planCurrent: response.planCurrent,
          planStart: response.planStart,
          planEnd: response.planEnd,
        })
      );
    });
  };
}

export function asyncGetScopeConfig() {
  return (dispatch: AppThunkDispatch): Promise<ReduxAction> => {
    dispatch(requestScopeConfig());
    return container.scopeClient
      .getScopeConfig()
      .then((response) => {
        return dispatch(receiveScopeConfig(response));
      })
      .catch((error) => {
        return dispatch(receiveScopeError(error));
      });
  };
}

export function asyncGetScope() {
  return (dispatch: AppThunkDispatch): Promise<ReduxAction | void> => {
    dispatch(requestScope());
    return container.scopeClient
      .getScope()
      .then((response) => {
        if (response.scope.valid) {
          const now = Date.now();
          const scope = { scope: response.scope, updatedAt: now };
          dispatch(receiveScope(scope));
          setSessionScope(response.scope);
          dispatch(asyncGetPlanDates());
        }

        dispatch(asyncGetScopeConfig()).then(() => {
          // Clear out the scope in state, as the scope in backend is not valid
          // but keep the current selections in the scope selector
          if (!response.scope.valid) {
            dispatch(resetOnlyScope());
          }
        });
      })
      .catch((error) => {
        removeSessionScope();
        // if we get an error trying to get scope, then at least try and fetch the scope config,
        // so that the user can try and create a new scope
        dispatch(asyncGetScopeConfig());
        return dispatch(receiveScopeError(error));
      });
  };
}

export function setSelectedPeriod(selection: QuickSelectItem, region: QuickSelectRegion) {
  return (dispatch: AppThunkDispatch, getState: () => AppState): ReduxAction | void => {
    const state = getState();
    const { scope } = state;
    if (selection != null) {
      if (region === ASSORTMENT_CONTEXT) {
        const endRangeList = scope.pastRangeList.filter(
          (x) => Object.values(scope.daysPastRangeList.end_date).indexOf(x.id) !== -1
        );
        // this allows scope to find and set it's historyEnd to the latest valid end date if there is an overlap
        let validEndWeek = defaultTo(selection.lySlsend, '');
        if (isNil(getDateFromWeek(validEndWeek, scope.daysPastRangeList.end_date))) {
          validEndWeek = defaultTo(last(endRangeList)?.id, '');
        }
        return dispatch(
          changeScopeSelection({
            end: selection.slsend,
            start: selection.slsstart,
            floorSet: selection.id,
            historyStart: selection.lySlsstart,
            historyEnd: validEndWeek,
          })
        );
      } else {
        return dispatch(
          changeScopeSelection({
            historyEnd: selection.slsend,
            historyStart: selection.slsstart,
            historyFloorset: selection.id,
          })
        );
      }
    }
  };
}

export function asyncGetFloorsetAttributes<S extends AppState>(floorSet: string) {
  return (dispatch: AppDispatch, getState: () => S): Promise<ReduxAction | void> => {
    const productId = getState().scope.selections.productMember;
    const rangeList = getState().scope.pastRangeList;

    if (productId) {
      return container.scopeClient.getFloorsetAttributes(floorSet, productId).then((response) => {
        return dispatch(
          changeScopeSelection({
            end: response.slsend,
            start: response.slsstart,
            // endSales: response.slsend,
            // startSales: response.slsstart,
            ...(function historyGuard() {
              const histStartDateExists = find(rangeList, { id: response.lyslsstart });
              const histEndDateExists = find(rangeList, { id: response.lyslsend });
              if (!histStartDateExists || !histEndDateExists) {
                return {
                  historyStart: 'invalid',
                  historyEnd: 'invalid',
                };
              }
              return {
                historyEnd: response.lyslsend,
                historyStart: response.lyslsstart,
              };
            })(),
            floorSet,
          })
        );
      });
    }
    return Promise.resolve();
  };
}

export function asyncGetFloorsetConfig<S extends AppState>(productId: string, region?: string) {
  return async (dispatch: AppThunkDispatch, _getState: () => S): Promise<ReduxAction[]> => {
    dispatch(requestFloorsetConfig(productId));
    const floorsetsFetch = await container.scopeClient
      .getFloorsets(productId)
      .then((response) => {
        return dispatch(receiveFloorsetConfig(response));
      })
      .catch((error) => {
        return dispatch(receiveScopeError(error));
      });
    if (region != null) {
      await container.scopeClient.getQuickSelects(productId, region).then((response) => {
        return dispatch(receiveQuickSelects(response));
      });
    }
    return Promise.all([floorsetsFetch]);
  };
}

export function asyncSetScope(oldScope: Scope) {
  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<ReduxAction | void> => {
    const selectedPerspective = getState().perspective.selected;
    if (!selectedPerspective) {
      throw new Error('Perspective not found');
    }

    dispatch(validateAllSelections());
    if (getState().scope.selectionsValid) {
      const { selections, rangeList } = getState().scope;
      const perspectiveConfig = getState().appConfig.tenantConfig.perspective;
      // Don't submit scope unless it has changed
      if (isEqual(oldScope, selections)) {
        dispatch(closeScope());
        return Promise.resolve();
      }
      dispatch(sendScope());
      // TODO: Determine why the names are causing issues as they are not required to set scope.
      const requiredSelections = {
        ...selections,
        locationMemberName: null,
        productMemberName: null,
      };
      const planCurrent = rangeList[0].id;
      // if assortment plan not selected, select first week as default
      if (anyNull(selections, ['end', 'start'])) {
        assign(requiredSelections, { end: planCurrent, start: planCurrent });
      }
      clearCachedData();
      return container.scopeClient
        .setScope(requiredSelections)
        .then((response) => {
          const now = Date.now();

          response.scope.valid ? setSessionScope(selections) : removeSessionScope();

          if (perspectiveConfig) {
            perspectiveConfig.scopeConfig.dependentScopes?.forEach((depScope) => {
              switch (depScope.type) {
                case DependentScopeType.enum.MfpScope:
                  const currentModule = getMfpModule(getState());
                  if (!isNil(currentModule)) {
                    dispatch(
                      newScope(axios, {
                        // this fails without a current Module
                        initParams: {
                          type: SCOPECREATE_WITH_WP,
                        },
                        // this is valid for dependent scoope
                        // TODO add new dependent scope create func
                        // @ts-ignore
                        anchor: {},
                        workflow: 'pre-season',
                      })
                    );
                  }
                  break;
                default:
                  break;
              }
            });
          }

          dispatch(asyncGetPlanDates());
          dispatch(scopeSent());
          dispatch(closeScope());

          // refresh if the submitted oldScope is referentially equal to the initial scope (indicating we're in the initial redux state)
          // or if the scopes aren't equal, indicating a new scope is being submitted
          if (oldScope === initialScope || !isEqual(oldScope, initialScope)) {
            const scope = { scope: response.scope, updatedAt: Date.now() };
            return dispatch(receiveScopeRefreshTrigger(scope));
          }
          return;
        })
        .catch((error) => {
          removeSessionScope();
          return dispatch(receiveScopeError(error));
        });
    }
    return Promise.resolve();
  };
}
