import React from 'react';
import { Link } from 'react-router-dom';
import { RouteLink } from 'src/types/RouteLink';
import { TopNavLink } from 'src/components/TopNavbar/TopNavLink';
import styles from 'src/components/TopNavbar/TopNavbar.styles';
import TopNavIconPopover, { TopNavIconPopoverMenuItem } from 'src/components/TopNavbar/TopNavIconPopover';
import TopNavIconLink from 'src/components/TopNavbar/TopNavIconLink';
import TopNavSearch from 'src/components/TopNavbar/TopNavSearch';
import { resolvePath } from 'src/cdn';

import brandLogoPath from 'src/images/S5Logo-White-NoInnovation-Centered.png';
import { isEmpty, isNil, noop } from 'lodash';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { FastNav, BlankFastNav } from 'src/components/FastNav/FastNav';
import { BoundEnabledPerspective } from 'src/services/configuration/codecs/bindings.types';
import { Bindings } from 'src/pages/NavigationShell/NavigationShell';
import { Backdrop } from '@material-ui/core';
import { logout } from 'src/services/auth/platform';
import { getPerspectiveLookupKey } from 'src/services/configuration/service';

const BrandLogo = resolvePath(brandLogoPath);

export interface TopNavbarProps {
  routeLinks: RouteLink[];
  viewLinks: RouteLink[];
  bindings: Bindings;
  perspective: BoundEnabledPerspective;
  location: unknown;
}
export interface TopNavbarState {
  fastNavOpen: boolean;
  backdropOpen: boolean;
  activeFastNavTab: string;
  searchLinks: RouteLink[]; // combination of route and view links
}

interface TopNavbarHomeLinkProps {
  onClick: () => void;
}

const TopNavbarHomeLink = ({ onClick }: TopNavbarHomeLinkProps) => {
  return (
    <Link to="/" onClick={onClick}>
      <img className="logo" src={BrandLogo} alt="" data-qa="TopNavHomeIcon" />
    </Link>
  );
};

const MemoizedTopNavbarHomeLink = React.memo(TopNavbarHomeLink);

export class TopNavbar extends React.Component<TopNavbarProps, TopNavbarState> {
  fastNavAnchor: HTMLElement | null = null;
  searchContainer: HTMLElement | null = null;
  userAnchor: React.RefObject<HTMLAnchorElement> = React.createRef();
  constructor(props: TopNavbarProps) {
    super(props);
    this.state = {
      fastNavOpen: false,
      backdropOpen: false,
      activeFastNavTab: '',
      searchLinks: props.routeLinks.concat(props.viewLinks),
    };

    this.handleTouchDevices();
  }

  componentDidUpdate(prevProps: TopNavbarProps) {
    if (prevProps.location !== this.props.location) {
      this.resetFastNavToggle();
    }
  }

  handleLogout() {
    logout();
  }

  userDropdownMenuItems: TopNavIconPopoverMenuItem[] = [
    {
      text: 'Logout',
      qaString: 'Logout',
      onClick: this.handleLogout,
    },
  ];

  // TODO: Move this possibly
  handleTouchDevices() {
    window.addEventListener('touchstart', function onFirstTouch() {
      document.cookie = 'TOUCH=TRUE';
      window.removeEventListener('touchstart', onFirstTouch, false);
    });
  }

  /**
   * Toggles the state for the desired TopNavLink and FastNav
   *
   * @param tabName determines the current tab to take action against, an empty value will reset the fast nav state.
   */
  onFastNavToggle = (tabName: string) => {
    if (tabName === this.state.activeFastNavTab || tabName === '') {
      this.setState({
        fastNavOpen: false,
        activeFastNavTab: '',
      });
    } else {
      this.setState({
        fastNavOpen: true,
        activeFastNavTab: tabName,
      });
    }
  };

  resetFastNavToggle = () => {
    this.onFastNavToggle('');
  };

  onEnterSearch = () => {
    if (!this.state.fastNavOpen && this.state.backdropOpen) {
      return;
    }

    this.setState({
      fastNavOpen: false,
      backdropOpen: true,
    });
  };

  onLeaveSearch = () => {
    this.setState({
      backdropOpen: false,
    });
  };

  render() {
    const { fastNavOpen, activeFastNavTab, backdropOpen } = this.state;
    const { routeLinks, bindings, perspective } = this.props;
    const links = routeLinks.map(({ name, link, highlightPrefix, disabled }) => {
      return (
        <TopNavLink
          key={name}
          name={name}
          link={link}
          highlightPrefix={highlightPrefix}
          disabled={disabled}
          data-qa="TopNavRouteLink"
          fastNavOpen={fastNavOpen && activeFastNavTab === name}
          onFastNavToggle={this.onFastNavToggle}
        />
      );
    });

    const perspectiveLookupKey = getPerspectiveLookupKey(perspective);
    const tab = bindings[perspectiveLookupKey].boundTabs.find(
      (tab) => !isEmpty(tab.boundSections) && tab.name === activeFastNavTab
    );

    return (
      <ClickAwayListener onClickAway={this.resetFastNavToggle}>
        <nav className={`bg-dark ${styles.mainNavBar}`} data-qa="TopNav">
          <Backdrop open={fastNavOpen || backdropOpen} style={{ zIndex: 102 }} onClick={this.resetFastNavToggle} />
          <span className="logo-container">
            <MemoizedTopNavbarHomeLink onClick={noop} />
          </span>
          <span className="nav-links" ref={(elem) => (this.fastNavAnchor = elem)}>
            {links}
          </span>
          <span className="nav-controls" data-qa="TopNavRightSide">
            <span className="search-container" ref={(elem) => (this.searchContainer = elem)}>
              <TopNavSearch
                routeLinks={this.state.searchLinks}
                onEnter={this.onEnterSearch}
                onLeave={this.onLeaveSearch}
              />
            </span>
            <span className="buttons">
              <TopNavIconLink
                iconClass="fas fa-question-circle"
                href="http://s5stratoshelp.helpdocsonline.com/home/"
                target="_blank"
                data-qa="TopNavHelp"
              />
              <TopNavIconPopover
                icon={'fas fa-user user'}
                iconQaString={'User'}
                dropdownItems={this.userDropdownMenuItems}
              />
            </span>
          </span>
          {fastNavOpen && (
            <Popper
              style={{ zIndex: 105 }}
              open={fastNavOpen}
              anchorEl={this.fastNavAnchor}
              placement={'bottom-start'}
              transition={true}
            >
              <Paper
                style={{
                  // style taken directly from popover styles (.MuiPaper-elevation8)
                  boxShadow:
                    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                }}
                classes={{ root: styles.getFastNavPopperStyles(this.fastNavAnchor, this.searchContainer) }}
              >
                {!isNil(tab) ? (
                  <FastNav
                    key={`${tab.name}`}
                    perspective={perspective}
                    tab={tab}
                    sections={tab.boundSections}
                    onItemClick={this.resetFastNavToggle}
                  />
                ) : (
                  <BlankFastNav />
                )}
              </Paper>
            </Popper>
          )}
        </nav>
      </ClickAwayListener>
    );
  }
}
