import { ofType } from 'redux-observable';
import { concatMap, filter, map, mergeMap, throttleTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppEpic } from 'src/store';
import { receivedPublish, receivedWorkingSets } from 'src/state/workingSets/workingSets.slice';
import { CONTEXT_READY } from 'src/state/workingSets/workingSets.types';
import { AnyAction } from '@reduxjs/toolkit';
import { getScopeId } from './Scope.types';
import { clearScope, receivedCreateScope, receivedScope, requestRefreshGrid } from './Scope.slice';
import { SCOPECREATE_WITH_WP } from 'src/services/Scope.client';
import { fetchCommands, getAvailableScopeMembers, requestAddPrivateVersion } from './Scope.actions';
import { AppThunk } from 'src/store';
import { updateAppConfig } from 'src/services/configuration/AppConfig.slice';
import { setActivePerspective, setActiveTab } from 'src/pages/NavigationShell/NavigationShell.slice';
import { resetScope } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { clearAvailableScopes } from '../ViewConfig/ViewConfig.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

// throttle incoming events that can trigger data refreshes
// as sometimes many events can come in rapid succession from the server
// note that both the grid and the macrosummaries listen for the output 'requestRefreshGrid' here
// so they can both eventually be refreshed from the output action
export const refreshGrid: AppEpic = (action$, state$): Observable<AnyAction> => {
  return action$.pipe(
    ofType(receivedWorkingSets.type, receivedScope.type),
    filter(() => {
      // filter only 'ready' events for the current scope,
      // because we only want to refresh when the grid is ready
      const currentScopeId = getScopeId(state$.value.mfpScope);
      if (currentScopeId) {
        const workingsets = state$.value.workingSets.contexts;
        const newScopeStatus = workingsets
          .filter((s) => s.initParams.type === SCOPECREATE_WITH_WP)
          .find((ws) => ws.id === currentScopeId)?.status;
        return newScopeStatus === CONTEXT_READY;
      }
      return false;
    }),
    throttleTime(48, undefined, {
      // the first action fires, but events that come within three frames are ignored
      leading: true, // take the first one
      trailing: false, // dont take the last one
    }),
    concatMap(() => of(requestRefreshGrid()))
  );
};

export const handleFetchCommands: AppEpic = (action$): Observable<AppThunk> => {
  return action$.pipe(
    ofType(
      receivedScope.type,
      receivedCreateScope.type,
      requestAddPrivateVersion.fulfilled.type,
      updateAppConfig.type,
      receivedPublish.type,
      setActiveTab.type
    ),
    concatMap(() => of(fetchCommands()))
  );
};

export const clearMfpScope: AppEpic = (action$): Observable<AnyAction> => {
  return action$.pipe(
    // resetScope() is the action that is fired when the bubble view mounts
    // clear out mfp scope info when that happens,
    // as well as on any major navigation event, as
    // the mfp module information is stored in the tab context, so we may
    // need to refetch when the tab or perspective changes
    //SPIKE: check if it doesn't break Target Setting when tab switching
    ofType(resetScope.type, updateAppConfig.type, setActivePerspective.type, setActiveTab.type),
    concatMap(() => of(clearScope(), clearAvailableScopes()))
  );
};

export const handleFetchMfpAvailableMembers: AppEpic = (action$, state$): Observable<AppThunk> => {
  // refetch availableMembers after we clear mfp scope
  return action$.pipe(
    ofType(clearAvailableScopes.type),
    map(() => getMfpModule(state$.value)),
    filter(inputIsNotNullOrUndefined),
    concatMap((mfpModule) => of(getAvailableScopeMembers(mfpModule)))
  );
};
