import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line prettier/prettier
import type { BoundEnabledPerspective } from "src/services/configuration/codecs/bindings.types";
import { SectionViewConf } from "src/services/configuration/codecs/confdefnComponents";
import { RouteLink } from "src/types/RouteLink";

export interface NavigationShellSlice {
  /** Active Perspective */
  selected: BoundEnabledPerspective | null;
  activeTab: string;
  activePage: string;
  viewLinks: RouteLink[];
  /** For sections within a view that we want to hold navigation state for, such as opened accordions or tabs */
  activeSubPage?: string,
  activePageConf?: SectionViewConf
}

const initialState: NavigationShellSlice = {
  selected: null,
  activeTab: '',
  activePage: '',
  viewLinks: [],
};

const navigationShellReducer = createSlice({
  name: 'NavigationShell',
  initialState: initialState as NavigationShellSlice,
  reducers: {
    setActivePerspective: (state, action: PayloadAction<BoundEnabledPerspective>) => {
      state.selected = action.payload
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    setViewLinks: (state, action: PayloadAction<RouteLink[]>) => {
      state.viewLinks = action.payload
    },
    preSetActivePage: (state) => state,
    setActivePage: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload
    },
    preSetActiveSubPage: (state) => state,
    setActiveSubPage: (state, action: PayloadAction<string | undefined>) => {
      state.activeSubPage = action.payload
    },
    setActivePageComponentPropsConf: (state, action: PayloadAction<SectionViewConf | undefined>) => {
      state.activePageConf = action.payload;
    }
  }
});

export const {
  setActivePerspective,
  setActiveTab,
  preSetActivePage,
  setActivePage,
  setViewLinks,
  preSetActiveSubPage,
  setActiveSubPage,
  setActivePageComponentPropsConf
} = navigationShellReducer.actions;

export default navigationShellReducer.reducer;